import env from '../../config';
import ApiHandlerService from '../../services/api-handler';

export default class IntervalsApi {
  static add(variables, token) {
    const payload = variables && variables.payload;
    if (!payload) throw Error('No payload in add request');

    delete payload.data; // do not send the data back
    if (!payload.name) delete payload.name; // https://github.com/zalando/connexion/issues/287

    const url = `${env.API_BASE_URL}/intervals`;
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store'
      },
      body: JSON.stringify({
        payload
      }),
      method: 'POST'
    };

    return ApiHandlerService.fetch(url, config);
  }

  static delete(variables, token) {
    const id = variables && variables.id;
    const url = `${env.API_BASE_URL}/intervals/${id}`;
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store'
      },
      method: 'DELETE'
    };

    return ApiHandlerService.fetch(url, config);
  }

  static get(variables, token) {
    const id = variables && variables.id;
    const url = `${env.API_BASE_URL}/intervals/${id}`;
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store'
      },
      method: 'GET'
    };

    return ApiHandlerService.fetch(url, config);
  }

  static getCycles(variables, token) {
    const id = variables && variables.id;
    const url = `${env.API_BASE_URL}/intervals/cycles/${id}`;
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store'
      },
      method: 'GET'
    };
    return ApiHandlerService.fetch(url, config);
  }

  static list(variables, token) {
    const examinationId = variables && variables.examinationId;
    const url = `${env.API_BASE_URL}/intervals?examination_id=${examinationId}`;
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store'
      },
      method: 'GET'
    };

    return ApiHandlerService.fetch(url, config);
  }

  static update(variables, token) {
    const payload = variables && variables.payload;

    const id = payload && payload.id;
    if (!id) throw Error('Id not specified in payload');

    delete payload.data; // do not send the data back
    if (!payload.name) delete payload.name; // https://github.com/zalando/connexion/issues/287

    const url = `${env.API_BASE_URL}/intervals/${id}`;
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store'
      },
      body: JSON.stringify({
        payload
      }),
      method: 'PUT'
    };

    return ApiHandlerService.fetch(url, config).then(res => {
      if (res && res.ok && res.data) {
        res.data.fields = variables && variables.fields; // return the fields also
      }
      return res;
    });
  }
}
