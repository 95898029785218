import { takeLatest, fork, put } from 'redux-saga/effects';
import { Types } from '../../reducers/intervals';
import { Actions } from '../../reducers/request';
import {
  Actions as NotificationsActions,
  MessageTypes
} from '../../reducers/notifications';

export const selectToken = state =>
  state.auth && state.auth.user && state.auth.user.token;

export function* loadIntervalExtended({ payload: { data } }) {
  try {
    if (!data || !data.interval) {
      return;
    }
  } catch (error) {
    yield put(
      NotificationsActions.showNotification({
        uid: 'network-error',
        message: error.message,
        type: MessageTypes.Error,
        flash: true
      })
    );
  }
}

export function* reloadIntervalOnMAUpdate(resp) {
  try {
    const {
      payload: { data, fields }
    } = resp;

    if (!fields || !data || !data.id) {
      return;
    }

    // GET BACK INTERVAL CYCLES
    yield put(Actions.getIntervalCycles(data.id));
  } catch (error) {
    yield put(
      NotificationsActions.showNotification({
        uid: 'network-error',
        message: error.message,
        type: MessageTypes.Error,
        flash: true
      })
    );
  }
}

function* intervalSaga() {
  yield fork(takeLatest, Types.GET_INTERVAL_SUCCESS, loadIntervalExtended);
  yield fork(
    takeLatest,
    Types.UPDATE_INTERVAL_SUCCESS,
    reloadIntervalOnMAUpdate
  );
}

export default intervalSaga;
