import { all, call } from 'redux-saga/effects';

import authSaga from './auth';
import notificationSaga from './notification';
import patientSaga from './patient';
import examinationSaga from './examination';
import evaluationSaga from './evaluation';
import intervalSaga from './interval';
import requestSaga from './request';

function* rootSaga() {
  yield all([
    call(authSaga),
    call(notificationSaga),
    call(patientSaga),
    call(intervalSaga),
    call(examinationSaga),
    call(evaluationSaga),
    call(requestSaga)
  ]);
}

export default rootSaga;
