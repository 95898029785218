import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@material-ui/core';

import i18n from '../../../i18n';

class ExamStatusView extends Component {
  static propTypes = {
    status: PropTypes.string,
    examinationId: PropTypes.number,
    message: PropTypes.string.isRequired,
    isPaired: PropTypes.bool,
    isMobileOnline: PropTypes.bool,
    elapsedTime: PropTypes.string.isRequired,
    stopExamination: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    messageClass: PropTypes.string
  };

  renderStatusBar() {
    const { status, isPaired, isMobileOnline } = this.props;

    if (status === 'loading') return null;

    const networkStatus = isMobileOnline ? ' / online' : ' / offline';

    // do not display network status if exam is not running
    return (
      <div className="status-bar">
        <p className="paired-status">
          {isPaired
            ? i18n.t(`examStatusInfo.paired`)
            : i18n.t(`examStatusInfo.notPaired`)}
          {status && !/completed|nodata|noExamInProgress|failed/.test(status)
            ? networkStatus
            : ''}
        </p>
      </div>
    );
  }

  renderElapsedTime() {
    const { elapsedTime } = this.props;
    return (
      <div className="elapsed-time">
        <p className="time-label">
          {`${i18n.t('examStatusInfo.elapsedTime')}:`}
        </p>
        <h1 className="time-value">{elapsedTime}</h1>
      </div>
    );
  }

  renderButton() {
    const { examinationId, status, stopExamination, close } = this.props;
    switch (status) {
      case 'completed':
      case 'loading':
      case 'nodata':
      case 'noExamInProgress':
      case 'stopping':
      case 'stopped':
      case 'failed':
        return (
          <button
            className="btn-red"
            type="button"
            onClick={() => close(examinationId)}
          >
            {i18n.t('examStatusActions.close')}
          </button>
        );
      case 'failedToStop':
        return (
          <div>
            <button
              className="btn-red"
              type="button"
              onClick={() => stopExamination(examinationId)}
            >
              Stop
            </button>
            <button
              className="btn-red"
              type="button"
              onClick={() => close(examinationId)}
            >
              {i18n.t('examStatusActions.close')}
            </button>
          </div>
        );
      case 'disconnected':
      default:
        return (
          <button
            className="btn-red"
            type="button"
            onClick={() => stopExamination(examinationId)}
          >
            Stop
          </button>
        );
    }
  }

  render() {
    const { message, messageClass } = this.props;

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open
        style={{ overflow: 'scroll' }}
      >
        <form
          id="exam-status-modal"
          className="modal-view exam-status box"
          autoComplete="off"
          style={{ outline: 'none' }}
        >
          {this.renderElapsedTime()}
          <div className="actual-status">
            <h1>{i18n.t(`examStatusInfo.examStatus`)}</h1>
            <p className={`exam-status-message ${messageClass}`}>{message}</p>
          </div>
          {this.renderStatusBar()}
          <div className="actions">{this.renderButton()}</div>
        </form>
      </Modal>
    );
  }
}

export default ExamStatusView;
