import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarView from './sidebar-view';

class Sidebar extends Component {
  static propTypes = {
    signOut: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired
  };

  constructor(props) {
    super(props);
    this.signOutHandler = this.signOutHandler.bind(this);
  }

  signOutHandler(e) {
    const { signOut } = this.props;
    const { history } = this.props;
    e.preventDefault();
    signOut();
    history.push('/');
  }

  render() {
    const { props } = this;
    const userToDisplay = props.user === null ? {} : props.user;
    return (
      <SidebarView
        user={userToDisplay}
        navigationRoutes={props.navigationRoutes}
        signOutHandler={this.signOutHandler}
      />
    );
  }
}

export default Sidebar;
