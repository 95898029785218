import React, { Component } from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line
import { TextField, MenuItem } from '@material-ui/core';
import '../../../../styles/benchmark.css';
import ChartBenchmark from '../../../charts/benchmark';
import Loader from '../../../loader';

import i18n from '../../../../i18n';
import ChartLabel from '../../../charts/labels';

class BenchmarkView extends Component {
  static propTypes = {
    benchmark: PropTypes.arrayOf(PropTypes.any),
    benchmarkDomain: PropTypes.shape({
      max: PropTypes.number,
      min: PropTypes.number,
      ticks: PropTypes.arrayOf(PropTypes.number)
    }),
    benchmarkSample: PropTypes.number,
    intervalName: PropTypes.string.isRequired,
    changeBenchmarkType: PropTypes.func.isRequired,
    benchmarkType: PropTypes.shape({
      value: PropTypes.number,
      name: PropTypes.string
    }).isRequired,
    possibleBenchmarkTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
    isBenchmarkLoading: PropTypes.bool,
    evaluationTime: PropTypes.string,
    movingAverage: PropTypes.number
  };

  static getMenuItems(items) {
    return items.map(item => (
      <MenuItem key={item.name} value={item.value}>
        {item.name}
      </MenuItem>
    ));
  }

  renderLoading() {
    const { isBenchmarkLoading } = this.props;
    if (isBenchmarkLoading) {
      return (
        <React.Fragment>
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              background: 'rgba(255,255,255,0.8)',
              zIndex: 98
            }}
          />
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '90%',
              zIndex: 99
            }}
          >
            <Loader />
          </div>
        </React.Fragment>
      );
    }
    return null;
  }

  renderCharts() {
    const {
      benchmark,
      benchmarkDomain,
      evaluationTime,
      movingAverage,
      benchmarkType,
      benchmarkSample
    } = this.props;
    return benchmark.map(chart => (
      <div className="chart box" key={chart.name}>
        <ChartLabel
          labelInfo={{
            evaluationTime,
            movingAverage,
            benchmarkType: benchmarkType.name,
            chartName: chart.name,
            benchmarkSample: benchmarkSample || 0
          }}
          labelType="benchmark"
        />
        <div>
          {this.renderLoading()}
          <ChartBenchmark
            averages={chart.data}
            borders={chart.borders}
            benchmarkDomain={benchmarkDomain}
          />
        </div>
      </div>
    ));
  }

  render() {
    const {
      intervalName,
      changeBenchmarkType,
      benchmarkType,
      possibleBenchmarkTypes
    } = this.props;

    return (
      <div className="benchmark">
        <div className="head">
          <h1>{intervalName}</h1>
          <p>{i18n.t('benchmarkInfo.chooseBenchmarkSet')}</p>
          <TextField
            select
            onChange={e => {
              e.preventDefault();
              changeBenchmarkType(e);
            }}
            value={benchmarkType.value}
            InputLabelProps={{
              shrink: true,
              style: { fontSize: '13pt' }
            }}
            style={{ display: 'inline-block', textAlign: 'left' }}
            name="movingAverage"
          >
            {BenchmarkView.getMenuItems(possibleBenchmarkTypes)}
          </TextField>
        </div>
        <div className="grid grid-two">{this.renderCharts()}</div>
      </div>
    );
  }
}

export default BenchmarkView;
