import React, { Component } from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line
import moment from 'moment';
import DiagnosisView from './diagnosis-view';
import Loader from '../../../loader';
import NotFound from '../../../not-found';

import i18n from '../../../../i18n';

class Diagnosis extends Component {
  static propTypes = {
    token: PropTypes.string,
    interval: PropTypes.shape({
      data: PropTypes.string,
      end: PropTypes.number.isRequired,
      examinationId: PropTypes.number.isRequired,
      ftEnd: PropTypes.number.isRequired,
      ftStart: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
      movingAverage: PropTypes.number.isRequired,
      speed: PropTypes.number.isRequired,
      start: PropTypes.number.isRequired,
      name: PropTypes.string
    }),
    evaluation: PropTypes.shape({
      results: PropTypes.string,
      id: PropTypes.number.isRequired
    }),
    print: PropTypes.func.isRequired,
    isIntervalFetching: PropTypes.bool,
    isEvaluationFetching: PropTypes.bool,
    intervalId: PropTypes.string,
    evaluationId: PropTypes.string,
    getInterval: PropTypes.func,
    getEvaluation: PropTypes.func,
    updateEvaluation: PropTypes.func.isRequired,
    evaluationErrors: PropTypes.shape({
      message: PropTypes.string
    }),
    intervalErrors: PropTypes.shape({
      message: PropTypes.string
    }),
    examinationId: PropTypes.string,
    getBenchmark: PropTypes.func,
    examination: PropTypes.shape({}),
    patient: PropTypes.shape({}),
    isExaminationFetching: PropTypes.bool,
    isPatientFetching: PropTypes.bool,
    patientId: PropTypes.number,
    getExamination: PropTypes.func,
    getPatient: PropTypes.func,
    examinationErrors: PropTypes.shape({}),
    patientErrors: PropTypes.shape({}),
    benchmarkErrors: PropTypes.shape({}),
    benchmark: PropTypes.shape({})
  };

  static getAveragesDomain(dimensions) {
    let max = 0;
    let min = 0;
    dimensions.forEach(dimension => {
      dimension.datasets.forEach(dataset => {
        dataset.forEach(piece => {
          if (piece.y >= max) {
            max = piece.y;
          }
          if (piece.y <= min) {
            min = piece.y;
          }
        });
      });
    });
    max = Math.ceil(max) + 2;
    min = Math.floor(min) - 2;

    const ticks = [];
    for (let x = min; x <= max; x += 1) {
      if (x % 2 === 0) {
        ticks.push(x);
      }
    }

    return { max, min, ticks };
  }

  constructor(props) {
    super(props);
    this.state = {};

    this.printReport = this.printReport.bind(this);
  }

  componentDidMount() {
    const { evaluation, getBenchmark } = this.props;
    if (evaluation && evaluation.benchmarkId.toString()) {
      getBenchmark(evaluation.benchmarkId, evaluation.id);
    }
  }

  /*
   * Returns the time of the evaluation
   */
  getEvaluationTime() {
    const { evaluation } = this.props;
    if (!evaluation || !evaluation.ftStart || !evaluation.ftEnd) return '';

    return `${moment()
      .minutes(0)
      .seconds(evaluation.ftStart / 100)
      .format('m:ss')}
      - 
      ${moment()
        .minutes(0)
        .seconds(evaluation.ftEnd / 100)
        .format('m:ss')}`;
  }

  /**
   * Returns the name of the interval if specified. Falls back to the speed.
   */
  getIntervalName() {
    const { interval } = this.props;

    if (!interval) return '';

    return interval.name || `${interval.speed} km/h`;
  }

  /**
   * Extracts the parameters from the state
   */
  getParameters() {
    const { evaluation } = this.props;

    if (!evaluation || !evaluation.results) return undefined;
    const results = JSON.parse(evaluation.results.replace(/\bNaN\b/g, "null"));

    const parameters = results && results.parameters;
    return parameters;
  }

  /**
   * Maps the API data to the chart format.
   */
  avgsToChart() {
    const { evaluation } = this.props;

    if (!evaluation || !evaluation.avgCurves)
      return { chartAvgs: [], domain: {} };

    const avgs = JSON.parse(evaluation.avgCurves.replace(/\bNaN\b/g, "null"));

    if (!avgs) return { chartAvgs: [], domain: {} };

    const chartAvgs = [];

    const V = { name: 'V', datasets: [] };
    const S = { name: 'S', datasets: [] };
    const H = { name: 'H', datasets: [] };

    chartAvgs.push(V, S, H);

    const newDatasetVleft = [];
    const newDatasetVright = [];
    const newDatasetSleft = [];
    const newDatasetSright = [];
    const newDatasetHleft = [];
    const newDatasetHright = [];

    avgs.forEach(d => {
      newDatasetVleft.push({ x: d.ss, y: d.lavg_v });
      newDatasetVright.push({ x: d.ss, y: d.ravg_v });
      newDatasetSleft.push({ x: d.ss, y: d.lavg_s });
      newDatasetSright.push({ x: d.ss, y: d.ravg_s });
      newDatasetHleft.push({ x: d.ss, y: d.lavg_h });
      newDatasetHright.push({ x: d.ss, y: d.ravg_h });
    });

    V.datasets.push(newDatasetVleft);
    V.datasets.push(newDatasetVright);
    S.datasets.push(newDatasetSleft);
    S.datasets.push(newDatasetSright);
    H.datasets.push(newDatasetHleft);
    H.datasets.push(newDatasetHright);

    const domain = Diagnosis.getAveragesDomain(chartAvgs);

    return { chartAvgs, domain };
  }

  printReport(evaluationId) {
    const { token, print } = this.props;

    print('report', {
      evaluationId,
      token
    });
  }

  render() {
    const {
      print,
      interval,
      evaluation,
      isIntervalFetching,
      isEvaluationFetching,
      intervalId,
      evaluationId,
      getInterval,
      getEvaluation,
      evaluationErrors,
      intervalErrors,
      examinationId,
      updateEvaluation,
      examination,
      patient,
      isExaminationFetching,
      isPatientFetching,
      patientId,
      getExamination,
      getPatient,
      examinationErrors,
      patientErrors,
      benchmarkErrors,
      benchmark
    } = this.props;

    if (evaluationErrors || intervalErrors) {
      const errorMessage =
        (evaluationErrors && evaluationErrors.message) ||
        (intervalErrors && intervalErrors.message);
      return (
        <NotFound
          isWrapped
          message={i18n.t(`backendResponse.${errorMessage}`)}
          backTo={`/examinations/${examinationId}/overview`}
        />
      );
    }

    const averages = this.avgsToChart();
    const parameters = this.getParameters();

    if (
      evaluationErrors ||
      intervalErrors ||
      examinationErrors ||
      patientErrors ||
      benchmarkErrors
    ) {
      const errorMessage =
        (evaluationErrors && evaluationErrors.message) ||
        (intervalErrors && intervalErrors.message) ||
        (examinationErrors && examinationErrors.message) ||
        (patientErrors && patientErrors.message) ||
        (benchmarkErrors && benchmarkErrors.message);
      return (
        <NotFound
          isWrapped
          icon
          message={i18n.t(`backendResponse.${errorMessage}`)}
          backTo={`/examinations/${examinationId}/overview`}
        />
      );
    }

    if (!evaluation) {
      if (!isEvaluationFetching) {
        getEvaluation(evaluationId);
      }
    }

    if (!interval) {
      if (!isIntervalFetching) {
        getInterval(intervalId);
      }
    }

    if (!examination) {
      if (!isExaminationFetching) {
        getExamination(examinationId);
      }
    }

    if (!patient) {
      if (!isPatientFetching) {
        getPatient(patientId);
      }
    }

    if (!evaluation || !examination || !interval || !patient) {
      return (
        <Loader isWrapped message={i18n.t('loadingInfo.loadingDiagnosis')} />
      );
    }

    return (
      <DiagnosisView
        evaluationTime={this.getEvaluationTime()}
        movingAverage={evaluation && evaluation.movingAverage}
        medicalFields={Diagnosis.medicalFields}
        intervalName={this.getIntervalName()}
        evaluationId={evaluation && evaluation.id}
        evaluation={evaluation}
        examination={examination}
        patient={patient}
        interval={interval}
        benchmark={benchmark}
        averages={averages.chartAvgs}
        averagesDomain={averages.domain}
        parameters={parameters}
        printReport={this.printReport}
        print={print}
        updateEvaluation={updateEvaluation}
      />
    );
  }
}

export default Diagnosis;
