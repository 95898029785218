import React, { Component } from 'react';
import Icon from '@material-ui/core/Icon';
import Sidebar from '../layout/sidebar';
import PropTypes from 'prop-types'; // eslint-disable-line

export class Menu extends Component {
  static propTypes = {
    menuToogle: PropTypes.func.isRequired,
    isMenuOpen: PropTypes.bool.isRequired
  };

  menuToogle() {
    const { menuToogle } = this.props;
    menuToogle();
  }

  menuStyle(elem) {
    const { isMenuOpen } = this.props;
    if (isMenuOpen) {
      return '';
    }
    return `${elem}-closed-menu`;
  }

  menuToogleText() {
    const { isMenuOpen } = this.props;
    if (isMenuOpen) {
      return <Icon>menu</Icon>;
    }
    return <Icon>menu</Icon>;
  }

  render() {
    return (
      <div className={`menu-view ${this.menuStyle('menu-view')}`}>
        <Sidebar />
        <div
          className="toogle-menu"
          role="presentation"
          onClick={() => this.menuToogle()}
        >
          {this.menuToogleText()}
        </div>
      </div>
    );
  }
}

export default Menu;
