import { connect } from 'react-redux';
import NewExamination from './new-examination';
import { addExamination } from '../../../reducers/request';

const mapStateToProps = () => ({});

export default connect(
  mapStateToProps,
  {
    addExamination
  }
)(NewExamination);
