import { takeLatest, fork, put } from 'redux-saga/effects';
import { Types } from '../../reducers/examinations';
import { getExamination } from '../../reducers/request';
import {
  Actions as NotificationsActions,
  MessageTypes
} from '../../reducers/notifications';

export function* updated(response) {
  yield put(
    NotificationsActions.showNotification({
      message: response && response.payload && response.payload.message,
      type: MessageTypes.Success,
      flash: true
    })
  );
}

export function* paired(response) {
  yield put(
    NotificationsActions.showNotification({
      message: response && response.payload && response.payload.message,
      type: MessageTypes.Success,
      flash: true
    })
  );
}

export function* recorded(payload) {
  const examId =
    payload &&
    (payload.id ||
      (payload.payload &&
        payload.payload.data &&
        payload.payload.data.examinationId));
  if (examId) {
    yield put(getExamination(examId));
  }
}

function* examinationSaga() {
  yield fork(
    takeLatest,
    [Types.UPDATE_EXAMINATION_SUCCESS, Types.ADD_EXAMINATION_SUCCESS],
    updated
  );
  yield fork(takeLatest, [Types.PAIR_DEVICE_SUCCESS], paired);
  yield fork(
    takeLatest,
    [Types.CLEAN_EXAMINATION_PROGRESS, Types.RESEND_EXAMINATION_DATA_SUCCESS],
    recorded
  );
}

export default examinationSaga;
