import React, { Component } from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line
import moment from 'moment';
import EvaluationListView from './evaluation-list-view';

class EvaluationList extends Component {
  static propTypes = {
    isFetching: PropTypes.bool,
    evaluations: PropTypes.arrayOf(
      PropTypes.shape({
        intervalId: PropTypes.number.isRequired,
        ftEnd: PropTypes.number.isRequired,
        ftStart: PropTypes.number.isRequired,
        id: PropTypes.number.isRequired,
        movingAverage: PropTypes.number.isRequired,
        results: PropTypes.string,
        speed: PropTypes.number.isRequired
      })
    ),
    intervalId: PropTypes.number,
    examinationId: PropTypes.number,
    listEvaluations: PropTypes.func.isRequired,
    cleanBenchmark: PropTypes.func
  };

  async componentDidMount() {
    const { intervalId, isFetching, listEvaluations } = this.props;

    // load evaluations if not yet being loaded
    if (!isFetching) {
      listEvaluations(intervalId);
    }
  }

  getExactEvaluations() {
    const { evaluations, intervalId } = this.props;
    const newEvaluations = [];

    if (!evaluations) return [];

    // @TODO: should be useless, since evaluations should only contain the items
    // belonging to the actual intervalId
    // => clean this state on returning to the interval or overview tab
    // (or on main menu navigation -> less important)!
    evaluations.forEach(evaluation => {
      if (evaluation.intervalId === intervalId && evaluation.results) {
        newEvaluations.push(evaluation);
      }
    });

    return newEvaluations;
  }

  static getEvaluationTime(evaluation) {
    if (!evaluation || !evaluation.ftStart || !evaluation.ftEnd) return '';

    return `${moment()
      .minutes(0)
      .seconds(evaluation.ftStart / 100)
      .format('m:ss')}
      - 
      ${moment()
        .minutes(0)
        .seconds(evaluation.ftEnd / 100)
        .format('m:ss')}`;
  }

  static getStepCount(evaluation) {
    if (!evaluation || !evaluation.results) return '';
    const jsonData = JSON.parse(evaluation.results.replace(/\bNaN\b/g, "null"));

    if (!jsonData.dimensions) return '';
    return jsonData.dimensions.length;
  }

  render() {
    const { intervalId, examinationId, cleanBenchmark } = this.props;

    const pathStart = `/examinations/${examinationId}/interval/${intervalId}/evaluation`;

    const evaluations = this.getExactEvaluations();

    return (
      <EvaluationListView
        evaluations={evaluations}
        pathStart={pathStart}
        cleanBenchmark={cleanBenchmark}
        getEvaluationTime={EvaluationList.getEvaluationTime}
        getStepCount={EvaluationList.getStepCount}
      />
    );
  }
}

export default EvaluationList;
