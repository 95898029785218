import { connect } from 'react-redux';
import { updateExamination } from '../../../reducers/request';
import EditExamination from './edit-examination';

const mapStateToProps = () => ({});

export default connect(
  mapStateToProps,
  {
    updateExamination
  }
)(EditExamination);
