import moment from 'moment';
import i18n from '../../i18n';

export const ModalTypes = {
  Patient: 'patient',
  Examination: 'examination'
};

const patientFields = {
  firstName: {
    name: 'firstName',
    type: 'text',
    placeholder: i18n.t('patientInfo.firstName'),
    label: i18n.t('patientInfo.firstName'),
    required: true,
    changed: false,
    defaultValue: ''
  },
  lastName: {
    name: 'lastName',
    type: 'text',
    placeholder: i18n.t('patientInfo.lastName'),
    label: i18n.t('patientInfo.lastName'),
    required: true,
    changed: false,
    defaultValue: ''
  },
  dateOfBirth: {
    name: 'dateOfBirth',
    type: 'date',
    label: i18n.t('patientInfo.dateOfBirth'),
    required: true,
    changed: false,
    defaultValue: '2000-01-01'
  },
  placeOfBirth: {
    name: 'placeOfBirth',
    type: 'text',
    label: i18n.t('patientInfo.placeOfBirth'),
    placeholder: 'Budapest',
    required: true,
    changed: false,
    defaultValue: ''
  },
  mothersName: {
    name: 'mothersName',
    type: 'text',
    label: i18n.t('patientInfo.mothersName'),
    placeholder: 'Anyja neve',
    required: true,
    changed: false,
    defaultValue: ''
  },
  gender: {
    type: 'select',
    name: 'gender',
    values: ['male', 'female'],
    default: 'male',
    label: i18n.t('patientInfo.gender'),
    required: true,
    changed: false,
    defaultValue: 'male'
  },
  dominantSide: {
    type: 'radio',
    name: 'dominantSide',
    values: ['left', 'right'],
    label: i18n.t('patientInfo.dominantSide'),
    required: true,
    changed: false,
    defaultValue: 'right'
  },
  address: {
    type: 'text',
    name: 'address',
    placeholder: '1111 Budapest, Kossuth Lajus utca 1.',
    label: i18n.t('patientInfo.address'),
    changed: false,
    defaultValue: ''
  },
  email: {
    type: 'text',
    name: 'email',
    placeholder: 'email@email.com',
    label: i18n.t('patientInfo.email'),
    changed: false,
    defaultValue: ''
  },
  phone: {
    type: 'text',
    name: 'phone',
    placeholder: '+36-30-123-4567',
    label: i18n.t('patientInfo.phone'),
    changed: false,
    defaultValue: ''
  },
  statement: {
    type: 'checkbox',
    name: 'statement',
    label: i18n.t('patientInfo.statementIsPrinted'),
    required: true,
    changed: false,
    checked: false,
    value: false
  }
};

const examFields = {
  weight: {
    type: 'text',
    name: 'weight',
    placeholder: '70',
    label: i18n.t('examinationInfo.weight'),
    required: true,
    changed: false,
    defaultValue: ''
  },
  height: {
    type: 'text',
    name: 'height',
    placeholder: '180',
    label: i18n.t('examinationInfo.height'),
    required: true,
    changed: false,
    defaultValue: ''
  },
  examType: {
    type: 'select',
    name: 'examType',
    values: ['treadmill', 'other'],
    label: i18n.t('examinationInfo.examType'),
    required: true,
    changed: false,
    defaultValue: 'treadmill'
  },
  comment: {
    type: 'textarea',
    name: 'comment',
    placeholder: i18n.t('examinationInfo.commentPlaceholder'),
    label: i18n.t('examinationInfo.comment'),
    required: false,
    changed: false,
    defaultValue: ''
  },
  date: {
    type: 'datetime-local',
    name: 'date',
    placeholder: '2018-02-10 13:12:10',
    label: i18n.t('examinationInfo.dateOfExamination'),
    required: true,
    changed: false,
    defaultValue: moment().format('YYYY-MM-DDTHH:mm')
  }
};

export function getModalFields(type, defaults) {
  let fields = [];
  switch (type) {
    case 'patient':
      fields = patientFields;
      break;
    case 'examination':
      fields = examFields;
      break;
    default:
      break;
  }

  Object.keys(fields).forEach(fieldName => {
    if (defaults !== null && defaults[fieldName]) {
      fields[fieldName].value = defaults[fieldName];
    } else {
      fields[fieldName].value = fields[fieldName].defaultValue;
    }
    fields[fieldName].changed = false;
  });

  return fields;
}
