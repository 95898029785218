import React, { Component } from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line
import moment from 'moment';
import '../../../../styles/overview.css';
import OverviewView from './overview-view';
// import Loader from '../../../loader';

const CHART_WIDTH = 1170;

class Overview extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string
      })
    }).isRequired,
    examination: PropTypes.shape({
      comment: PropTypes.string,
      date: PropTypes.string.isRequired,
      dominantSide: PropTypes.string,
      examType: PropTypes.string.isRequired,
      hasData: PropTypes.bool.isRequired,
      height: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
      patientId: PropTypes.number.isRequired,
      results: PropTypes.string,
      weight: PropTypes.number.isRequired
    }),
    intervals: PropTypes.arrayOf(PropTypes.any),
    addInterval: PropTypes.func.isRequired,
    deleteInterval: PropTypes.func.isRequired,
    getInterval: PropTypes.func.isRequired,
    listIntervals: PropTypes.func.isRequired,
    updateInterval: PropTypes.func.isRequired,
    examinationId: PropTypes.string,
    isIntervalsFetching: PropTypes.bool
  };

  /**
   * Formats the X axis tickers of the chart
   *
   * @param {int} sec The time to format
   */
  static tickFormatter(sec) {
    return moment()
      .minutes(0)
      .second(sec / 100)
      .format('m:ss');
  }

  // The possible speeds the user can choose from
  possibleSpeedOptions = [2, 4, 6, 8, 10];

  /**
   * Returns the name of the interval if specified. Falls back to the speed.
   */
  static getIntervalName(interval) {
    if (!interval) return '';

    return interval.name || `${interval.speed} km/h`;
  }

  constructor(props) {
    super(props);
    this.getResultData = this.getResultData.bind(this);
    this.changeDraggablePosition = this.changeDraggablePosition.bind(this);
    this.newInterval = this.newInterval.bind(this);
    this.deleteInterval = this.deleteInterval.bind(this);
    this.convertXToTime = this.convertXToTime.bind(this);
    this.convertTimeToX = this.convertTimeToX.bind(this);
    this.getDraggableBounds = this.getDraggableBounds.bind(this);
    this.getDraggablePosition = this.getDraggablePosition.bind(this);
    this.handleSpeedChange = this.handleSpeedChange.bind(this);
    this.getIntervalClean = this.getIntervalClean.bind(this);
    this.state = { actualSpeed: 2 };
  }

  componentDidMount() {
    const { listIntervals, examinationId } = this.props;
    listIntervals(examinationId);
  }

  /**
   * Extracts the interpolated result data from the examination
   */
  getResultData() {
    const { examination } = this.props;
    const results =
      examination && examination.results && JSON.parse(examination.results.replace(/\bNaN\b/g, "null"));
    return results && results.data;
  }

  /**
   * Extracts the vectors from the examination
   */
  getResultVectors() {
    const { examination } = this.props;
    const results =
      examination && examination.results && JSON.parse(examination.results.replace(/\bNaN\b/g, "null"));
    return results && results.vectors;
  }

  /**
   * Generates the X coordinate ticks of the chart.
   */
  getTimeTicks() {
    const data = this.getResultData();
    if (!data) return [];

    // split to 20 even ranges
    const ratio = Math.ceil(data.length / 20);
    const ticks = data
      .filter((value, index) => index % ratio === 0)
      .map(d => d.time);

    return ticks;
  }

  /**
   * Returns the min / max draggable boundaries of the interval.
   *
   * @param {object} interval The interval to limit
   * @param {int} index The index of the interval in the list
   */
  getDraggableBounds(interval, index) {
    const { intervals } = this.props;
    const data = this.getResultData();

    return {
      // the start of the interval (=position of the previous marker)
      left: this.convertTimeToX(interval.start || 0),
      // the right boundary is the end of the next interval
      // (=position if the next marker) or the end of the timescale
      right: this.convertTimeToX(
        index < intervals.length - 1
          ? intervals[index + 1].end
          : data[data.length - 1].time
      )
    };
  }

  getIntervalClean(id) {
    const { getInterval } = this.props;
    getInterval(id);
  }

  /**
   * Returns the position of the separator marker.
   */
  getDraggablePosition(interval) {
    return { x: this.convertTimeToX(interval.end), y: 0 };
  }

  /**
   * Changes the speed of the new interval
   *
   * @param {int} newSpeed The changed speed
   */
  handleSpeedChange(newSpeed) {
    this.setState({ actualSpeed: newSpeed });
  }

  /**
   * Adds a new interval.
   *
   * @param {object} e Event
   */
  newInterval(e) {
    e.preventDefault();

    const { actualSpeed } = this.state;

    const data = this.getResultData();
    const { match, intervals, addInterval } = this.props;
    const examId = match && match.params && match.params.id;

    if (!data) {
      return;
    }

    // extract the max time and the end of the last interval (otherwise 0)
    const timeEnd = data[data.length - 1].time;

    const lastIntervalEnd =
      (intervals &&
        intervals.length > 0 &&
        intervals[intervals.length - 1].end) ||
      0;

    // 1 minute swap
    let end;
    if (timeEnd - lastIntervalEnd > 6000) {
      end = lastIntervalEnd + 6000;
    } else {
      end = timeEnd;
    }

    /*
     * Not necessary for further use
     */
    // let speed = e.target.children[1].children[0].value;
    // speed = speed && parseInt(speed, 0);

    const interval = {
      end,
      examinationId: parseInt(examId, 0),
      ftEnd: end,
      ftStart: lastIntervalEnd,
      movingAverage: actualSpeed >= 8 ? 15 : 20,
      /*
       *
       * Name input deleted, if still needed -> it's an empty string
       */
      // name: e.target.children[0].children[0].value,
      name: '',

      speed: actualSpeed,
      start: lastIntervalEnd
    };

    addInterval(interval);
  }

  /**
   * Deletes an interval
   *
   * @param {int} id Id of the interval to delete
   * @param {object} interval The interval to delete
   * @param {int} index Index of the interval to delete
   */
  deleteInterval(id, interval, index, sortedIntervals) {
    if (!id) return;

    const { deleteInterval, updateInterval } = this.props;

    // delete the interval
    deleteInterval(id);

    let nextInterval;
    switch (index) {
      case sortedIntervals.length - 1: // last interval
        // do nothing
        break;

      default:
        // prepare the next interval
        nextInterval = { ...sortedIntervals[index + 1] };
        nextInterval.start = interval.start;

        // update the next interval
        updateInterval(nextInterval);
    }
  }

  /**
   * Converts an interval start/end edge (time) to a x-axis (pixels) marker position.
   *
   * @param {int} position The time position of the marker
   */
  convertTimeToX(timePosition) {
    const data = this.getResultData();
    if (!data) return 0;

    // extract the timescale
    const lastItem = data[data.length - 1];

    // avoid division by 0
    if (!lastItem.time) {
      return 0;
    }

    // CHART_WIDTH is the fixed width of the chart => the position
    // is relative to the full timescale
    const result = (CHART_WIDTH / lastItem.time) * timePosition;

    // marker position might be rounded by the browser while boundaries are not rounded
    // => current position of the marker might be out of the boundaries
    // => convert result to 2 decimal places (both for boundaries and positions)
    return Math.round(result * 100) / 100;
  }

  /**
   * Converts X coordinate (pixels) to time values for intervals.
   *
   * @param {int} position The position of the marker as X pixels coordinate
   */
  convertXToTime(xPosition) {
    const data = this.getResultData();
    if (!data) return 0;

    // extract the timescale
    const lastItem = data[data.length - 1];

    // CHART_WIDTH is the fixed width of the chart => the position
    // is relative to the full timescale
    return (lastItem.time / CHART_WIDTH) * xPosition;
  }

  /**
   * Updates the intervals through the backend API.
   *
   * It updates both the one changed and the next one (if any).
   *
   * @param {object} e The change event
   * @param {object} data The changed data
   * @param {object} interval The interval changed with the original data
   * @param {object} index The index of the changed interval
   */
  changeDraggablePosition(e, data, interval, intervals, index) {
    const { updateInterval } = this.props;

    if (!intervals) return;

    const newPosition = this.convertXToTime(data.x);

    // update the current interval
    if (interval.speed !== 0) {
      updateInterval({
        ...interval,
        end: newPosition
      });
    }

    // check if this was the last interval
    if (index === intervals.length - 1) {
      return;
    }

    // sort the intervals by the end time
    const sortedIntervals = [...intervals].sort((a, b) => a.end - b.end);

    // update the next interval
    updateInterval({
      ...sortedIntervals[index + 1],
      start: newPosition
    });
  }

  intervalsToDraggableFormat() {
    const { intervals } = this.props;
    let intervalsToReturn = [];
    if (intervals.length > 0) {
      intervalsToReturn = [...intervals].sort((a, b) => a.end - b.end);

      // insert the first separator
      if (!intervalsToReturn[0].speed !== 0) {
        intervalsToReturn.unshift({
          id: -2,
          end: intervalsToReturn[0].start,
          name: '0 km/h',
          speed: 0,
          start: 0
        });
      }
    }
    return intervalsToReturn;
  }

  render() {
    const data = this.getResultData();
    const vectors = this.getResultVectors();
    const { isIntervalsFetching } = this.props;
    const { actualSpeed } = this.state;

    return (
      <OverviewView
        isIntervalsFetching={isIntervalsFetching}
        vectors={vectors}
        intervals={this.intervalsToDraggableFormat()}
        data={data}
        convertTimeToX={this.convertTimeToX}
        convertXToTime={this.convertXToTime}
        deleteInterval={this.deleteInterval}
        newInterval={this.newInterval}
        ticks={this.getTimeTicks()}
        tickFormatter={Overview.tickFormatter}
        changeDraggablePosition={this.changeDraggablePosition}
        getInterval={this.getIntervalClean}
        getDraggableBounds={this.getDraggableBounds}
        getDraggablePosition={this.getDraggablePosition}
        getIntervalName={Overview.getIntervalName}
        possibleSpeedOptions={this.possibleSpeedOptions}
        actualSpeed={actualSpeed}
        handleSpeedChange={this.handleSpeedChange}
      />
    );
  }
}

export default Overview;
