import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types'; // eslint-disable-line

import i18n from 'i18n-js';

import '../../styles/examination-list.css';
import moment from 'moment';

export default class ExaminationsListView extends Component {
  static propTypes = {
    examinations: PropTypes.shape({
      isFetching: PropTypes.bool.isRequired,
      examinations: PropTypes.arrayOf(PropTypes.any).isRequired
    }),
    isDisplayPatientName: PropTypes.bool.isRequired,
    cleanIntervals: PropTypes.func,
    displayCount: PropTypes.number
  };

  renderStatus(examination) {
    const { cleanIntervals } = this.props;
    if (examination.hasData) {
      return (
        <div className="status">
          <Link
            to={{
              pathname: `/examinations/${examination.id}/overview`,
              startExam: false
            }}
            onClick={() => cleanIntervals()}
          >
            <div className="start-exam-badge">
              <h1>{i18n.t('examinationsList.showOverview')}</h1>
            </div>
          </Link>
        </div>
      );
    }

    return (
      <div className="status">
        <Link
          to={{
            pathname: `/examinations/${examination.id}/overview`,
            startExam: true
          }}
          onClick={() => cleanIntervals()}
        >
          <div className="start-exam-badge">
            <h1>{i18n.t('examinationsList.showOverview')}</h1>
          </div>
        </Link>
      </div>
    );
  }

  renderExaminations() {
    const {
      examinations,
      isDisplayPatientName,
      cleanIntervals,
      displayCount
    } = this.props;

    let sortedExaminations =
      (examinations &&
        examinations.examinations &&
        examinations.examinations.sort((a, b) =>
          moment(b.date).diff(moment(a.date))
        )) ||
      [];

    if (displayCount) {
      sortedExaminations = sortedExaminations.slice(0, displayCount);
    }

    return sortedExaminations.map(examination => (
      <div className="exam-wrapper" key={examination.id}>
        <Link
          key={examination.id}
          to={`/examinations/${examination.id}/overview`}
          style={{ textDecoration: 'none' }}
          onClick={() => cleanIntervals()}
        >
          <div className="list-item box">
            <div className="column-exam-date">
              {examination.date &&
                new Date(examination.date).toLocaleDateString('hu-HU')}
            </div>
            {isDisplayPatientName ? (
              <div className="column-name">
                {`${examination.lastName} ${examination.firstName}`}
              </div>
            ) : null}
            <div className="column-exam-comment">{examination.comment}</div>
            <div className="column-exam-anamnesis">
              {examination.leftHip ||
              examination.rightHip ||
              examination.leftKnee ||
              examination.rightKnee ||
              examination.leftFoot ||
              examination.rightFoot ||
              examination.pelvis
                ? null
                : 'n/a'}
              {examination.leftHip
                ? `${i18n.t('diagnosisInfo.left')} ${i18n.t(
                    'diagnosisInfo.hip'
                  )}: ${examination.leftHip} | `
                : null}
              {examination.rightHip
                ? `${i18n.t('diagnosisInfo.right')} ${i18n.t(
                    'diagnosisInfo.hip'
                  )}: ${examination.rightHip} | `
                : null}
              {examination.leftKnee
                ? `${i18n.t('diagnosisInfo.left')} ${i18n.t(
                    'diagnosisInfo.knee'
                  )}: ${examination.leftKnee} | `
                : null}
              {examination.rightKnee
                ? `${i18n.t('diagnosisInfo.right')} ${i18n.t(
                    'diagnosisInfo.knee'
                  )}: ${examination.rightKnee} | `
                : null}
              {examination.leftFoot
                ? `${i18n.t('diagnosisInfo.left')} ${i18n.t(
                    'diagnosisInfo.foot'
                  )}: ${examination.leftFoot} | `
                : null}
              {examination.rightFoot
                ? `${i18n.t('diagnosisInfo.right')} ${i18n.t(
                    'diagnosisInfo.foot'
                  )}: ${examination.rightFoot} | `
                : null}
              {examination.pelvis
                ? `${i18n.t('diagnosisInfo.pelvis')}: ${examination.pelvis}`
                : null}
            </div>
          </div>
        </Link>
        {this.renderStatus(examination)}
      </div>
    ));
  }

  render() {
    const { isDisplayPatientName } = this.props;
    return (
      <div className="examination-list examinations-grid">
        <div className="list-item list-header">
          <div className="column-exam-date">
            {i18n.t('examinationsList.date')}
          </div>
          {isDisplayPatientName ? (
            <div className="column-name">
              {i18n.t('examinationsList.patientName')}
            </div>
          ) : null}
          <div className="column-exam-comment">
            {i18n.t('examinationsList.comment')}
          </div>
          <div className="column-exam-anamnesis">
            {i18n.t('examinationsList.anamnesis')}
          </div>
        </div>
        {this.renderExaminations()}
      </div>
    );
  }
}
