import React, { Component } from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line
import NewPatient from '../../components/modals/new-patient';

import i18n from '../../i18n';

import '../../styles/patients.css';
import PatientsList from '../../components/patient-list';

export default class PatientsView extends Component {
  static propTypes = {
    patients: PropTypes.shape({
      isFetching: PropTypes.bool.isRequired,
      patients: PropTypes.arrayOf(PropTypes.any).isRequired
    }),
    searchedPatients: PropTypes.arrayOf(PropTypes.any).isRequired,
    search: PropTypes.shape({
      show: PropTypes.string
    }),
    isModalOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    searchChanged: PropTypes.func.isRequired,
    closeSearch: PropTypes.func.isRequired
  };

  renderSearchedPatients() {
    const { searchedPatients } = this.props;
    return (
      <PatientsList
        shouldSort
        patients={{ patients: searchedPatients, isFetching: false }}
      />
    );
  }

  render() {
    const {
      search,
      searchChanged,
      closeSearch,
      isModalOpen,
      closeModal,
      openModal,
      patients
    } = this.props;
    return (
      <div className="patients">
        <div className="search">
          <h1>{i18n.t('searchInfo.searchForPatient')}</h1>
          <div className="search-fields">
            <div className="search-field">
              <h1>{i18n.t('searchInfo.byName')}</h1>
              <div className="search-field-input box">
                <input
                  name="name"
                  type="text"
                  placeholder={i18n.t('searchPlaceHolder.name')}
                  onChange={e => searchChanged(e)}
                />
              </div>
            </div>
            <div className="search-field">
              <h1>{i18n.t('searchInfo.byExamComment')}</h1>
              <div className="search-field-input box">
                <input
                  name="comment"
                  type="text"
                  placeholder={i18n.t('searchPlaceHolder.comment')}
                  onChange={e => searchChanged(e)}
                />
              </div>
            </div>
            <div className="search-field">
              <h1>{i18n.t('searchInfo.byExamDate')}</h1>
              <div className="search-field-input box">
                <input
                  name="date"
                  type="text"
                  placeholder="2018-02"
                  onChange={e => searchChanged(e)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={`search-results ${search.show}`}>
          <h1>{i18n.t('searchInfo.results')}</h1>
          {this.renderSearchedPatients()}
          <button
            type="button"
            className="btn-red close-search"
            onClick={() => closeSearch()}
          >
            {i18n.t('searchActions.close')}
          </button>
        </div>

        <NewPatient isOpen={isModalOpen} closeModal={closeModal} />

        <h1 className="route-title">{i18n.t('navigation.patients')}</h1>

        <div className="new-patient">
          <button className="btn-zoom" onClick={openModal} type="button">
            {i18n.t('patientActions.newPatient')}
          </button>
        </div>

        <PatientsList shouldSort patients={patients || {}} />
      </div>
    );
  }
}
