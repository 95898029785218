import { takeLatest, call, put, fork } from 'redux-saga/effects';
import AuthApi from '../../api/auth';
import {
  Types as AuthTypes,
  Actions as AuthActions
} from '../../reducers/auth';
import { Actions as AppActions } from '../../reducers/app';

export function* signIn(action) {
  if (!action) {
    // Throw error if not valid action type
    throw new Error('Action not defined');
  }
  try {
    // Clear out last stored user
    yield put({ type: 'FLUSH' });

    // TURNED OFF WHILE USING TEST DATA
    // Set fetching true
    // yield put(AppActions.startApiFetching());

    // Send API call to authenticate user
    const response = yield call(AuthApi.signIn, action.payload);

    // If authentication was successful:
    if (response && response.ok) {
      // Call the success reducer with the data got from API
      yield put(AuthActions.signInSuccess(response));

      // Fetch the user
      // Now the AuthApi.signIn returns the full user data
      // yield put(getUser());

      // If the response contains errors
    } else if (response && response.data && response.data.errors) {
      // Set the errors to the state
      yield put(AppActions.signInError(response.data.errors));

      // If some error happened during fetching
    } else {
      // Set this specific error message to the state
      yield put(AppActions.signInError('Something went wrong.'));
    }

    // If some error happened during the communication process:
  } catch (e) {
    // Set this specific error message to the state
    yield put(AppActions.signInError('Something went wrong. Try again.'));

    // End of the process:
  } finally {
    // TURNED OFF WHILE USING TEST DATA
    // When the whole process ends, set the fetching false
    // yield put(AppActions.stopApiFetching());
  }
}

export function* signOut(action) {
  if (!action) {
    throw new Error('Action not defined');
  }
  try {
    yield put(AuthActions.signedOut());
    yield put({ type: 'FLUSH' });
  } catch (e) {
    // yield put();
  } finally {
    // navigationService.navigate('Auth');
  }
}

function* authSaga() {
  yield fork(takeLatest, AuthTypes.SIGN_IN, signIn);
  yield fork(takeLatest, AuthTypes.SIGN_OUT, signOut);
}

export default authSaga;
