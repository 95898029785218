/* eslint-disable linebreak-style */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { TextField } from '@material-ui/core';
import i18n from '../../i18n';

class ExaminationDiagnosisBodyView extends Component {
  static propTypes = {
    anamnesis: PropTypes.shape({
      leftHip: PropTypes.string,
      rightHip: PropTypes.string,
      leftKnee: PropTypes.string,
      rightKnee: PropTypes.string,
      leftFoot: PropTypes.string,
      rightFoot: PropTypes.string,
      pelvis: PropTypes.string
    }),
    onAnamnesisChange: PropTypes.func
  };

  static medicalFields = {
    left: [
      {
        name: i18n.t('diagnosisInfo.hip'),
        key: 'hip',
        dictionary: []
      },
      {
        name: i18n.t('diagnosisInfo.knee'),
        key: 'knee',
        dictionary: []
      },
      {
        name: i18n.t('diagnosisInfo.foot'),
        dictionary: [],
        key: 'foot'
      }
    ],
    right: [
      {
        name: i18n.t('diagnosisInfo.hip'),
        dictionary: [],
        key: 'hip'
      },
      {
        name: i18n.t('diagnosisInfo.knee'),
        key: 'knee',
        dictionary: []
      },
      {
        name: i18n.t('diagnosisInfo.foot'),
        key: 'foot',
        dictionary: []
      }
    ],
    bottom: [
      {
        name: i18n.t('diagnosisInfo.pelvis'),
        key: 'pelvis',
        dictionary: []
      }
    ]
  };

  static selectOptions = {
    hip: [],
    knee: [
      {
        name: 'aclRupture',
        value: i18n.t('diagnosisOptions.knee.aclRupture')
      },
      {
        name: 'aclReplacement',
        value: i18n.t('diagnosisOptions.knee.aclReplacement')
      },
      {
        name: 'meniscusInjury',
        value: i18n.t('diagnosisOptions.knee.meniscusInjury')
      },
      {
        name: 'shinRibbonRupture',
        value: i18n.t('diagnosisOptions.knee.shinRibbonRupture')
      },
      {
        name: 'osteoarthritis',
        value: i18n.t('diagnosisOptions.knee.osteoarthritis')
      }
    ],
    foot: [
      {
        name: 'ankleDescent',
        value: i18n.t('diagnosisOptions.foot.ankleDescent')
      },
      {
        name: 'ankleLigamentRupture',
        value: i18n.t('diagnosisOptions.foot.ankleLigamentRupture')
      },
      {
        name: 'ankleLigamentPartialRupture',
        value: i18n.t('diagnosisOptions.foot.ankleLigamentPartialRupture')
      }
    ],
    pelvis: []
  };

  onSelectOptionChange(event, oldValue) {
    const { onAnamnesisChange } = this.props;
    const { name, value: eventValue } = event.target;
    let newValue = eventValue;
    if (eventValue !== '') {
      if (oldValue) {
        newValue = `${oldValue}, ${eventValue}`;
      }
      onAnamnesisChange(name, newValue);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  generateSelectOptions(key) {
    return ExaminationDiagnosisBodyView.selectOptions[key].map(selectItem => (
      <MenuItem
        key={selectItem.name}
        name={selectItem.name}
        value={selectItem.value}
      >
        {selectItem.value}
      </MenuItem>
    ));
  }

  renderMedicalFields(side) {
    const { medicalFields } = ExaminationDiagnosisBodyView;
    const { anamnesis, onAnamnesisChange } = this.props;
    let fields;
    let prepos;
    let preposKey;

    switch (side) {
      case 'left':
        fields = medicalFields.left;
        prepos = i18n.t('diagnosisInfo.left');
        preposKey = 'left';
        break;
      case 'right':
        fields = medicalFields.right;
        prepos = i18n.t('diagnosisInfo.right');
        preposKey = 'right';
        break;
      case 'bottom':
        fields = medicalFields.bottom;
        prepos = '';
        preposKey = '';
        break;
      default:
        fields = [];
        prepos = '';
        preposKey = '';
    }

    return fields.map(field => {
      let { key } = field;
      const optionKey = key;

      if (preposKey) {
        key =
          preposKey.toLowerCase() + key.replace(/^./, str => str.toUpperCase());
      }

      const value = anamnesis[key];
      return (
        <React.Fragment key={key}>
          <p className="box-title">{`${prepos} ${field.name}`}</p>
          <div className="box">
            <textarea
              rows="2"
              type="text"
              value={value || ''}
              name={key}
              onChange={e => onAnamnesisChange(e.target.name, e.target.value)}
            />
            <FormControl fullWidth key={key}>
              <TextField
                select
                onChange={e => this.onSelectOptionChange(e, value)}
                InputLabelProps={{
                  shrink: true,
                  style: { fontSize: '13pt' }
                }}
                name={key}
              >
                {this.generateSelectOptions(optionKey)}
              </TextField>
            </FormControl>
          </div>
        </React.Fragment>
      );
    });
  }

  render() {
    return (
      <div className="diagnosis">
        { /* <div id="diagnosis">
          <div className="grid grid-three">
            <div className="left-side left-side-modal">
              {this.renderMedicalFields('left')}
            </div>
            <div className="right-side right-side-modal">
              {this.renderMedicalFields('right')}
            </div>
            <div className="bottom-side">
              {this.renderMedicalFields('bottom')}
            </div>
          </div>
    </div>*/ }
      </div>
    );
  }
}

export default ExaminationDiagnosisBodyView;
