import React, { Component } from 'react';
import PropTypes from 'prop-types'; //eslint-disable-line
import moment from 'moment';
import IntervalView from './interval-view';
import NotFound from '../../../not-found';

import i18n from '../../../../i18n';

const CHART_WIDTH = 900;

class Interval extends Component {
  static propTypes = {
    isFetchingIntervals: PropTypes.bool,
    isFetchingEvaluations: PropTypes.bool,
    isEvaluationRunning: PropTypes.bool,
    intervalId: PropTypes.string,
    interval: PropTypes.shape({
      data: PropTypes.string,
      end: PropTypes.number.isRequired,
      examinationId: PropTypes.number.isRequired,
      ftEnd: PropTypes.number.isRequired,
      ftStart: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
      movingAverage: PropTypes.number.isRequired,
      speed: PropTypes.number.isRequired,
      start: PropTypes.number.isRequired,
      name: PropTypes.string
    }),
    startEvaluation: PropTypes.func.isRequired,
    getInterval: PropTypes.func.isRequired,
    updateInterval: PropTypes.func.isRequired,
    errors: PropTypes.shape({
      message: PropTypes.string
    }),
    examinationId: PropTypes.string
  };

  static possibleMovingAverageValues = [
    8,
    10,
    12,
    14,
    15,
    16,
    20,
    24,
    30,
    36,
    40
  ];

  /**
   * Formats the X axis tickers of the chart
   *
   * @param {int} sec The time to format
   */
  static tickFormatter(sec) {
    return moment()
      .minutes(0)
      .second(sec / 100)
      .format('m:ss');
  }

  constructor(props) {
    super(props);

    this.getResultData = this.getResultData.bind(this);
    this.changeDraggablePosition = this.changeDraggablePosition.bind(this);
    this.getDraggableStartPosition = this.getDraggableStartPosition.bind(this);
    this.getDraggableEndPosition = this.getDraggableEndPosition.bind(this);
    this.getDraggableStartBound = this.getDraggableStartBound.bind(this);
    this.getDraggableEndBound = this.getDraggableEndBound.bind(this);
    this.convertTimeToX = this.convertTimeToX.bind(this);
    this.convertXToTime = this.convertXToTime.bind(this);
    this.changeMovingAverage = this.changeMovingAverage.bind(this);
    this.evaluateInterval = this.evaluateInterval.bind(this);
    this.setBestFit = this.setBestFit.bind(this);
  }

  componentDidMount() {
    const {
      intervalId,
      interval,
      getInterval,
      isFetchingIntervals
    } = this.props;

    // load interval if empty and not yet being loaded
    if (!interval && !isFetchingIntervals) {
      getInterval(intervalId);
    }

    // load data if navigated from other tab and refreshed before
    if (interval && !isFetchingIntervals && !interval.data) {
      getInterval(intervalId);
    }
  }

  /**
   * Extracts the interpolated result data of the interval
   */
  getResultData() {
    const { interval } = this.props;
    const data = interval && interval.data && JSON.parse(interval.data.replace(/\bNaN\b/g, "null"));

    return data && data.data;
  }

  getStepCycleDelimiter(movingAverage) {
    const data = this.getResultData();
    if (!data) return [];

    const delimiters = [];

    // here comes the algorithm that determines the step cycle
    for (let x = 0; x < data.length; x += 1) {
      if (x % movingAverage === 0) {
        delimiters.push(data[x]);
      }
    }

    return delimiters;
  }

  /**
   * Generates the X coordinate ticks of the chart.
   */
  getTimeTicks() {
    const data = this.getResultData();
    if (!data) return [];

    // split to 20 even ranges
    const ratio = Math.ceil(data.length / 20);
    const ticks = data
      .filter((value, index) => index % ratio === 0)
      .map(d => d.time);

    return ticks;
  }

  /**
   * Returns the min / max draggable boundaries of the interval start.
   */
  getDraggableStartBound() {
    const { interval } = this.props;

    if (!interval) {
      return {
        left: 0,
        right: CHART_WIDTH
      };
    }

    return {
      left: this.convertTimeToX(interval.start),
      right: this.convertTimeToX(interval.ftEnd || interval.end)
    };
  }

  /**
   * Returns the position of the start interval marker
   */
  getDraggableStartPosition() {
    const { interval } = this.props;

    if (!interval) {
      return {
        x: 0,
        y: 0
      };
    }

    return { x: this.convertTimeToX(interval.ftStart || interval.start), y: 0 };
  }

  /**
   * Returns the min / max draggable boundaries of the interval end.
   */
  getDraggableEndBound() {
    const { interval } = this.props;

    if (!interval) {
      return {
        left: 0,
        right: CHART_WIDTH
      };
    }

    return {
      left: this.convertTimeToX(interval.ftStart || interval.start),
      right: this.convertTimeToX(interval.end)
    };
  }

  /**
   * Returns the position of the end interval marker
   */
  getDraggableEndPosition() {
    const { interval } = this.props;

    if (!interval) {
      return {
        x: CHART_WIDTH,
        y: 0
      };
    }

    return { x: this.convertTimeToX(interval.ftEnd || interval.end), y: 0 };
  }

  /**
   * Returns the name of the interval if specified. Falls back to the speed.
   */
  getIntervalName() {
    const { interval } = this.props;

    if (!interval) return '';

    return interval.name || `${interval.speed} km/h`;
  }

  setBestFit() {
    const { interval, startEvaluation } = this.props;
    // evaluate the best fitting option
    startEvaluation({
      intervalId: interval.id,
      ftStart: interval.ftStart,
      ftEnd: interval.ftEnd,
      speed: interval.speed,
      movingAverage: 0,
      bestfit: true
    });
  }

  /**
   * Updates the intervals through the backend API.
   *
   * It updates both the one changed and the next one (if any).
   *
   * @param {object} e The change event
   * @param {object} data The changed data
   * @param {object} isStart Indicates if the fine-tuned start should be updated.
   * If false, the ft-end is modified.
   */
  changeDraggablePosition(e, data, isStart) {
    const { interval, updateInterval } = this.props;

    if (!interval) return;

    const newPosition = this.convertXToTime(data.x);

    const updatedInterval = { ...interval };
    if (isStart) {
      updatedInterval.ftStart = newPosition;
    } else {
      updatedInterval.ftEnd = newPosition;
    }

    // update the current interval
    updateInterval(updatedInterval, ['ftStart', 'ftEnd']);
  }

  /**
   * Updates the moving average and recalculates the data
   *
   * @param {object} e Event
   */
  changeMovingAverage(e) {
    const { interval, updateInterval } = this.props;

    if (!interval) return;

    // update the current interval
    updateInterval(
      {
        ...interval,
        movingAverage: e.target.value
      },
      ['movingAverage'] // the current interval is reloaded by the interval saga
    );
  }

  /**
   * Start the evaluation
   */
  evaluateInterval() {
    const { interval, startEvaluation } = this.props;

    if (!interval) return;

    startEvaluation({
      intervalId: interval.id,
      ftStart: interval.ftStart,
      ftEnd: interval.ftEnd,
      speed: interval.speed,
      movingAverage: interval.movingAverage,
      bestfit: false
    });
  }

  /**
   * Converts an interval start/end edge (time) to a x-axis (pixels) marker position.
   *
   * @param {int} position The time position of the marker
   */
  convertTimeToX(timePosition) {
    const data = this.getResultData();
    if (!data) return 0;

    // extract the timescale
    const lastItem = data[data.length - 1];
    const firstItem = data[0];
    const timeRange = lastItem.time - firstItem.time;

    // avoid division by 0
    if (!timeRange) {
      return 0;
    }

    // CHART_WIDTH is the fixed width of the chart => the position
    // is relative to the full timescale
    const result = (CHART_WIDTH / timeRange) * (timePosition - firstItem.time);

    // marker position might be rounded by the browser while boundaries are not rounded
    // => current position of the marker might be out of the boundaries
    // => convert result to 2 decimal places (both for boundaries and positions)
    return Math.round(result * 100) / 100;
  }

  /**
   * Converts X coordinate (pixels) to time values for intervals.
   *
   * @param {int} position The position of the marker as X pixels coordinate
   */
  convertXToTime(xPosition) {
    const data = this.getResultData();
    if (!data) return 0;

    // extract the timescale
    const lastItem = data[data.length - 1];
    const firstItem = data[0];
    const timeRange = lastItem.time - firstItem.time;

    // CHART_WIDTH is the fixed width of the chart => the position
    // is relative to the full timescale
    return (timeRange / CHART_WIDTH) * xPosition + firstItem.time;
  }

  render() {
    const {
      interval,
      isFetchingIntervals,
      isFetchingEvaluations,
      isEvaluationRunning,
      errors,
      examinationId
    } = this.props;

    if (errors) {
      const errorMessage = errors && errors.message;
      return (
        <NotFound
          isWrapped
          icon
          message={i18n.t(`backendResponse.${errorMessage}`)}
          backTo={`/examinations/${examinationId}/overview`}
        />
      );
    }

    let defaulMovingAverage = 20;
    if (interval && interval.speed && interval.speed >= 8) {
      defaulMovingAverage = 15;
    }

    return (
      <IntervalView
        setBestFit={this.setBestFit}
        isFetchingIntervals={isFetchingIntervals}
        isEvaluationRunning={isEvaluationRunning}
        isFetchingEvaluations={isFetchingEvaluations}
        tickFormatter={Interval.tickFormatter}
        data={interval && interval.data && JSON.parse(interval.data.replace(/\bNaN\b/g, "null"))}
        cycles={interval && interval.cycles && JSON.parse(interval.cycles.replace(/\bNaN\b/g, "null"))}
        ticks={this.getTimeTicks()}
        changeDraggablePosition={this.changeDraggablePosition}
        intervalName={this.getIntervalName()}
        changeMovingAverage={this.changeMovingAverage}
        movingAverageValue={
          (interval && interval.movingAverage) || defaulMovingAverage
        }
        possibleMovingAverageValues={Interval.possibleMovingAverageValues}
        draggableStartBound={this.getDraggableStartBound()}
        draggableStartPosition={this.getDraggableStartPosition()}
        draggableEndBound={this.getDraggableEndBound()}
        draggableEndPosition={this.getDraggableEndPosition()}
        startEvaluation={this.evaluateInterval}
        intervalId={interval && interval.id}
        speed={interval && interval.speed}
      />
    );
  }
}

export default Interval;
