import React, { Component } from 'react';
import './styles/app.css';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import createBrowserHistory from 'history/createBrowserHistory'; // eslint-disable-line import/no-extraneous-dependencies
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configureStore from './store';
import Main from './views/main';
import Report from './views/report';
import Utils from './services/utils';

import './styles/statement.css';

const { store, persistor } = configureStore();
const history = createBrowserHistory();

// Render view based on login status
class App extends Component {
  static getReportRoute() {
    return (
      <div>
        <Router basename="/">
          <Switch>
            <Route exact path="/print/report/:id" component={Report} />
          </Switch>
        </Router>
      </div>
    );
  }

  render() {
    // handle printing report path separately
    const isPrinting = Utils.isPDFPrinting();

    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {isPrinting ? App.getReportRoute() : <Main />}
          <ToastContainer />
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
export { history };
