import { REQUEST } from '../request';

const CLEAN_EVALUATION = 'CLEAN_EVALUATION';
const ADD_EVALUATION_SUCCESS = 'ADD_EVALUATION_SUCCESS';
const GET_EVALUATION_SUCCESS = 'GET_EVALUATION_SUCCESS';
const LIST_EVALUATIONS_SUCCESS = 'LIST_EVALUATIONS_SUCCESS';
const UPDATE_EVALUATION_SUCCESS = 'UPDATE_EVALUATION_SUCCESS';
const START_EVALUATION_SUCCESS = 'START_EVALUATION_SUCCESS';
const ADD_EVALUATION_FAILED = 'ADD_EVALUATION_FAILED';
const GET_EVALUATION_FAILED = 'GET_EVALUATION_FAILED';
const LIST_EVALUATIONS_FAILED = 'LIST_EVALUATIONS_FAILED';
const UPDATE_EVALUATION_FAILED = 'UPDATE_EVALUATION_FAILED';
const START_EVALUATION_FAILED = 'START_EVALUATION_FAILED';

export const Types = {
  CLEAN_EVALUATION,
  ADD_EVALUATION_SUCCESS,
  GET_EVALUATION_SUCCESS,
  LIST_EVALUATIONS_SUCCESS,
  UPDATE_EVALUATION_SUCCESS,
  START_EVALUATION_SUCCESS,
  ADD_EVALUATION_FAILED,
  GET_EVALUATION_FAILED,
  LIST_EVALUATIONS_FAILED,
  UPDATE_EVALUATION_FAILED,
  START_EVALUATION_FAILED,
  REQUEST
};

export const initialState = {
  isFetching: false,
  isEvaluationRunning: false,
  evaluations: [],
  errors: null
};

const evaluations = (state = initialState, action) => {
  if (!action) return state;

  let id;
  let found;
  let evaluation;
  let evaluationsUpdated;

  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        isFetching:
          (action.payload &&
            /EVALUATION/.test(action.payload.action) &&
            action.payload.action !== 'START_EVALUATION') ||
          state.isFetching,
        isEvaluationRunning:
          (action.payload && action.payload.action === 'START_EVALUATION') ||
          state.isEvaluationRunning,
        errors:
          action.payload && /EVALUATION/.test(action.payload.action)
            ? null
            : state.errors
      };

    case LIST_EVALUATIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        evaluations:
          (action.payload &&
            action.payload.data &&
            action.payload.data.evaluations) ||
          [],
        errors: null
      };

    case START_EVALUATION_SUCCESS:
    case ADD_EVALUATION_SUCCESS:
    case UPDATE_EVALUATION_SUCCESS:
    case GET_EVALUATION_SUCCESS:
      id =
        action &&
        action.payload &&
        action.payload.data &&
        action.payload.data.id;
      if (!id) return state;

      ({ evaluation } = action.payload.data);

      if (!evaluation) return state;

      found = false;
      evaluationsUpdated = {
        ...state,
        // If the type is START_EVALUATION_SUCCESS:
        // -> set isFetching to state.isFetching
        // -> set isEvaluationRunning to false
        //
        // If the type is anything elste than START_EVALUATION_SUCCESS:
        // -> set isFetching to false
        // -> set isEvaluationRunning to state.isEvalutaionRunning
        isFetching:
          action.type === START_EVALUATION_SUCCESS ? state.isFetching : false,
        isEvaluationRunning:
          action.type !== START_EVALUATION_SUCCESS && state.isEvaluationRunning,
        evaluations: (state.evaluations || []).map(itm => {
          if (!itm || (itm.id && itm.id.toString()) !== id.toString()) {
            // this isn't the item we care about - keep it as-is
            return itm;
          }

          // otherwise, this is the item we are looking for => return the retrieved value
          found = true;

          return evaluation;
        }),
        errors: null
      };

      if (!found) {
        evaluationsUpdated.evaluations.push(evaluation);
      }

      return evaluationsUpdated;

    case START_EVALUATION_FAILED:
      return {
        ...state,
        isEvaluationRunning: false,
        errors: action.payload
      };

    case ADD_EVALUATION_FAILED:
    case UPDATE_EVALUATION_FAILED:
    case GET_EVALUATION_FAILED:
    case LIST_EVALUATIONS_FAILED:
      return {
        ...state,
        isFetching: false,
        errors: action.payload
      };

    case 'FLUSH':
    case CLEAN_EVALUATION:
      return initialState;
    default:
      return state;
  }
};

export const cleanEvaluations = () => ({
  type: CLEAN_EVALUATION
});

export const Actions = { cleanEvaluations };

export default evaluations;
