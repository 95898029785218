import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReportPDFView from './report-pdf-view';

import i18n from '../../i18n';

class ReportPDF extends Component {
  static propTypes = {
    evaluation: PropTypes.shape({}),
    interval: PropTypes.shape({}),
    examination: PropTypes.shape({}),
    patient: PropTypes.shape({}),
    benchmark: PropTypes.shape({})
  };

  static propTypes = {
    evaluation: PropTypes.shape({}),
    interval: PropTypes.shape({}),
    examination: PropTypes.shape({}),
    patient: PropTypes.shape({}),
    benchmark: PropTypes.shape({}),
    errors: PropTypes.shape({
      message: PropTypes.string
    })
  };

  static possibleBenchmarkTypes = [
    {
      name: i18n.t('benchmarkInfo.allExaminations'),
      value: 0
    },
    {
      name: i18n.t('benchmarkInfo.allMaleExaminations'),
      value: 10
    },
    {
      name: i18n.t('benchmarkInfo.allFemaleExaminations'),
      value: 20
    },
    {
      name: i18n.t('benchmarkInfo.matchingGenderAgeCohort'),
      value: 100
    },
    {
      name: i18n.t('benchmarkInfo.matchingGenderAgeBMICohort'),
      value: 1000
    }
  ];

  /**
   *
   * Returns the domain of the benchmark chart
   *
   */
  static getBenchmarkDomain(benchmarks) {
    let max = 0;
    let min = 0;
    benchmarks.forEach(benchmark => {
      benchmark.data.forEach(piece => {
        if (piece.y >= max) {
          max = piece.y;
        }
        if (piece.y <= min) {
          min = piece.y;
        }
      });
    });
    benchmarks.forEach(benchmark => {
      benchmark.borders.forEach(piece => {
        if (piece.y >= max) {
          max = piece.y;
        }
        if (piece.y0 <= min) {
          min = piece.y0;
        }
      });
    });

    max = Math.ceil(max) + 4;
    min = Math.floor(min) - 4;

    const ticks = [];
    for (let x = min; x <= max; x += 1) {
      if (x % 2 === 0) {
        ticks.push(x);
      }
    }

    return { max, min, ticks };
  }

  static getHistogramDomain(histograms) {
    let max = 0;
    const min = 0;

    histograms.forEach(histogram => {
      histogram.data.forEach(data => {
        if (data >= max) max = data;
      });
    });

    return { max, min };
  }

  static getDimensionsDomain(dimensions) {
    let max = 0;
    let min = 0;
    dimensions.forEach(dimension => {
      dimension.datasets.forEach(dataset => {
        dataset.forEach(piece => {
          if (piece.y >= max) {
            max = piece.y;
          }
          if (piece.y <= min) {
            min = piece.y;
          }
        });
      });
    });

    max = Math.ceil(max);
    min = Math.floor(min);

    const ticks = [];
    for (let x = min; x <= max; x += 1) {
      if (x % 2 === 0) {
        ticks.push(x);
      }
    }

    return { max, min, ticks };
  }

  static getAveragesDomain(dimensions) {
    let max = 0;
    let min = 0;
    dimensions.forEach(dimension => {
      dimension.datasets.forEach(dataset => {
        dataset.forEach(piece => {
          if (piece.y >= max) {
            max = piece.y;
          }
          if (piece.y <= min) {
            min = piece.y;
          }
        });
      });
    });
    max = Math.ceil(max) + 2;
    min = Math.floor(min) - 2;

    const ticks = [];
    for (let x = min; x <= max; x += 1) {
      if (x % 2 === 0) {
        ticks.push(x);
      }
    }

    return { max, min, ticks };
  }

  /*
   * Returns the time of the evaluation
   */
  static getEvaluationTime(evaluation) {
    if (!evaluation || !evaluation.ftStart || !evaluation.ftEnd) return '';

    return `${moment()
      .minutes(0)
      .seconds(evaluation.ftStart / 100)
      .format('m:ss')}
      - 
      ${moment()
        .minutes(0)
        .seconds(evaluation.ftEnd / 100)
        .format('m:ss')}`;
  }

  /**
   * Creates data from patient, examination, interval and evaluation props
   * that the PDF preview can handle
   */
  static dataToPrint(patient, examination, interval, evaluation, benchmark, panasz, korelozmeny, ellenjavallat,
                      staty, dinamy, walk2, walk4, walk8, walk10, walke, special, opinion) {
    return {
      patient: {
        firstName: patient.firstName,
        lastName: patient.lastName,
        weight: examination.weight,
        height: examination.height,
        BMI: (
          (examination.weight / examination.height / examination.height) *
          10000
        ).toFixed(2)
      },
      panasz: panasz,
      korelozmeny: korelozmeny === "" ? evaluation.leftHip : korelozmeny,
      ellenjavallat: ellenjavallat === "" ? evaluation.rightFoot : ellenjavallat,
      staty: staty === "" ? evaluation.leftKnee : staty,
      dinamy: dinamy === "" ? evaluation.rightKnee : dinamy,
      walk2: walk2,
      walk4: walk4 === "" ? evaluation.rightHip : walk4,
      walk8: walk8,
      walk10: walk10,
      walke: walke,
      special: special === "" ? evaluation.leftFoot : special,
      opinion: opinion === "" ? evaluation.pelvis : opinion,
      exam: {
        type: interval && `${interval.speed} km/h`,
        id: examination && examination.id,
        status: examination && examination.comment,
        date: examination && moment(examination.date).format('YYYY-MM-DD HH:mm')
      },
      evaluation: {
        date: evaluation && moment(evaluation.createdAt).format('YYYY-MM-DD'),
        time: ReportPDF.getEvaluationTime(evaluation),
        movingAverage: evaluation && evaluation.movingAverage,
        benchmarkName: ReportPDF.returnBenchmarkName(evaluation.benchmarkId),
        benchmarkSample: benchmark && benchmark.sample
      },
      diagnosis: false 
      /*[
        {
          name: `${i18n.t('diagnosisInfo.left')} ${i18n.t(
            'diagnosisInfo.hip'
          )}`,
          value: evaluation.leftHip
        },
        {
          name: `${i18n.t('diagnosisInfo.left')} ${i18n.t(
            'diagnosisInfo.knee'
          )}`,
          value: evaluation.leftKnee
        },
        {
          name: `${i18n.t('diagnosisInfo.left')} ${i18n.t(
            'diagnosisInfo.foot'
          )}`,
          value: evaluation.leftFoot
        },
        {
          name: `${i18n.t('diagnosisInfo.right')} ${i18n.t(
            'diagnosisInfo.hip'
          )}`,
          value: evaluation.rightHip
        },
        {
          name: `${i18n.t('diagnosisInfo.right')} ${i18n.t(
            'diagnosisInfo.knee'
          )}`,
          value: evaluation.rightKnee
        },
        {
          name: `${i18n.t('diagnosisInfo.right')} ${i18n.t(
            'diagnosisInfo.foot'
          )}`,
          value: evaluation.rightFoot
        },
        {
          name: `${i18n.t('diagnosisInfo.pelvis')}`,
          value: evaluation.pelvis
        }
      ]*/
    };
  }

  /*
   * Decodes the benchmarkId to match dictionary and sets the state according to the result
   */
  static returnBenchmarkName(benchmarkId) {
    let stateBench = 0;
    if (benchmarkId < 100) {
      stateBench = benchmarkId;
    }
    if (benchmarkId >= 100 && benchmarkId < 1000) {
      stateBench = 100;
    }
    if (benchmarkId >= 1000 && benchmarkId) {
      stateBench = 1000;
    }

    const newBench = ReportPDF.possibleBenchmarkTypes.find(
      f => f.value.toString() === stateBench.toString()
    );

    return newBench.name || '';
  }

  /**
   * Extracts the parameters from the state
   */
  getParameters() {
    const { evaluation } = this.props;

    if (!evaluation || !evaluation.results) return null;
    const results = JSON.parse(evaluation.results.replace(/\bNaN\b/g, "null"));

    const parameters = results && results.parameters;
    return parameters;
  }

  /**
   * Maps the API data to the chart format.
   */
  histogramToChart() {
    const { evaluation } = this.props;
    const chartHistograms = [];

    if (!evaluation || !evaluation.results)
      return { chartHistograms: [], domain: {} };

    const results = JSON.parse(evaluation.results.replace(/\bNaN\b/g, "null"));

    const histograms = [];
    histograms.push(results.leftTimeHistogram);
    histograms.push(results.rightTimeHistogram);

    histograms.forEach(histogram => {
      const time = [];
      histogram.data.forEach((d, index) => {
        time.push(index);
      });
      chartHistograms.push({
        name: i18n.t(`chartInfo.${histogram.side}`),
        data: histogram.data,
        time,
        hi: histogram.band_high,
        low: histogram.band_low
      });
    });

    const domain = ReportPDF.getHistogramDomain(chartHistograms);

    return { chartHistograms, domain };
  }

  /**
   * Maps the API data to the chart format.
   */
  dimensionsToChart() {
    const { evaluation } = this.props;

    if (!evaluation || !evaluation.results)
      return { chartDimensions: [], domain: {} };

    const results = JSON.parse(evaluation.results.replace(/\bNaN\b/g, "null"));

    const dimensions = results && results.dimensions;

    const chartDimensions = [];

    const leg = i18n.t('chartInfo.leg');
    const left = i18n.t('chartInfo.left');
    const right = i18n.t('chartInfo.right');

    const leftV = { name: `${left} ${leg} V`, datasets: [] };
    const leftS = { name: `${left} ${leg} S`, datasets: [] };
    const leftH = { name: `${left} ${leg} H`, datasets: [] };
    const rightV = { name: `${right} ${leg} V`, datasets: [] };
    const rightS = { name: `${right} ${leg} S`, datasets: [] };
    const rightH = { name: `${right} ${leg} H`, datasets: [] };

    chartDimensions.push(leftV, rightV, leftS, rightS, leftH, rightH);

    dimensions.forEach(step => {
      const newDatasetV = [];
      const newDatasetS = [];
      const newDatasetH = [];

      step.data.forEach(d => {
        newDatasetV.push({ x: d.cycle_time_perc * 100, y: d.v });
        newDatasetS.push({ x: d.cycle_time_perc * 100, y: d.s });
        newDatasetH.push({ x: d.cycle_time_perc * 100, y: d.h });
      });

      switch (step.phase) {
        case 'left':
          leftV.datasets.push(newDatasetV);
          leftS.datasets.push(newDatasetS);
          leftH.datasets.push(newDatasetH);
          break;
        case 'right':
          rightV.datasets.push(newDatasetV);
          rightS.datasets.push(newDatasetS);
          rightH.datasets.push(newDatasetH);
          break;
        default:
          break;
      }
    });

    const domain = ReportPDF.getDimensionsDomain(chartDimensions);

    return { chartDimensions, domain };
  }

  /**
   * Maps the API data to the chart format.
   */
  avgsToChart() {
    const { evaluation } = this.props;

    if (!evaluation || !evaluation.avgCurves)
      return { chartDimensions: [], domain: {} };

    const avgs = JSON.parse(evaluation.avgCurves.replace(/\bNaN\b/g, "null"));

    if (!avgs) return null;

    const chartAvgs = [];

    const V = { name: 'V', datasets: [] };
    const S = { name: 'S', datasets: [] };
    const H = { name: 'H', datasets: [] };

    chartAvgs.push(V, S, H);

    const newDatasetVleft = [];
    const newDatasetVright = [];
    const newDatasetSleft = [];
    const newDatasetSright = [];
    const newDatasetHleft = [];
    const newDatasetHright = [];

    avgs.forEach(d => {
      newDatasetVleft.push({ x: d.ss, y: d.lavg_v });
      newDatasetVright.push({ x: d.ss, y: d.ravg_v });
      newDatasetSleft.push({ x: d.ss, y: d.lavg_s });
      newDatasetSright.push({ x: d.ss, y: d.ravg_s });
      newDatasetHleft.push({ x: d.ss, y: d.lavg_h });
      newDatasetHright.push({ x: d.ss, y: d.ravg_h });
    });

    V.datasets.push(newDatasetVleft);
    V.datasets.push(newDatasetVright);
    S.datasets.push(newDatasetSleft);
    S.datasets.push(newDatasetSright);
    H.datasets.push(newDatasetHleft);
    H.datasets.push(newDatasetHright);

    const domain = ReportPDF.getAveragesDomain(chartAvgs);

    return { chartAvgs, domain };
  }

  /**
   * Transforms API benchmark data to chart data
   */
  benchmarkToChart() {
    const { evaluation, benchmark } = this.props;

    if (!evaluation || !evaluation.avgCurves)
      return { chartAvgs: [], domain: null };

    const avgs = JSON.parse(evaluation.avgCurves.replace(/\bNaN\b/g, "null"));

    if (!avgs) return { chartAvgs: [], domain: null };

    const chartAvgs = [];

    const leftV = {
      name: `${i18n.t('chartInfo.left').toLowerCase()} V`,
      data: [],
      borders: []
    };
    const rightV = {
      name: `${i18n.t('chartInfo.right').toLowerCase()} V`,
      data: [],
      borders: []
    };
    const leftS = {
      name: `${i18n.t('chartInfo.left').toLowerCase()} S`,
      data: [],
      borders: []
    };
    const rightS = {
      name: `${i18n.t('chartInfo.right').toLowerCase()} S`,
      data: [],
      borders: []
    };
    const leftH = {
      name: `${i18n.t('chartInfo.left').toLowerCase()} H`,
      data: [],
      borders: []
    };
    const rightH = {
      name: `${i18n.t('chartInfo.right').toLowerCase()} H`,
      data: [],
      borders: []
    };

    avgs.forEach(d => {
      leftV.data.push({ x: d.ss, y: d.lavg_v });
      rightV.data.push({ x: d.ss, y: d.ravg_v });
      leftS.data.push({ x: d.ss, y: d.lavg_s });
      rightS.data.push({ x: d.ss, y: d.ravg_s });
      leftH.data.push({ x: d.ss, y: d.lavg_h });
      rightH.data.push({ x: d.ss, y: d.ravg_h });
    });

    // Borders coming from benchmark API
    if (benchmark && benchmark.maxCurve && benchmark.minCurve) {
      const max = benchmark.maxCurve;
      const min = benchmark.minCurve;

      for (let i = 0; i < benchmark.maxCurve.length; i += 1) {
        leftV.borders.push({
          x: max[i].ss,
          y0: min[i].lavgV,
          y: max[i].lavgV
        });
        leftS.borders.push({
          x: max[i].ss,
          y0: min[i].lavgS,
          y: max[i].lavgS
        });
        leftH.borders.push({
          x: max[i].ss,
          y0: min[i].lavgH,
          y: max[i].lavgH
        });
        rightV.borders.push({
          x: max[i].ss,
          y0: min[i].ravgV,
          y: max[i].ravgV
        });
        rightS.borders.push({
          x: max[i].ss,
          y0: min[i].ravgS,
          y: max[i].ravgS
        });
        rightH.borders.push({
          x: max[i].ss,
          y0: min[i].ravgH,
          y: max[i].ravgH
        });
      }
    }

    chartAvgs.push(leftV, rightV, leftS, rightS, leftH, rightH);

    const domain = ReportPDF.getBenchmarkDomain(chartAvgs);

    return { chartAvgs, domain };
  }

  render() {
    const { staty, dinamy, walk2, walk4, walk8, walk10, walke, special, opinion, panasz, ellenjavallat, korelozmeny, evaluation, interval, examination, patient } = this.props;

    const averages = this.avgsToChart();
    const histogram = this.histogramToChart();
    const dimensions = this.dimensionsToChart();
    const benchmark = this.benchmarkToChart();
    const parameters = this.getParameters();

    // Creating preview ready object
    const dataToPrint = ReportPDF.dataToPrint(
      patient,
      examination,
      interval,
      evaluation,
      benchmark,
      panasz,
      korelozmeny,
      ellenjavallat,
      staty,
      dinamy,
      walk2,
      walk4,
      walk8,
      walk10,
      walke,
      special,
      opinion
    );

    // Wrapping all chart data to this object so the view can easily pass it to the preview renderer
    const allChartData = {
      dimensions: (dimensions && dimensions.chartDimensions) || [],
      dimensionsDomain: (dimensions && dimensions.domain) || {},
      averages: (averages && averages.chartAvgs) || [],
      averagesDomain: (averages && averages.domain) || {},
      histogram: (histogram && histogram.chartHistograms) || [],
      histogramDomain: (histogram && histogram.domain) || {},
      benchmark: (benchmark && benchmark.chartAvgs) || [],
      benchmarkDomain: (benchmark && benchmark.domain) || {}
    };

    return (
      <ReportPDFView
        dataToPrint={dataToPrint}
        allChartData={allChartData}
        parameters={parameters}
      />
    );
  }
}

export default ReportPDF;
