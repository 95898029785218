import { connect } from 'react-redux';
import Main from './main';

const mapStateToProps = ({
  auth: { user, isFetching },
  app: { isMenuOpen }
}) => ({
  user,
  isMenuOpen,
  isFetching
});

export default connect(mapStateToProps)(Main);
