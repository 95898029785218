import React, { Component } from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line
import moment from 'moment';
import EvaluationView from './evaluation-view';
import Loader from '../../../loader';
import NotFound from '../../../not-found';

import i18n from '../../../../i18n';

class Evaluation extends Component {
  static propTypes = {
    interval: PropTypes.shape({
      data: PropTypes.string,
      end: PropTypes.number.isRequired,
      examinationId: PropTypes.number.isRequired,
      ftEnd: PropTypes.number.isRequired,
      ftStart: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
      movingAverage: PropTypes.number.isRequired,
      speed: PropTypes.number.isRequired,
      start: PropTypes.number.isRequired,
      name: PropTypes.string
    }),
    evaluation: PropTypes.shape({
      results: PropTypes.string,
      id: PropTypes.number.isRequired
    }),
    isIntervalFetching: PropTypes.bool,
    isEvaluationFetching: PropTypes.bool,
    intervalId: PropTypes.string,
    evaluationId: PropTypes.string,
    getInterval: PropTypes.func,
    getEvaluation: PropTypes.func,
    evaluationErrors: PropTypes.shape({
      message: PropTypes.string
    }),
    intervalErrors: PropTypes.shape({
      message: PropTypes.string
    }),
    examinationId: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.getIntervalName = this.getIntervalName.bind(this);

    this.state = {};
  }

  static getHistogramDomain(histograms) {
    let max = 0;
    const min = 0;

    histograms.forEach(histogram => {
      histogram.data.forEach(data => {
        if (data >= max) max = data;
      });
    });

    return { max, min };
  }

  static getDimensionsDomain(dimensions) {
    let max = 0;
    let min = 0;
    dimensions.forEach(dimension => {
      dimension.datasets.forEach(dataset => {
        dataset.forEach(piece => {
          if (piece.y >= max) {
            max = piece.y;
          }
          if (piece.y <= min) {
            min = piece.y;
          }
        });
      });
    });

    max = Math.ceil(max);
    min = Math.floor(min);

    const ticks = [];
    for (let x = min; x <= max; x += 1) {
      if (x % 2 === 0) {
        ticks.push(x);
      }
    }

    return { max, min, ticks };
  }

  static getAveragesDomain(dimensions) {
    let max = 0;
    let min = 0;
    dimensions.forEach(dimension => {
      dimension.datasets.forEach(dataset => {
        dataset.forEach(piece => {
          if (piece.y >= max) {
            max = piece.y;
          }
          if (piece.y <= min) {
            min = piece.y;
          }
        });
      });
    });
    max = Math.ceil(max) + 2;
    min = Math.floor(min) - 2;

    const ticks = [];
    for (let x = min; x <= max; x += 1) {
      if (x % 2 === 0) {
        ticks.push(x);
      }
    }

    return { max, min, ticks };
  }

  /**
   * Returns the name of the interval if specified. Falls back to the speed.
   */
  getIntervalName() {
    const { interval } = this.props;

    if (!interval) return '';

    return interval.name || `${interval.speed} km/h`;
  }

  /*
   * Returns the time of the evaluation
   */
  getEvaluationTime() {
    const { evaluation } = this.props;
    if (!evaluation || !evaluation.ftStart || !evaluation.ftEnd) return '';

    return `${moment()
      .minutes(0)
      .seconds(evaluation.ftStart / 100)
      .format('m:ss')}
      - 
      ${moment()
        .minutes(0)
        .seconds(evaluation.ftEnd / 100)
        .format('m:ss')}`;
  }

  /**
   * Maps the API data to the chart format.
   */
  histogramToChart() {
    const { evaluation } = this.props;
    const chartHistograms = [];

    if (!evaluation || !evaluation.results) return null;

    const results = JSON.parse(evaluation.results.replace(/\bNaN\b/g, "null"));

    const histograms = [];
    histograms.push(results.leftTimeHistogram);
    histograms.push(results.rightTimeHistogram);

    histograms.forEach(histogram => {
      const time = [];
      histogram.data.forEach((d, index) => {
        time.push(index);
      });
      chartHistograms.push({
        name: i18n.t(`chartInfo.${histogram.side}`),
        data: histogram.data,
        time,
        hi: histogram.band_high,
        low: histogram.band_low
      });
    });

    const domain = Evaluation.getHistogramDomain(chartHistograms);

    return { chartHistograms, domain };
  }

  /**
   * Maps the API data to the chart format.
   */
  dimensionsToChart() {
    const { evaluation } = this.props;

    if (!evaluation || !evaluation.results) return null;

    const results = JSON.parse(evaluation.results.replace(/\bNaN\b/g, "null"));

    const dimensions = results && results.dimensions;

    const chartDimensions = [];

    const leg = i18n.t('chartInfo.leg');
    const left = i18n.t('chartInfo.left');
    const right = i18n.t('chartInfo.right');

    const leftV = { name: `${left} ${leg} V`, datasets: [] };
    const leftS = { name: `${left} ${leg} S`, datasets: [] };
    const leftH = { name: `${left} ${leg} H`, datasets: [] };
    const rightV = { name: `${right} ${leg} V`, datasets: [] };
    const rightS = { name: `${right} ${leg} S`, datasets: [] };
    const rightH = { name: `${right} ${leg} H`, datasets: [] };

    chartDimensions.push(leftV, leftS, leftH, rightV, rightS, rightH);

    dimensions.forEach(step => {
      const newDatasetV = [];
      const newDatasetS = [];
      const newDatasetH = [];

      step.data.forEach(d => {
        newDatasetV.push({ x: d.cycle_time_perc * 100, y: d.v });
        newDatasetS.push({ x: d.cycle_time_perc * 100, y: d.s });
        newDatasetH.push({ x: d.cycle_time_perc * 100, y: d.h });
      });

      switch (step.phase) {
        case 'left':
          leftV.datasets.push(newDatasetV);
          leftS.datasets.push(newDatasetS);
          leftH.datasets.push(newDatasetH);
          break;
        case 'right':
          rightV.datasets.push(newDatasetV);
          rightS.datasets.push(newDatasetS);
          rightH.datasets.push(newDatasetH);
          break;
        default:
          break;
      }
    });

    const domain = Evaluation.getDimensionsDomain(chartDimensions);

    return { chartDimensions, domain };
  }

  /**
   * Maps the API data to the chart format.
   */
  avgsToChart() {
    const { evaluation } = this.props;

    if (!evaluation || !evaluation.avgCurves) return null;

    const avgs = JSON.parse(evaluation.avgCurves.replace(/\bNaN\b/g, "null"));

    if (!avgs) return null;

    const chartAvgs = [];

    const V = { name: 'V', datasets: [] };
    const S = { name: 'S', datasets: [] };
    const H = { name: 'H', datasets: [] };

    chartAvgs.push(V, S, H);

    const newDatasetVleft = [];
    const newDatasetVright = [];
    const newDatasetSleft = [];
    const newDatasetSright = [];
    const newDatasetHleft = [];
    const newDatasetHright = [];

    avgs.forEach(d => {
      newDatasetVleft.push({ x: d.ss, y: d.lavg_v });
      newDatasetVright.push({ x: d.ss, y: d.ravg_v });
      newDatasetSleft.push({ x: d.ss, y: d.lavg_s });
      newDatasetSright.push({ x: d.ss, y: d.ravg_s });
      newDatasetHleft.push({ x: d.ss, y: d.lavg_h });
      newDatasetHright.push({ x: d.ss, y: d.ravg_h });
    });

    V.datasets.push(newDatasetVleft);
    V.datasets.push(newDatasetVright);
    S.datasets.push(newDatasetSleft);
    S.datasets.push(newDatasetSright);
    H.datasets.push(newDatasetHleft);
    H.datasets.push(newDatasetHright);

    const domain = Evaluation.getAveragesDomain(chartAvgs);

    return { chartAvgs, domain };
  }

  render() {
    const chartHistograms = this.histogramToChart();
    const chartDimensions = this.dimensionsToChart();
    const chartAvgs = this.avgsToChart();

    const {
      interval,
      evaluation,
      isIntervalFetching,
      isEvaluationFetching,
      intervalId,
      evaluationId,
      getInterval,
      getEvaluation,
      evaluationErrors,
      intervalErrors,
      examinationId
    } = this.props;

    if (evaluationErrors || intervalErrors) {
      const errorMessage =
        (evaluationErrors && evaluationErrors.message) ||
        (intervalErrors && intervalErrors.message);
      return (
        <NotFound
          isWrapped
          message={i18n.t(`backendResponse.${errorMessage}`)}
          backTo={`/examinations/${examinationId}/overview`}
        />
      );
    }

    if (!evaluation) {
      if (!isEvaluationFetching) {
        getEvaluation(evaluationId);
      }
    }

    if (!interval) {
      if (!isIntervalFetching) {
        getInterval(intervalId);
      }
    }

    if (!evaluation || !interval) {
      return (
        <Loader isWrapped message={i18n.t('loadingInfo.loadingEvaluation')} />
      );
    }

    return (
      <EvaluationView
        evaluationTime={this.getEvaluationTime()}
        movingAverage={evaluation && evaluation.movingAverage}
        intervalName={this.getIntervalName()}
        histograms={chartHistograms && chartHistograms.chartHistograms}
        histogramsDomain={chartHistograms && chartHistograms.domain}
        dimensions={chartDimensions && chartDimensions.chartDimensions}
        dimensionsDomain={chartDimensions && chartDimensions.domain}
        averages={chartAvgs && chartAvgs.chartAvgs}
        averagesDomain={chartAvgs && chartAvgs.domain}
      />
    );
  }
}

export default Evaluation;
