import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ConfirmView from './confirm-view';

class Confirm extends Component {
  static propTypes = {
    isConfirmOpen: PropTypes.bool,
    confirmFunction: PropTypes.func,
    closeConfirm: PropTypes.func,
    confirmMessage: PropTypes.string
  };

  render() {
    const {
      closeConfirm,
      confirmFunction,
      confirmMessage,
      isConfirmOpen
    } = this.props;

    return (
      <ConfirmView
        isConfirmOpen={isConfirmOpen}
        confirmFunction={confirmFunction}
        closeConfirm={closeConfirm}
        confirmMessage={confirmMessage}
      />
    );
  }
}

export default Confirm;
