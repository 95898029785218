import env from '../../config';
import ApiHandlerService from '../../services/api-handler';

export default class AuthApi {
  static signIn = credentials => {
    const config = {
      body: JSON.stringify(credentials),
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store'
      },
      method: 'POST'
    };
    return ApiHandlerService.fetch(`${env.API_BASE_URL}/auth/login`, config);
  };

  static resendVerificationEmail = credentials => {
    const config = {
      body: JSON.stringify(credentials),
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store'
      },
      method: 'POST'
    };

    return ApiHandlerService.fetch(
      `${env.API_BASE_URL}/auth/verify/email/resend`,
      config
    );
  };

  static authResetPassword = credentials => {
    const config = {
      body: JSON.stringify(credentials),
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store'
      },
      method: 'POST'
    };

    return ApiHandlerService.fetch(
      `${env.API_BASE_URL}/auth/forgot-password/change`,
      config
    );
  };

  static resetPassword = credentials => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store'
      },
      method: 'GET'
    };
    const { email } = credentials;
    return ApiHandlerService.fetch(
      `${env.API_BASE_URL}/auth/forgot-password/token?email=${email}`,
      config
    );
  };

  static signOut = () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store'
      },
      method: 'DELETE'
    };

    return ApiHandlerService.fetch(
      `${env.API_BASE_URL}/api/auth/logout`,
      config
    );
  };

  static signUp = credentials => {
    const body = {
      ...credentials,
      password_confirmation: credentials.password
    };
    const config = {
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store'
      },
      method: 'POST'
    };

    return ApiHandlerService.fetch(`${env.API_BASE_URL}/auth/register`, config);
  };
}
