import { connect } from 'react-redux';
import Examination from './examination';
import {
  getExamination,
  getPatient,
  startExamination,
  stopExamination,
  pairDevice,
  resendData,
  listIntervals,
  getProgress
} from '../../reducers/request';
import { Actions as NotificationsActions } from '../../reducers/notifications';
import { Actions as ExaminationActions } from '../../reducers/examinations';

const mapStateToProps = ({ app, auth, patients, examinations }) => ({
  app,
  patients,
  examinations,
  progress: examinations && examinations.progress,
  token: auth && auth.user && auth.user.token,
  user: auth.user
});


export default connect(
  mapStateToProps,
  {
    getExamination,
    getPatient,
    startExamination,
    stopExamination,
    pairDevice,
    resendData,
    showNotification: NotificationsActions.showNotification,
    listIntervals,
    getProgress,
    cleanExaminationProgress: ExaminationActions.cleanExaminationProgress
  }
)(Examination);
