/**
 * Not a real reducer, only types are defined. The reducer logic
 * is split apart between other real reducers.
 */

const GET_PROGRESS_SUCCESS = 'GET_PROGRESS_SUCCESS';
const GET_PROGRESS_FAILED = 'GET_PROGRESS_FAILED';

/* eslint-disable import/prefer-default-export */
export const Types = {
  GET_PROGRESS_SUCCESS,
  GET_PROGRESS_FAILED
};
/* eslint-enable import/prefer-default-export */
