import { buffers } from 'redux-saga';
import {
  take,
  actionChannel,
  call,
  fork,
  put,
  select
} from 'redux-saga/effects';
import Api from '../../api';
import { Actions as AuthActions } from '../../reducers/auth';
import {
  Actions as NotificationsActions,
  MessageTypes
} from '../../reducers/notifications';
import navigationService from '../../services/navigation-service';
import env from '../../config';
import i18n from '../../i18n';

export function* watchRequests() {
  const requestChan = yield actionChannel(
    'REQUEST',
    buffers.dropping(env.SAGA_BUFFER_SIZE || 10)
  );
  while (true) {
    const { payload } = yield take(requestChan);

    yield call(handleRequest, payload);
  }
}

export const selectToken = state =>
  state.auth && state.auth.user && state.auth.user.token;

const getErrorMessage = (action, response) => {
  if (!response.data) return null;

  if (action === 'STOP_EXAMINATION' && response.data.message) {
    return i18n.t(`backendResponse.${response.data.message}`);
  }
  return response.data && (response.data.message || response.data.detail);
};

export function* handleRequest(payload) {
  try {
    const token = yield select(selectToken);
    const response = yield call(
      Api.request,
      payload.requestId,
      payload.variables,
      token
    );

    if (!response) {
      throw Error('No response returned.');
    }

    if (response.ok) {
      yield put({
        type: `${payload.action}_SUCCESS`,
        payload: response.data
      });
    } else if (response.status === 401) {
      yield put({
        type: 'FLUSH'
      });
      yield call(navigationService.navigate, '/');
    } else {
      // send failed action
      yield put({
        type: `${payload.action}_FAILED`,
        payload: response.data
      });
      // send notification action
      const message = getErrorMessage(payload.action, response);
      yield put(
        NotificationsActions.showNotification({
          uid: 'network-error',
          message,
          type: MessageTypes.Error,
          flash: true
        })
      );
    }

    if (response.headers && response.headers.authorization) {
      yield put(
        AuthActions.swapJwtToken(
          response.headers.authorization.replace('Bearer ', '')
        )
      );
    }
  } catch (error) {
    yield put(
      NotificationsActions.showNotification({
        uid: 'network-error',
        message: error.message,
        type: MessageTypes.Error,
        flash: true
      })
    );
  }
}

function* requestSaga() {
  yield fork(watchRequests);
}

export default requestSaga;
