import { connect } from 'react-redux';
import Recent from './recent';
import { getPatients, getAllExaminations } from '../../reducers/request';

const mapStateToProps = ({
  patients,
  isFetching,
  examinations,
  isFetchingExaminations
}) => ({
  patients,
  isFetching,
  examinations,
  isFetchingExaminations
});

export default connect(
  mapStateToProps,
  { getPatients, getAllExaminations }
)(Recent);
