import { REQUEST, ActionTypes } from '../request';

const GET_PRINT_DATA_SUCCESS = 'GET_PRINT_DATA_SUCCESS';
const GET_PRINT_DATA_FAILED = 'GET_PRINT_DATA_FAILED';

export const Types = {
  GET_PRINT_DATA_SUCCESS,
  GET_PRINT_DATA_FAILED,
  REQUEST
};

export const initialState = {
  isFetching: false,
  patient: {},
  examination: {},
  interval: {},
  evaluation: {},
  benchmark: {},
  errors: null
};

const patients = (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        isFetching:
          (action.payload &&
            action.payload.action === ActionTypes.GET_PRINT_DATA) ||
          state.isFetching,
        errors: null
      };

    case GET_PRINT_DATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        patient:
          (action.payload &&
            action.payload.data &&
            action.payload.data.patient) ||
          {},
        examination:
          (action.payload &&
            action.payload.data &&
            action.payload.data.examination) ||
          {},
        interval:
          (action.payload &&
            action.payload.data &&
            action.payload.data.interval) ||
          {},
        evaluation:
          (action.payload &&
            action.payload.data &&
            action.payload.data.evaluation) ||
          {},
        benchmark:
          (action.payload &&
            action.payload.data &&
            action.payload.data.benchmark) ||
          {},
        errors: null
      };

    case GET_PRINT_DATA_FAILED:
      return {
        ...state,
        isFetching: false,
        errors: action.payload
      };

    case 'FLUSH':
      return initialState;
    default:
      return state;
  }
};

export const Actions = {};

export default patients;
