/* eslint-disable react/jsx-wrap-multilines */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import '../../styles/login.css';

import i18n from '../../i18n';

class LoginView extends Component {
  static propTypes = {
    handleChange: PropTypes.func,
    isKeepMeLoggedIn: PropTypes.bool
  };

  static showErrors(errors) {
    /* eslint-disable react/no-array-index-key */
    return errors.map((err, i) => (
      <p key={`login_error_msg_${i}`} className="error-msg">
        {err}
      </p>
    ));
    /* eslint-enable react/no-array-index-key */
  }

  render() {
    const { login, changeView, handleChange, isKeepMeLoggedIn } = this.props;
    const { props } = this;

    if (login) {
      return (
        <div className="Login">
          <div className="content">
            <div className="login-header">
              <img src="/assets/images/mml_logo.png" alt="logo" />
              <p>{i18n.t('login.loginText')}</p>
            </div>
            <form onSubmit={props.handleLogin}>

              <div className="input-field">
                <input
                  type="text"
                  name="username"
                  placeholder={i18n.t('login.email')}
                />
              </div>
              <div className="input-field">
                <input
                  type="password"
                  name="password"
                  placeholder={i18n.t('login.password')}
                />
              </div>
              <div className="checkbox-field">
                <FormControlLabel
                  style={{ marginTop: 10, marginBottom: 5, padding: 0 }}
                  label={i18n.t('login.keepMeLoggedIn')}
                  control={
                    <Checkbox
                      style={{
                        marginTop: 0,
                        padding: 0,
                        marginRight: 10,
                        color: '#28bcbc'
                      }}
                      name="isKeepMeLoggedIn"
                      checked={isKeepMeLoggedIn}
                      onChange={e => handleChange(e)}
                      classes={{ root: { '&checked': { color: '#28bcbc' } } }}
                    />
                  }
                />
              </div>
              <div className="submit-field">
                <button type="submit">{i18n.t('login.loginButton')}</button>
                <a href="https://mml.science/mymml/lost-password/" style={{ display: "block", marginTop: 20, background: "#fff0", color: "#000" }}>{i18n.t('login.forgetButton')}</a>
              </div>
            </form>
            {LoginView.showErrors(props.errors)}
          </div >
        </div >
      );
    } else {
      return (
        <div className="Login">
          <div className="content">
            <div className="login-header">
              <img src="/assets/images/mml_logo.png" alt="logo" />
              <p>{i18n.t('login.forgetText')}</p>
            </div>
            <form onSubmit={props.handleForget}>
              {
                props.forgetSended ?
                <p>
                  { i18n.t('login.forgetInfo') }
                </p> : <span /> 
              }
            <div className="input-field">
              <input
                type="email"
                name="email"
                placeholder={i18n.t('login.email')}
              />
            </div>
            <div className="submit-field">
              <button type="submit">{i18n.t('login.forgetButton')}</button>
              <button onClick={(e) => changeView(e)} type="button" style={{ marginTop: 20, background: "#fff0", color: "#000" }}>{i18n.t('loadingActions.back')}</button>
            </div>
          </form>
          {LoginView.showErrors(props.errors)}
        </div >
        </div >
      );
    }
  }
}
export default LoginView;
