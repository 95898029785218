import { connect } from 'react-redux';
import Interval from './interval';
import {
  listEvaluations,
  startEvaluation,
  getInterval,
  updateInterval,
  getExamination
} from '../../../../reducers/request';

const mapStateToProps = (state, props) => {
  const { evaluations, intervals, examinations } = state;
  const { location } = props;

  // extract the interval id from the url
  const regExp = /\/examinations\/(\d*)(\/interval\/(\d*)(\/(.*)\/(\d*))*)*/i;
  const matches = regExp.exec(location.pathname);
  const examinationId = matches && matches.length > 1 && matches[1];
  const intervalId = matches && matches.length > 3 && matches[3];

  const examination =
    examinations &&
    examinations.examinations &&
    examinations.examinations.find(
      e => e.id && e.id.toString() === examinationId
    );

  const isFetchingExamination = examinations && examinations.isFetching;

  // extract the interval from the list
  const interval =
    intervals &&
    intervals.intervals &&
    intervals.intervals.find(e => e.id && e.id.toString() === intervalId);

  const errors = intervals && intervals.errors;

  return {
    errors,
    examinationId,
    interval,
    intervalId,
    examination,
    isFetchingExamination,
    isFetchingIntervals: intervals && intervals.isFetching,
    isFetchingEvaluations: evaluations && evaluations.isFetching,
    isEvaluationRunning: evaluations && evaluations.isEvaluationRunning
  };
};

export default connect(
  mapStateToProps,
  {
    listEvaluations,
    startEvaluation,
    getInterval,
    updateInterval,
    getExamination
  }
)(Interval);
