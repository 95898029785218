import PropTypes from 'prop-types'; // eslint-disable-line
import { VictoryChart, VictoryLine, VictoryAxis, VictoryBar } from 'victory';
import React, { Component } from 'react';

import i18n from '../../../../i18n';

class OverviewChart extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    ticks: PropTypes.arrayOf(PropTypes.any).isRequired,
    tickFormatter: PropTypes.func.isRequired
  };

  render() {
    const { data, ticks, tickFormatter } = this.props;
    return (
      <svg viewBox="0 0 1000 320">
        <VictoryChart standalone={false} width={1000} height={320}>
          <VictoryAxis
            orientation="left"
            domain={[-10, 10]}
            tickValues={[-15, -10, -5, 0, 5, 10, 15]}
            style={{
              tickLabels: { fontSize: 12, padding: 6 }
            }}
          />
          <VictoryAxis
            dependentAxis
            orientation="right"
            tickValues={[-35, -30, -25, -20, -15, -10, -5, 0]}
            tickFormat={t => t + 30}
            style={{
              tickLabels: { fontSize: 12, padding: 6 }
            }}
          />
          <VictoryAxis
            dependentAxis
            orientation="bottom"
            offsetY={50}
            label={i18n.t(`overviewInfo.time`)}
            tickValues={ticks}
            tickFormat={t => tickFormatter(t)}
            style={{
              grid: { stroke: '#D0D0D0' },
              tickLabels: { fontSize: 12, padding: 6 }
            }}
          />
          <VictoryBar
            data={data}
            x="time"
            y0="v_min"
            y="v_max"
            style={{
              data: { fill: '#28bcbc' }
            }}
          />
          <VictoryLine
            data={data}
            x="time"
            y={d => d.momentum - 30}
            style={{
              data: { stroke: '#f26f5a' }
            }}
          />
        </VictoryChart>
      </svg>
    );
  }
}

export default OverviewChart;
