import React, { Component } from 'react';
import FormView from './form-view';

class Form extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }


  render() {
    return (
      <FormView />
    );
  }
}

export default Form;
