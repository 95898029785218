// import env from "../../config";
import PatientsApi from './patients';
import ExaminationsApi from './examinations';
import PrintApi from './print';
import IntervalApi from './intervals';
import EvaluationsApi from './evaluations';
import BenchmarkApi from './benchmark';
import ProgressApi from './progress';

export default class Api {
  static request = (requestId, variables, token) => {
    switch (requestId) {
      case 'addPatient':
        return PatientsApi.add(variables, token);
      case 'getPatient':
        return PatientsApi.get(variables, token);
      case 'getPatients':
        return PatientsApi.list(variables, token);
      case 'updatePatient':
        return PatientsApi.update(variables, token);
      case 'addExamination':
        return ExaminationsApi.add(variables, token);
      case 'updateExamination':
        return ExaminationsApi.update(variables, token);
      case 'getAllExaminations':
        return ExaminationsApi.list(variables, token);
      case 'getExamination':
        return ExaminationsApi.get(variables, token);
      case 'getExaminations':
        return ExaminationsApi.getAllByPatient(variables, token);
      case 'getLastExamination':
        return ExaminationsApi.getAllByPatient(variables, token);
      case 'startExamination':
        return ExaminationsApi.start(variables, token);
      case 'stopExamination':
        return ExaminationsApi.stop(variables, token);
      case 'pairDevice':
        return ExaminationsApi.pairDevice(variables, token);
      case 'resendData':
        return ExaminationsApi.resendData(variables, token);
      case 'addInterval':
        return IntervalApi.add(variables, token);
      case 'deleteInterval':
        return IntervalApi.delete(variables, token);
      case 'getInterval':
        return IntervalApi.get(variables, token);
      case 'getIntervalCycles':
        return IntervalApi.getCycles(variables, token);
      case 'listIntervals':
        return IntervalApi.list(variables, token);
      case 'updateInterval':
        return IntervalApi.update(variables, token);
      case 'addEvaluation':
        return EvaluationsApi.add(variables, token);
      case 'getEvaluation':
        return EvaluationsApi.get(variables, token);
      case 'listEvaluations':
        return EvaluationsApi.list(variables, token);
      case 'updateEvaluation':
        return EvaluationsApi.update(variables, token);
      case 'startEvaluation':
        return EvaluationsApi.start(variables, token);
      case 'getBenchmark':
        return BenchmarkApi.get(variables, token);
      case 'getPrintData':
        return EvaluationsApi.getPrintData(variables, token);
      case 'print':
        return PrintApi.print(variables, token);
      case 'getProgress':
        return ProgressApi.get(variables, token);
      default:
    }
    return null;
  };
}
