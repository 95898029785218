import { REQUEST, REHYDRATE } from '../request';
import { Types as ProgressTypes } from '../progress';

const ADD_EXAMINATION_SUCCESS = 'ADD_EXAMINATION_SUCCESS';
const UPDATE_EXAMINATION_SUCCESS = 'UPDATE_EXAMINATION_SUCCESS';
const GET_ALL_EXAMINATIONS_SUCCESS = 'GET_ALL_EXAMINATIONS_SUCCESS';
const GET_EXAMINATION_SUCCESS = 'GET_EXAMINATION_SUCCESS';
const GET_EXAMINATIONS_SUCCESS = 'GET_EXAMINATIONS_SUCCESS';
const START_EXAMINATION_SUCCESS = 'START_EXAMINATION_SUCCESS';
const STOP_EXAMINATION_SUCCESS = 'STOP_EXAMINATION_SUCCESS';
const RESEND_EXAMINATION_DATA_SUCCESS = 'RESEND_EXAMINATION_DATA_SUCCESS';
const ADD_EXAMINATION_FAILED = 'ADD_EXAMINATION_FAILED';
const UPDATE_EXAMINATION_FAILED = 'UPDATE_EXAMINATION_FAILED';
const GET_ALL_EXAMINATIONS_FAILED = 'GET_ALL_EXAMINATIONS_FAILED';
const GET_EXAMINATION_FAILED = 'GET_EXAMINATION_FAILED';
const START_EXAMINATION_FAILED = 'START_EXAMINATION_FAILED';
const STOP_EXAMINATION_FAILED = 'STOP_EXAMINATION_FAILED';
const RESEND_EXAMINATION_DATA_FAILED = 'RESEND_EXAMINATION_DATA_FAILED';
const CLEAN_EXAMINATION_PROGRESS = 'CLEAN_EXAMINATION_PROGRESS';
const PAIR_DEVICE_SUCCESS = 'PAIR_DEVICE_SUCCESS';

export const Types = {
  ADD_EXAMINATION_SUCCESS,
  UPDATE_EXAMINATION_SUCCESS,
  GET_EXAMINATION_SUCCESS,
  GET_EXAMINATIONS_SUCCESS,
  GET_ALL_EXAMINATIONS_SUCCESS,
  START_EXAMINATION_SUCCESS,
  STOP_EXAMINATION_SUCCESS,
  RESEND_EXAMINATION_DATA_SUCCESS,
  ADD_EXAMINATION_FAILED,
  UPDATE_EXAMINATION_FAILED,
  GET_ALL_EXAMINATIONS_FAILED,
  GET_EXAMINATION_FAILED,
  START_EXAMINATION_FAILED,
  STOP_EXAMINATION_FAILED,
  RESEND_EXAMINATION_DATA_FAILED,
  CLEAN_EXAMINATION_PROGRESS,
  PAIR_DEVICE_SUCCESS,
  REQUEST
};

export const initialState = {
  isFetching: false,
  progress: null,
  examinations: [],
  errors: null
};

const examinations = (state = initialState, action) => {
  if (!action) return state;

  let id;
  let found;
  let examinationsUpdated;

  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        isFetching:
          (action.payload &&
            (/EXAMINATION/.test(action.payload.action) &&
              !/START|STOP|RESEND/.test(action.payload.action))) ||
          state.isFetching,
        errors:
          action.payload && /EXAMINATION/.test(action.payload.action)
            ? null
            : state.errors
      };
    case GET_EXAMINATIONS_SUCCESS: // load all examinations of a patient (added to the patient only)
      return {
        ...state,
        isFetching: false,
        progress: null
      };
    case GET_ALL_EXAMINATIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        examinations:
          (action.payload &&
            action.payload.data &&
            action.payload.data.examinations) ||
          [],
        errors: null,
        progress: null
      };
    case ADD_EXAMINATION_SUCCESS:
    case UPDATE_EXAMINATION_SUCCESS:
    case GET_EXAMINATION_SUCCESS:
      id =
        action &&
        action.payload &&
        action.payload.data &&
        action.payload.data.id;
      if (!id) return state;

      if (!action.payload.data.examination) return state;

      found = false;
      examinationsUpdated = {
        ...state,
        isFetching: false,
        progress: null,
        examinations: (state.examinations || []).map(itm => {
          if (!itm || (itm.id && itm.id.toString()) !== id.toString()) {
            // this isn't the item we care about - keep it as-is
            return itm;
          }

          // otherwise, this is the item we are looking for => return the retrieved value
          found = true;
          const editedExam = action.payload.data.examination;

          if (!editedExam.results && itm.results) {
            editedExam.results = itm.results;
          }
          return editedExam;
        }),
        errors: null
      };

      if (!found) {
        examinationsUpdated.examinations.push(action.payload.data.examination);
      }

      return examinationsUpdated;
    case ADD_EXAMINATION_FAILED:
    case UPDATE_EXAMINATION_FAILED:
    case GET_ALL_EXAMINATIONS_FAILED:
    case GET_EXAMINATION_FAILED:
      return {
        ...state,
        isFetching: false,
        progress: null,
        errors: action.payload
      };
    case START_EXAMINATION_FAILED:
      return {
        ...state,
        progress: {
          entityId: action.payload && action.payload.examinationId,
          type: 'examination',
          status: 'failed',
          message: action.payload && action.payload.message
        }
      };
    case ProgressTypes.GET_PROGRESS_SUCCESS:
      return {
        ...state,
        progress: (action.payload && action.payload.data) || state.progress
      };
    case ProgressTypes.GET_PROGRESS_FAILED:
      return {
        ...state,
        progress: {
          status: 'noExamInProgress',
          message: (action.payload && action.payload.message) || 'Unknown error'
        }
      };
    case CLEAN_EXAMINATION_PROGRESS:
      return {
        ...state,
        progress: null
      };
    case REHYDRATE:
      return {
        ...((action.payload && action.payload.examinations) || initialState),
        isFetching: false,
        progress: null
      };
    case 'FLUSH':
      return initialState;
    default:
      return state;
  }
};

export const cleanExaminationProgress = id => ({
  type: CLEAN_EXAMINATION_PROGRESS,
  id
});

export const Actions = { cleanExaminationProgress };

export default examinations;
