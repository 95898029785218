import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import '../../styles/not-found.css';

import i18n from '../../i18n';

class NotFound extends Component {
  static propTypes = {
    isWrapped: PropTypes.bool, // Defines if the 404 page has to be wrapped in a div or can stand alone
    message: PropTypes.string,
    backTo: PropTypes.string,
    icon: PropTypes.bool
  };

  isThereMessage() {
    const { message } = this.props;
    if (message) {
      return <p className="not-found-message">{message}</p>;
    }
    return null;
  }

  isThereBackTo() {
    const { backTo } = this.props;
    if (backTo) {
      return (
        <Link to={backTo}>
          <button type="button" className="not-found-button">
            {i18n.t('loadingActions.back')}
          </button>
        </Link>
      );
    }
    return null;
  }

  renderNotFound() {
    return (
      <div className="not-found">
        {this.renderIcon()}
        {this.isThereMessage()}
        {this.isThereBackTo()}
      </div>
    );
  }

  renderIcon() {
    const { icon } = this.props;
    if (icon) {
      return null;
    }
    return <h1 id="not-found-number">404</h1>;
  }

  render() {
    const { isWrapped } = this.props;

    if (isWrapped) {
      return (
        <div style={{ position: 'relative', width: '100%', height: '200px' }}>
          {this.renderNotFound()}
        </div>
      );
    }
    return this.renderNotFound();
  }
}

export default NotFound;
