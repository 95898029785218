import { connect } from 'react-redux';
import Benchmark from './benchmark';
import {
  getBenchmark,
  getPatient,
  getEvaluation,
  getExamination,
  getInterval,
  updateEvaluation
} from '../../../../reducers/request';

const mapStateToProps = (state, props) => {
  const { intervals, evaluations, examinations, patients, benchmark } = state;
  const { location } = props;

  // extract the interval id from the url
  /*
  const regExp = /\/benchmark\/(\d*)/i;
  const matches = regExp.exec(location.pathname);
  const id = matches && matches.length > 1 && matches[1];
  */

  const regExp = /\/examinations\/(\d*)(\/interval\/(\d*)(\/benchmark\/(\d*))*)*/i;
  const matches = regExp.exec(location.pathname);
  const examinationId = matches && matches.length > 1 && matches[1];
  const intervalId = matches && matches.length > 3 && matches[3];
  const benchmarkId = matches && matches.length > 5 && matches[5];

  // extract the evaluation from the list
  const evaluation =
    evaluations &&
    evaluations.evaluations &&
    evaluations.evaluations.find(e => e.id && e.id.toString() === benchmarkId);

  const benchmarkData = benchmark && benchmark.benchmark;
  const evaluationErrors = evaluations && evaluations.errors;
  const isBenchmarkLoading = benchmark && benchmark.isFetching;

  const benchmarkErrors = benchmark && benchmark.errors;

  // extract the interval from the list
  const interval =
    intervals &&
    intervals.intervals &&
    intervals.intervals.find(e => e.id && e.id.toString() === intervalId);

  const intervalErrors = intervals && intervals.errors;

  // extract the examination from the list
  const examination =
    examinations &&
    examinations.examinations &&
    examinations.examinations.find(
      e => e.id && e.id.toString() === examinationId
    );

  const examinationErrors = examinations && examinations.errors;

  // extract the patient from the list
  // NOT WORKING
  //
  // @TODO:
  // Make it work...

  const patientId = examination && examination.patientId;

  const patient =
    patientId &&
    patients &&
    patients.patients &&
    patients.patients.find(
      e => e.id && e.id.toString() === patientId.toString()
    );

  const patientErrors = patients && patients.errors;

  const isIntervalFetching = intervals.isFetching;
  const isExaminationFetching = examinations.isFetching;
  const isEvaluationFetching = evaluations.isFetching;
  const isPatientFetching = patients.isFetching;

  return {
    interval,
    examination,
    evaluation,
    patient,
    isIntervalFetching,
    isExaminationFetching,
    isEvaluationFetching,
    isPatientFetching,
    intervalId,
    examinationId,
    evaluationId: benchmarkId,
    patientId,
    patients: patients.patients,
    benchmark: benchmarkData,
    benchmarkId: evaluation && evaluation.benchmarkId,
    benchmarkErrors,
    isBenchmarkLoading,
    patientErrors,
    examinationErrors,
    evaluationErrors,
    intervalErrors
  };
};

export default connect(
  mapStateToProps,
  {
    getBenchmark,
    getPatient,
    getEvaluation,
    getExamination,
    getInterval,
    updateEvaluation
  }
)(Benchmark);
