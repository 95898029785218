/* globals window */

import { takeLatest, fork, put, call } from 'redux-saga/effects';
import { Types } from '../../reducers/patients';
import {
  Actions as NotificationsActions,
  MessageTypes
} from '../../reducers/notifications';

export function* updated(response) {
  yield put(
    NotificationsActions.showNotification({
      message: response && response.payload && response.payload.message,
      type: MessageTypes.Success,
      flash: true
    })
  );
}

export function* added(response) {
  yield put(
    NotificationsActions.showNotification({
      message: response && response.payload && response.payload.message,
      type: MessageTypes.Success,
      flash: true
    })
  );

  if (response && response.payload && response.payload.data) {
    const { id } = response.payload.data;
    yield call(redirectToPatient, id);
  }
}

export function redirectToPatient(id) {
  window.location.href = `${window.location.href}/${id}`;
}

function* patientSaga() {
  yield fork(takeLatest, Types.ADD_PATIENT_SUCCESS, added);
  yield fork(takeLatest, Types.UPDATE_PATIENT_SUCCESS, updated);
}

export default patientSaga;
