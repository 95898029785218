import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import i18n from '../../i18n';

class ExaminationSubmenu extends Component {
  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string
    })
  };

  /**
   * Returns 'active' as an additional css classname if the route is active
   *
   * @param {boolean} isActive The boolean coming from a submenu's active key
   */
  static setActiveRoute(isActive) {
    if (isActive) return 'active';
    return '';
  }

  /**
   * Returning which submenus are needed to display according to the url
   */
  submenusToDisplay() {
    const submenus = [];
    const { location } = this.props;

    const regExp = /\/examinations\/(\d*)(\/interval\/(\d*)(\/(.*)\/(\d*))*)*/i;
    const matches = regExp.exec(location.pathname);
    const examinationId = matches && matches.length > 1 && matches[1];
    const intervalId = matches && matches.length > 3 && matches[3];
    const tab = matches && matches.length > 5 && matches[5];
    const tabId = matches && matches.length > 6 && matches[6];

    // Check which location is active and set exact active variable true. Add this to the the exact submenu.
    const isActiveOverview = location.pathname.includes('overview');
    const isActiveInterval = intervalId && matches[5] === undefined;
    const isActiveEvaluation = intervalId && tab && tab === 'evaluation';
    const isActiveBenchmark = intervalId && tab && tab === 'benchmark';
    const isActiveDiagnosis = intervalId && tab && tab === 'diagnosis';

    submenus.push({
      name: i18n.t(`navigation.overview`),
      path: `/examinations/${examinationId}/overview`,
      isActive: isActiveOverview
    });

    if (intervalId) {
      submenus.push({
        name: i18n.t(`navigation.interval`),
        path: `/examinations/${examinationId}/interval/${intervalId}`,
        isActive: isActiveInterval
      });
    }

    if (tab) {
      submenus.push({
        name: i18n.t(`navigation.evaluation`),
        path: `/examinations/${examinationId}/interval/${intervalId}/evaluation/${tabId}`,
        isActive: isActiveEvaluation
      });
      submenus.push({
        name: i18n.t(`navigation.benchmark`),
        path: `/examinations/${examinationId}/interval/${intervalId}/benchmark/${tabId}`,
        isActive: isActiveBenchmark
      });
      submenus.push({
        name: i18n.t(`navigation.diagnosis`),
        path: `/examinations/${examinationId}/interval/${intervalId}/diagnosis/${tabId}`,
        isActive: isActiveDiagnosis
      });
    }

    return submenus;
  }

  /**
   * Rendering the submenus
   */
  static renderSubmenu(submenus) {
    return submenus.map(submenu => (
      <Link
        key={submenu.name}
        to={submenu.path}
        className={`submenu-item ${ExaminationSubmenu.setActiveRoute(
          submenu.isActive
        )}`}
      >
        <h2>{submenu.name}</h2>
      </Link>
    ));
  }

  render() {
    const submenus = this.submenusToDisplay();
    return (
      <React.Fragment>
        <div className="menu">{ExaminationSubmenu.renderSubmenu(submenus)}</div>
      </React.Fragment>
    );
  }
}

export default ExaminationSubmenu;
