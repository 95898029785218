import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  VictoryChart,
  VictoryLine,
  VictoryAxis,
  VictoryLabel,
  VictoryLegend
} from 'victory';
import i18n from '../../i18n';

class ChartAverage extends Component {
  static propTypes = {
    averageDomain: PropTypes.shape({}),
    average: PropTypes.shape({}),
    fontSize: PropTypes.number
  };

  static renderTicks(t, modulo) {
    if (t % modulo === 0) {
      return `${t}`;
    }
    return '';
  }

  getDividerNumber() {
    const { averageDomain } = this.props;
    if (averageDomain && averageDomain.ticks) {
      let counter = 1;
      while (averageDomain.ticks.length / counter > 15) {
        counter += 2;
      }
      return counter;
    }
    return 1;
  }

  render() {
    const { average, averageDomain, fontSize } = this.props;
    const tickDivider = this.getDividerNumber();

    return (
      <VictoryChart
        padding={{ left: 30, top: 15, bottom: 30, right: 20 }}
        height={180}
      >
        <VictoryLegend
          x={206}
          y={5}
          orientation="horizontal"
          symbolSpacer={2}
          gutter={5}
          style={{ labels: { fontSize } }}
          data={[
            { name: i18n.t('chartInfo.left'), symbol: { fill: 'f26f5a' } },
            { name: i18n.t('chartInfo.right'), symbol: { fill: '#28bcbc' } }
          ]}
        />
        <VictoryAxis
          dependentAxis
          tickValues={averageDomain.ticks}
          tickFormat={() => ''}
          style={{
            grid: { stroke: '#d0d0d0' }
          }}
        />
        <VictoryAxis
          style={{
            tickLabels: { fontSize },
            grid: { stroke: '#d0d0d0' }
          }}
          dependentAxis
          orientation="bottom"
          tickValues={[
            0,
            5,
            10,
            15,
            20,
            25,
            30,
            35,
            40,
            45,
            50,
            55,
            60,
            65,
            70,
            75,
            80,
            85,
            90,
            95,
            100
          ]}
          tickFormat={() => ''}
        />
        <VictoryLine
          style={{ data: { stroke: '#28bcbc', strokeWidth: 0.8 } }}
          data={average.datasets[1]}
        />
        <VictoryLine
          style={{
            data: { stroke: '#f26f5a', strokeWidth: 0.8, strokeDasharray: 2 }
          }}
          data={average.datasets[0]}
        />
        <VictoryAxis
          style={{ tickLabels: { fontSize } }}
          dependentAxis
          orientation="bottom"
          offsetY={30}
          tickValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          tickFormat={t => `${t}%`}
          tickLabelComponent={<VictoryLabel />}
        />
        <VictoryAxis
          dependentAxis
          tickValues={averageDomain.ticks}
          tickFormat={t => `${ChartAverage.renderTicks(t, tickDivider)}`}
          style={{
            tickLabels: { fontSize }
          }}
        />
      </VictoryChart>
    );
  }
}

export default ChartAverage;
