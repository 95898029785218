import React, { Component } from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line
import ChartAverage from '../../../charts/average';

import DiagnosisTable from './diagnosis-table';
import DiagnosisBody from './diagnosis-body';
import '../../../../styles/diagnosis.css';
import ChartLabel from '../../../charts/labels';
import i18n from '../../../../i18n';

class DiagnosisView extends Component {
  static propTypes = {
    averages: PropTypes.arrayOf(PropTypes.any).isRequired,
    averagesDomain: PropTypes.shape({
      ticks: PropTypes.arrayOf(PropTypes.number),
      max: PropTypes.number,
      min: PropTypes.number
    }),
    evaluationId: PropTypes.number,
    evaluation: PropTypes.shape({}),
    parameters: PropTypes.shape({}),
    printReport: PropTypes.func.isRequired,
    print: PropTypes.func.isRequired,
    updateEvaluation: PropTypes.func.isRequired,
    evaluationTime: PropTypes.string,
    movingAverage: PropTypes.number,
    intervalName: PropTypes.string,
    examination: PropTypes.shape({}),
    patient: PropTypes.shape({}),
    interval: PropTypes.shape({}),
    benchmark: PropTypes.shape({})
  };

  renderAverages() {
    const {
      averages,
      averagesDomain,
      evaluationTime,
      movingAverage
    } = this.props;

    return averages.map(chart => (
      <div className="chart box" key={chart.name}>
        <ChartLabel
          labelInfo={{
            evaluationTime,
            movingAverage,
            chartName: chart.name
          }}
          labelType="averages"
        />
        <ChartAverage
          average={chart}
          averageDomain={averagesDomain}
          fontSize={9}
        />
      </div>
    ));
  }

  render() {
    const {
      evaluationId,
      evaluation,
      parameters,
      print,
      printReport,
      updateEvaluation,
      intervalName,
      examination,
      patient,
      interval,
      benchmark
    } = this.props;

    return (
      <div className="diagnosis">
        <div className="head">
          <h1>
            {`
          ${i18n.t('diagnosisInfo.medicalDiagnosis')} 
          |  
          ${intervalName}
          `}
          </h1>
        </div>
        <div className="grid grid-two">
          <div className="grid grid-one">{this.renderAverages()}</div>
          <div className="table box">
            <DiagnosisTable parameters={parameters} />
          </div>
        </div>
        <div className="head">
          <h1>{i18n.t('diagnosisInfo.medicalTreatment')}</h1>
        </div>
        <DiagnosisBody
          evaluation={evaluation}
          examination={examination}
          patient={patient}
          interval={interval}
          benchmark={benchmark}
          evaluationId={evaluationId}
          printReport={printReport}
          print={print}
          updateEvaluation={updateEvaluation}
        />
      </div>
    );
  }
}

export default DiagnosisView;
