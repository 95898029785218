import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Menu from '../../components/menu';
import routes from '../../routes';
import MainView from './main-view';
import Login from '../login';

class Main extends Component {
  static propTypes = {
    user: PropTypes.shape({
      username: PropTypes.string.isRequired,
      token: PropTypes.string.isRequired
    }),
    isFetching: PropTypes.bool.isRequired,
    isMenuOpen: PropTypes.bool.isRequired
  };

  menuStyle(elem) {
    const { isMenuOpen } = this.props;
    if (isMenuOpen) {
      return '';
    }
    return `${elem}-closed-menu`;
  }

  isSignedIn() {
    const { user } = this.props;
    const { isFetching } = this.props;

    const AppElements = (
      <div className="app">
        <div className="fullmenu"></div>
        <Menu />
        <div className={`main-view ${this.menuStyle('main-view')}`}>
          {routes}
        </div>
      </div>
    );

    const LoginElements = <Login />;

    // @TODO:
    // Need to create separate view for this
    const LoadingElements = (
      <div>
        <h1>Loading...</h1>
      </div>
    );

    if (isFetching) {
      return LoadingElements;
    }

    if(user && user.username !== "attila.eles" && window.location.pathname === "/generateform") {
      return LoginElements;
    }

    if (user && user.token) {
      return AppElements;
    }

    return LoginElements;
  }

  render() {
    const elements = this.isSignedIn();
    return <MainView elements={elements} />;
  }
}

export default Main;
