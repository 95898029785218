import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { VictoryChart, VictoryLine, VictoryAxis, VictoryLabel } from 'victory';

class ChartDimension extends Component {
  static propTypes = {
    dimensionDomain: PropTypes.shape({}),
    dimension: PropTypes.shape({})
  };

  static renderLines(dataSet) {
    return dataSet.map((data, index) => (
      <VictoryLine
        key={index} // eslint-disable-line
        style={{
          data: { stroke: '#28bcbc', strokeWidth: 1, strokeOpacity: 0.5 }
        }}
        data={data}
      />
    ));
  }

  static renderTicks(t, modulo) {
    if (t % modulo === 0) {
      return `${t}`;
    }
    return '';
  }

  getDividerNumber() {
    const { dimensionDomain } = this.props;
    if (dimensionDomain && dimensionDomain.ticks) {
      let counter = 1;
      while (dimensionDomain.ticks.length / counter > 10) {
        counter += 2;
      }
      return counter;
    }
    return 1;
  }

  render() {
    const { dimension, dimensionDomain } = this.props;
    const tickDivider = this.getDividerNumber();
    return (
      <VictoryChart
        padding={{ left: 40, top: 20, bottom: 50, right: 30 }}
        height={200}
        domain={{ y: [dimensionDomain.min, dimensionDomain.max] }}
      >
        <VictoryAxis
          dependentAxis
          tickValues={dimensionDomain.ticks}
          tickFormat={t => `${ChartDimension.renderTicks(t, tickDivider)}`}
          style={{
            grid: { stroke: '#d0d0d0' },
            tickLabels: { opacity: 0 }
          }}
        />
        {ChartDimension.renderLines(dimension.datasets)}
        <VictoryAxis
          dependentAxis
          style={{ tickLabels: { fontSize: 11 } }}
          orientation="bottom"
          // offsetY={50}
          tickValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          tickFormat={t => `${t}%`}
          tickLabelComponent={<VictoryLabel y={160} />}
        />
        <VictoryAxis
          dependentAxis
          tickValues={dimensionDomain.ticks}
          tickFormat={t => `${ChartDimension.renderTicks(t, tickDivider)}`}
          style={{
            tickLabels: { fontSize: 11 }
          }}
        />
      </VictoryChart>
    );
  }
}

export default ChartDimension;
