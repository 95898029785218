import { connect } from 'react-redux';
import Examinations from './examinations';
import { getAllExaminations } from '../../reducers/request';

const mapStateToProps = ({ examinations }) => ({
  examinations
});

export default connect(
  mapStateToProps,
  { getAllExaminations }
)(Examinations);
