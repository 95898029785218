import React, { Component } from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line
import { NavLink, Link } from 'react-router-dom';
import '../../../styles/sidebar.css';
import { Cookie, CookieJar, MemoryCookieStore } from 'tough-cookie';



import i18n from '../../../i18n';

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

class SidebarView extends Component {
  static propTypes = {
    user: PropTypes.shape({
      username: PropTypes.string,
      token: PropTypes.string
    }),
    navigationRoutes: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
    signOutHandler: PropTypes.func.isRequired
  };

  static renderNavigation(routes) {
    return routes.map(route => (
      <NavLink key={route.link} to={route.link} className="menu-item">
        <div className="menu-item-div">
          <h2>{route.label}</h2>
        </div>
      </NavLink>
    ));
  }

  render() {
    const { user } = this.props;
    const { navigationRoutes } = this.props;
    const { signOutHandler } = this.props;
    return (
      <div className="side-bar">
        <div className="app-name">
          <Link to="/recent">
            <img src="/assets/images/mml_logo.png" alt="Mobile Motion Lab" />
          </Link>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <img onClick={ (e) => { setCookie("language", "hu", 365); window.location.reload(false); }} style={{ width: 30, height: 30, padding: 5 }} src="/assets/images/hun.png" alt="hun" />
            <img onClick={ (e) => { setCookie("language", "en", 365); window.location.reload(false); }}style={{ width: 30, height: 30, padding: 5 }} src="/assets/images/eng.png" alt="eng" />
          </div>
        </div>
        <div className="user">
          <h1>{user && (user.name || user.username)}</h1>
          <div className="actions">
            {/* <p className="edit" type="button" role="presentation">
              {i18n.t(`profileActions.editProfile`)}
            </p> */}
            <p
              className="logout"
              type="button"
              role="presentation"
              onClick={signOutHandler}
            >
              {i18n.t(`profileActions.logout`)}
            </p>
          </div>
        </div>
        <div className="menu">
          {SidebarView.renderNavigation(navigationRoutes)}
        </div>
      </div>
    );
  }
}

export default SidebarView;
