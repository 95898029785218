/* globals window */
/* eslint react/jsx-wrap-multilines: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line

import i18n from '../../../i18n';

import { getModalFields, ModalTypes } from '../modal-fields';
import EditPatientView from './edit-patient-view';

class EditPatient extends Component {
  static propTypes = {
    patientId: PropTypes.number.isRequired,
    closeModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    updatePatient: PropTypes.func.isRequired,
    defaults: PropTypes.shape({})
  };

  constructor(props) {
    super(props);
    this.fields = getModalFields(ModalTypes.Patient, props.defaults);
    this.buttons = this.setupButtons();
  }

  componentWillReceiveProps(nextProps) {
    this.fields = getModalFields(ModalTypes.Patient, nextProps.defaults);
  }

  setupButtons() {
    return [
      {
        name: 'printStatement',
        type: 'submit',
        text: i18n.t('modalActions.printStatement'),
        doReset: false, // it resets the optional (true by default)
        // ignoreFieldsValidation: ['statement'],
        submit: () => {
          window.print();
        }
      },
      {
        name: 'editPatient',
        type: 'submit',
        text: i18n.t('modalActions.savePatient'),
        doValidate: true, // optional (true by default)
        submit: that => {
          this.editPatient(that);
        }
      }
    ];
  }

  editPatient(that) {
    const { updatePatient, patientId } = this.props;
    const { state } = that;
    const fields = { ...state.fields };

    let phone = fields.phone.value;
    let validPhone = phone;
    phone = phone.replace(/ /g, '');
    phone = phone.replace(/-/g, '');

    if (phone.startsWith('+36') && phone.length === 12) {
      validPhone = `${phone.slice(0, 3)}-${phone.slice(3, 5)}-${phone.slice(
        5,
        8
      )}-${phone.slice(8, 12)}`;
    }

    if (phone.startsWith('+36') && phone.length === 10) {
      validPhone = `${phone.slice(0, 3)}-${phone.slice(3, 4)}-${phone.slice(
        4,
        7
      )}-${phone.slice(7, 10)}`;
    }

    const patient = {
      firstName: fields.firstName.value,
      lastName: fields.lastName.value,
      dateOfBirth: fields.dateOfBirth.value,
      placeOfBirth: fields.placeOfBirth.value,
      mothersName: fields.mothersName.value,
      address: fields.address.value,
      email: fields.email.value,
      phone: validPhone,
      gender: fields.gender.value.toLowerCase(),
      dominantSide: fields.dominantSide.value.toLowerCase(),
      date: new Date()
    };

    updatePatient(patientId, patient);

    // fields.statement.checked = true;
    that.setState({ fields });
  }

  render() {
    const { isOpen, closeModal } = this.props;
    return (
      <EditPatientView
        modalType="patient"
        fields={this.fields}
        buttons={this.buttons}
        isOpen={isOpen}
        closeModal={closeModal}
      />
    );
  }
}

export default EditPatient;
