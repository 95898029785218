import React, { Component } from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line
import PatientsListView from './patient-list-view';

class PatientsList extends Component {
  static propTypes = {
    patients: PropTypes.shape({
      isFetching: PropTypes.bool.isRequired,
      patients: PropTypes.arrayOf(PropTypes.any).isRequired
    }),
    shouldSort: PropTypes.bool
  };

  render() {
    const { patients, shouldSort } = this.props;
    return <PatientsListView patients={patients} shouldSort={shouldSort} />;
  }
}

export default PatientsList;
