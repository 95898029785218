import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoginView from './login-view';

class Login extends Component {
  static propTypes = {
    signIn: PropTypes.func.isRequired,
    signInErrors: PropTypes.arrayOf(PropTypes.any).isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      isKeepMeLoggedIn: false,
      login: true,
      forgetSended: false
    };
    this.handleLogin = this.handleLogin.bind(this);
    this.handleForget = this.handleForget.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.changeView = this.changeView.bind(this);
  }

  handleForget(e) {
    e.preventDefault(); 

    fetch('https://menstage.kingstudio.hu/api/?email=' + e.target.email.value)
      .then(response => { console.log(response) })
      .catch(e => console.log(e))

    this.setState({forgetSended: true})
  }
  // Handles login request
  handleLogin(e) {
    const { isKeepMeLoggedIn } = this.state;
    const { signIn } = this.props;

    e.preventDefault();

    // TODO:
    // Data needs to be encrypted
    signIn({
      username: e.target.username.value,
      password: e.target.password.value,
      isKeepMeLoggedIn
    });
  }
  changeView(e) {
    this.setState({forgetSended: false})
    this.setState({login: !this.state.login})
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.checked });
  }

  render() {
    const { signInErrors } = this.props;
    const { isKeepMeLoggedIn } = this.state;
    return (
      <LoginView
        handleLogin={this.handleLogin}
        handleForget={this.handleForget}
        changeView={this.changeView}
        errors={signInErrors}
        isKeepMeLoggedIn={isKeepMeLoggedIn}
        login={this.state.login}
        forgetSended={this.state.forgetSended}
        handleChange={this.handleChange}
      />
    );
  }
}

export default Login;
