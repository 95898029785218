import React, { Component } from 'react';
import Draggable from 'react-draggable';
import PropTypes from 'prop-types';
import { TextField, MenuItem } from '@material-ui/core';
import moment from 'moment';
import i18n from '../../../../i18n';

class OverviewSeparator extends Component {
  static propTypes = {
    convertXToTime: PropTypes.func.isRequired,
    changeDraggablePosition: PropTypes.func.isRequired,
    newInterval: PropTypes.func.isRequired,
    getDraggableBounds: PropTypes.func.isRequired,
    getDraggablePosition: PropTypes.func.isRequired,
    possibleSpeedOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
    handleSpeedChange: PropTypes.func.isRequired,
    actualSpeed: PropTypes.number.isRequired,
    vectors: PropTypes.shape({}),
    interval: PropTypes.shape({}),
    index: PropTypes.number,
    intervals: PropTypes.arrayOf(PropTypes.any),
    sortedIntervals: PropTypes.arrayOf(PropTypes.any),
    convertTimeToX: PropTypes.func
  };

  constructor(props) {
    super(props);

    const { interval, convertTimeToX } = this.props;

    this.state = {
      actualPosition: convertTimeToX(interval.end),
      isHovered: false,
      isGrabbed: false
    };
  }

  formatTime(position) {
    const { convertXToTime } = this.props;
    return moment()
      .minutes(0)
      .seconds(convertXToTime(position) / 100)
      .format('m:ss');
  }

  /**
   * Renders the `add new` interval part
   */
  renderAddNew() {
    const { newInterval } = this.props;
    const { possibleSpeedOptions } = this.props;
    const { handleSpeedChange } = this.props;
    const { actualSpeed } = this.props;

    return (
      <div className="separator-add">
        <form
          onSubmit={e => {
            e.preventDefault();
            newInterval(e);
          }}
        >
          <div className="box">
            <TextField
              select
              onChange={e => {
                handleSpeedChange(e.target.value);
              }}
              fullWidth
              value={actualSpeed}
              InputLabelProps={{
                shrink: true,
                style: { fontSize: '13pt' }
              }}
              name="speed"
              label={i18n.t(`overviewInfo.speed`)}
            >
              {possibleSpeedOptions.map(value => (
                <MenuItem key={value} value={value}>
                  {`${value} km/h`}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <button className="add-interval-button" type="submit">
            {`+ ${i18n.t(`overviewActions.addInterval`)}`}
          </button>
        </form>
      </div>
    );
  }

  renderActualPosition() {
    const { actualPosition, isHovered, isGrabbed } = this.state;
    if (actualPosition && (isHovered || isGrabbed)) {
      return (
        <div className="actual-time-label box">
          <h1>{this.formatTime(actualPosition)}</h1>
        </div>
      );
    }
    return null;
  }

  render() {
    const {
      interval,
      index,
      intervals,
      sortedIntervals,
      getDraggableBounds,
      changeDraggablePosition,
      getDraggablePosition
    } = this.props;

    return (
      <Draggable
        key={interval.id}
        handle=".handle"
        bounds={getDraggableBounds(interval, index - 1)}
        axis="x"
        position={getDraggablePosition(interval)}
        onStop={(e, d) => {
          this.setState({ actualPosition: d.x, isGrabbed: false });
          changeDraggablePosition(e, d, interval, [...intervals], index - 1);
        }}
        onDrag={(e, d) =>
          this.setState({ actualPosition: d.x, isGrabbed: true })
        }
      >
        <div
          className="separator"
          onMouseEnter={() => this.setState({ isHovered: true })}
          onMouseLeave={() => this.setState({ isHovered: false })}
        >
          {this.renderActualPosition()}
          <div className={`${index ? 'handle' : ''} separator-dot`} />
          {index >= sortedIntervals.length - 1 ? this.renderAddNew() : null}
          <div className={`${index ? 'handle' : ''} separator-line`} />
        </div>
      </Draggable>
    );
  }
}

export default OverviewSeparator;
