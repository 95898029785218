import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ExaminationDiagnosisBodyView from './examination-diagnosis-body-view';

class ExaminationDiagnosisBody extends Component {
  static propTypes = {
    anamnesis: PropTypes.shape({
      leftHip: PropTypes.string,
      rightHip: PropTypes.string,
      leftKnee: PropTypes.string,
      rightKnee: PropTypes.string,
      leftFoot: PropTypes.string,
      rightFoot: PropTypes.string,
      pelvis: PropTypes.string
    }),
    onAnamnesisChange: PropTypes.func
  };

  render() {
    const { anamnesis, onAnamnesisChange } = this.props;
    return (
      <ExaminationDiagnosisBodyView
        anamnesis={anamnesis}
        onAnamnesisChange={onAnamnesisChange}
      />
    );
  }
}

export default ExaminationDiagnosisBody;
