import env from '../../config';
import ApiHandlerService from '../../services/api-handler';

export default class ProgressApi {
  static get(variables, token) {
    const id = variables && variables.id;
    const type = variables && variables.type;
    const url = `${env.API_BASE_URL}/progress/${type}/${id}`;
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store'
      },
      method: 'GET'
    };

    return ApiHandlerService.fetch(url, config);
  }
}
