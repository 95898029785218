import { takeLatest, fork, put } from 'redux-saga/effects';
import { Types } from '../../reducers/evaluations';
import {
  Actions as NotificationsActions,
  MessageTypes
} from '../../reducers/notifications';

export function* updated(response) {
  yield put(
    NotificationsActions.showNotification({
      message: response && response.payload && response.payload.message,
      type: MessageTypes.Success,
      flash: true
    })
  );
}

function* evaluationSaga() {
  yield fork(takeLatest, [Types.UPDATE_EVALUATION_SUCCESS], updated);
}

export default evaluationSaga;
