import React, { Component } from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../../i18n';

import '../../../../styles/overview-table.css';

class OverviewTable extends Component {
  static propTypes = {
    vectorData: PropTypes.shape({
      v_length: PropTypes.number,
      s_length: PropTypes.number,
      h_length: PropTypes.number,
      angle_vs_deg: PropTypes.number,
      angle_vh_deg: PropTypes.number,
      angle_sh_deg: PropTypes.number
    })
  };

  render() {
    const { vectorData } = this.props;
    if (!vectorData) return null;

    return (
      <table className="overview-table">
        <thead>
          <tr>
            <th>{i18n.t(`overviewInfo.vLength`)}</th>
            <th>{i18n.t(`overviewInfo.sLength`)}</th>
            <th>{i18n.t(`overviewInfo.hLength`)}</th>
            <th>{i18n.t(`overviewInfo.angleVSdeg`)}</th>
            <th>{i18n.t(`overviewInfo.angleVHdeg`)}</th>
            <th>{i18n.t(`overviewInfo.angleSHdeg`)}</th>
          </tr>
        </thead>
        <tbody>
          <tr key="1">
            <td>{vectorData.v_length && vectorData.v_length.toFixed(2)}</td>
            <td>{vectorData.s_length && vectorData.s_length.toFixed(2)}</td>
            <td>{vectorData.h_length && vectorData.h_length.toFixed(2)}</td>
            <td>
              {vectorData.angle_vs_deg && vectorData.angle_vs_deg.toFixed(2)}
            </td>
            <td>
              {vectorData.angle_vh_deg && vectorData.angle_vh_deg.toFixed(2)}
            </td>
            <td>
              {vectorData.angle_sh_deg && vectorData.angle_sh_deg.toFixed(2)}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

export default OverviewTable;
