import { connect } from 'react-redux';
import Evaluation from './evaluation';
import { getEvaluation, getInterval } from '../../../../reducers/request';

const mapStateToProps = (state, props) => {
  const { intervals, evaluations } = state;
  const { location } = props;

  // extract the interval id from the url
  const regExp = /\/examinations\/(\d*)(\/interval\/(\d*)(\/evaluation\/(\d*))*)*/i;
  const matches = regExp.exec(location.pathname);
  const examinationId = matches && matches.length > 1 && matches[1];
  const intervalId = matches && matches.length > 3 && matches[3];
  const evaluationId = matches && matches.length > 5 && matches[5];

  // extract the evaluation from the list
  const evaluation =
    evaluations &&
    evaluations.evaluations &&
    evaluations.evaluations.find(e => e.id && e.id.toString() === evaluationId);

  const isEvaluationFetching = evaluations && evaluations.isFetching;
  const evaluationErrors = evaluations && evaluations.errors;

  // extract the interval from the list
  const interval =
    intervals &&
    intervals.intervals &&
    intervals.intervals.find(e => e.id && e.id.toString() === intervalId);

  const isIntervalFetching = intervals && intervals.isFetching;
  const intervalErrors = intervals && intervals.errors;

  return {
    interval,
    evaluation,
    isIntervalFetching,
    isEvaluationFetching,
    intervalId,
    evaluationId,
    evaluationErrors,
    intervalErrors,
    examinationId
  };
};

export default connect(
  mapStateToProps,
  { getInterval, getEvaluation }
)(Evaluation);
