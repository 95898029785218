import React, { Component } from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line

import ExaminationsList from '../../components/examination-list';
import NewExamintion from '../../components/modals/new-examination';

import i18n from '../../i18n';

import '../../styles/patient.css';
import EditPatient from '../../components/modals/edit-patient';

export default class PatientView extends Component {
  static propTypes = {
    patient: PropTypes.shape({}),
    isFetching: PropTypes.bool,
    isPatientModalOpen: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
    isExamModalOpen: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
    closePatientModal: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    openPatientModal: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    closeExamModal: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    openExamModal: PropTypes.func.isRequired // eslint-disable-line react/no-unused-prop-types
  };

  renderPatientInfos() {
    const { patient } = this.props;
    const orderOfKeys = [
      'placeOfBirth',
      'dateOfBirth',
      'gender',
      'dominantSide',
      'mothersName',
      'address',
      'email',
      'phone'
    ];

    return orderOfKeys.map(key => {
      if (patient[key] && patient[key] !== '') {
        if (key.toString() === 'dateOfBirth') {
          return (
            <div className="patient-info" key={key}>
              <p>{i18n.t(`patientInfo.${key}`)}</p>
              <h1>{new Date(patient[key]).toLocaleDateString('hu-HU')}</h1>
            </div>
          );
        }
        if (key.toString() === 'gender') {
          return (
            <div className="patient-info" key={key}>
              <p>{i18n.t(`patientInfo.${key}`)}</p>
              <h1>{i18n.t(`patientInfo.genders.${patient[key]}`)}</h1>
            </div>
          );
        }
        if (key.toString() === 'dominantSide') {
          return (
            <div className="patient-info" key={key}>
              <p>{i18n.t(`patientInfo.${key}`)}</p>
              <h1>{i18n.t(`patientInfo.dominantSides.${patient[key]}`)}</h1>
            </div>
          );
        }
        return (
          <div className="patient-info" key={key}>
            <p>{i18n.t(`patientInfo.${key}`)}</p>
            <h1>{patient[key]}</h1>
          </div>
        );
      }
      return null;
    });
  }

  render() {
    const { props } = this;

    const { patient, isFetching } = this.props;

    const patientName = patient && `${patient.lastName} ${patient.firstName}`;
    const examinations = (patient && patient.examinations) || [];

    return (
      <div className="patient">
        <EditPatient
          isOpen={props.isPatientModalOpen}
          closeModal={props.closePatientModal}
          defaults={props.defaultModalValuesPatient || null}
          patientId={patient.id}
        />
        <h1 className="route-title">{i18n.t('navigation.patient')}</h1>

        <div className="head">
          <h1>{patientName}</h1>
          <button
            style={{
              display: 'block'
            }}
            onClick={() => props.openPatientModal()}
            type="button"
          >
            <p>{i18n.t('patientActions.editPatient')}</p>
          </button>
        </div>

        <div className="patient-grid">
          <div className="patient-info-list">
            <h1>{i18n.t('patientInfo.details')}</h1>
            <div className="patient-info-box box">
              {this.renderPatientInfos()}
            </div>
          </div>

          <div className="examinations">
            <h1>{i18n.t('navigation.examinations')}</h1>
            <div className="new-examination">
              <NewExamintion
                patientId={patient.id}
                isOpen={props.isExamModalOpen}
                closeModal={props.closeExamModal}
                defaults={props.defaultModalValuesExam || null}
              />

              <button
                className="btn-zoom"
                onClick={() => props.openExamModal()}
                type="button"
              >
                {i18n.t('examinationActions.newExamination')}
              </button>
            </div>
            <ExaminationsList
              examinations={{ examinations, isFetching }}
              isDisplayPatientName={false}
            />
          </div>
        </div>
      </div>
    );
  }
}
