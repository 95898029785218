/* globals */

import React, { Component } from 'react';
import { Route, Link } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import PropTypes from 'prop-types'; // eslint-disable-line
import moment from 'moment';

import i18n from '../../i18n';

import Overview from '../../components/examination/tabs/overview';
import Interval from '../../components/examination/tabs/interval';
import Evaluation from '../../components/examination/tabs/evaluation';
import Benchmark from '../../components/examination/tabs/benchmark';
import Diagnosis from '../../components/examination/tabs/diagnosis';

import '../../styles/examination.css';
import EditExamination from '../../components/modals/edit-examination';
import ExamStatus from '../../components/modals/exam-status';
import ExaminationSubmenu from '../../components/examination-submenu';
import Confirm from '../../components/modals/confirm/confirm';
import EditPatient from '../../components/modals/edit-patient';

export default class Examination extends Component {
  static propTypes = {
    patient: PropTypes.shape({
      patient: PropTypes.any
    }).isRequired,
    examination: PropTypes.shape({
      examination: PropTypes.shape({
        weight: PropTypes.number,
        height: PropTypes.number,
        date: PropTypes.string,
        examType: PropTypes.string,
        dominantSide: PropTypes.string,
        comment: PropTypes.string,
        hasData: PropTypes.bool,
        leftHip: PropTypes.string,
        rightHip: PropTypes.string,
        leftKnee: PropTypes.string,
        rightKnee: PropTypes.string,
        pelvis: PropTypes.string
      })
    }).isRequired,
    progress: PropTypes.shape({}),
    location: PropTypes.shape({
      pathname: PropTypes.string
    }),
    isRunningExamModalOpen: PropTypes.bool.isRequired,
    defaultModalValues: PropTypes.shape({
      weight: PropTypes.number,
      height: PropTypes.number,
      date: PropTypes.string,
      dominantSide: PropTypes.string,
      examType: PropTypes.string,
      comment: PropTypes.string,
      leftHip: PropTypes.string,
      rightHip: PropTypes.string,
      leftKnee: PropTypes.string,
      rightKnee: PropTypes.string,
      leftFoot: PropTypes.string,
      rightFoot: PropTypes.string,
      pelvis: PropTypes.string
    }).isRequired,
    isEditModalOpen: PropTypes.bool,
    isPaired: PropTypes.bool,
    startExam: PropTypes.func.isRequired,
    stopExam: PropTypes.func.isRequired,
    openExamModal: PropTypes.func.isRequired,
    closeExamModal: PropTypes.func.isRequired,
    openEditModal: PropTypes.func.isRequired,
    closeEditModal: PropTypes.func.isRequired,
    pairDevice: PropTypes.func,
    resendData: PropTypes.func,
    getYears: PropTypes.func.isRequired,
    getProgress: PropTypes.func,
    isConfirmOpen: PropTypes.bool,
    confirmFunction: PropTypes.func,
    closeConfirm: PropTypes.func,
    confirmMessage: PropTypes.string,
    openConfirm: PropTypes.func,
    openPatientModal: PropTypes.func,
    isPatientModalOpen: PropTypes.bool,
    closePatientModal: PropTypes.func,
    defaultModalValuesPatient: PropTypes.shape({})
  };

  renderLiveField(index, field) {
    if (field.type == "TEXT") {
      return (
        <div className="form-element">
          <p>{field.name}</p>
        </div>
      )
    } else if (field.type == "MULTITEXTINPUT") {
      return (
        <div className="form-element">
          <p>{field.name}</p>
          <textarea onChange={(e) => {
            this.props.changeFormValue(index, e.target.value)
            //var lst = this.state.fields;
            //lst[index].value = e.target.value
            //this.setState({ fields: lst });
          }} value={field.value} style={{ fontSize: "12pt", width: "100%", height: 120, border: "1px solid #5CB8BB" }} type="text" name=""></textarea>
        </div>
      )
    } else if (field.type == "TEXTINPUT") {
      return (
        <div className="form-element">
          <p>{field.name} {field.required ? "(*kötelező)": ""}</p>
          <input  style={{ fontSize: "12pt"}} onChange={(e) => {
            this.props.changeFormValue(index, e.target.value)
            //var lst = this.state.field;
            //lst[index].value = e.target.value
            //this.setState({ fields: lst });
          }} value={field.value} type="text" name=""></input>
        </div>
      )
    } else if (field.type == "SELECT") {
      return (
        <div className="form-element">
          <p>{field.name}</p>
          <select style={{ fontSize: "12pt"}} value={field.value} onChange={
            (e) => {
              this.props.changeFormValue(index, e.target.value)
              //var lst = this.state.fields;
              //lst[index].value = e.target.value
              //this.setState({ fields: lst });
            }
          }>
            <option value="">Válasszon</option>
            {
              field.options.map((option, index) => {
                return (<option value={option.name}>{option.name}</option>)
              })
            }
          </select>
        </div>
      )
    } else if (field.type == "CHECK") {
      return (
        <div className="form-element">
          <p>{field.name}</p>
          {
            field.options.map((option, ind) => {
              return (<p>
                <label  style={{ fontSize: "12pt"}} class="container">
                {option.name} 
                <input style={{ width: "auto" }} type="checkbox" checked={option.value} value={index} onClick={(e) => {
                  console.log("opcio", option)

                  this.props.changeFormValue(index, ind, true)
                //var lst = this.state.fields;
                //lst[index].options[ind].value = !lst[index].options[ind].value
                //this.setState({ fields: lst });
              }} /> 
              <span class="checkmark"></span>
              </label></p>)
            })
          }
        </div>
      )
    } else {
      return (
        <div className="form-element">
          <p>Mező neve:</p>
          <input name="name" type="text" value={field.name} onChange={
            (e) => {
              var lst = this.state.fields;
              lst[index].name = e.target.value
              //this.setState({ fields: lst });
            }
          } />
          <p>Mező megjelenítési feltétele:</p>
          <div>
            {this.renderConditions(index, field.conditions)}
            <br />
            <button onClick={() => {
              var lst = this.state.fields;
              lst[index].conditions.push({ "field": "", "value": "", "op": "eq" })
              //this.setState({ fields: lst });
            }}>Új feltétel</button>
          </div>
          <p>Mező típusa:</p>
          <select value={field.type} onChange={
            (e) => {
              //var lst = this.state.fields;
              //lst[index].type = e.target.value
              //this.setState({ fields: lst });
            }
          }>
            <option value="">Válasszon</option>
            <option value="TEXT">Megjegyzés</option>
            <option value="TEXTINPUT">Szöveges beviteli mező</option>
            <option value="SELECT">Lenyíló beviteli mező</option>
            <option value="CHECK">Több opciós beviteli mező</option>
          </select>
          {
            field.type == "SELECT" || field.type == "CHECK" ?
              (
                <div>
                  {this.renderOptions(index, field.options)}
                  <br />
                  <button onClick={() => {
                    var lst = this.state.fields;
                    lst[index].options.push({ "name": "" })
                    //this.setState({ fields: lst });
                  }}>Új opció</button>
                </div>
              )
              : <span></span>
          }
        </div>
      )
    }
  }

  renderData() {
    const { props } = this;
    const {
      patient,
      examination: { examination }
    } = props;

    // const examination =
    //   (props.examination && props.examination.examination) || {};

    if (!examination.hasData) {
      return (
        <React.Fragment>
          <div className="no-data-yet">
            <p className="no-data-p">{i18n.t('examinationInfo.noData')}</p>
          </div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <ExaminationSubmenu />
        <Route exact path="/examinations/:id/overview" component={Overview} />
        <Route
          exact
          path="/examinations/:id/interval/:intervalId"
          component={Interval}
        />
        <Route
          exact
          path="/examinations/:id/interval/:intervalId/benchmark/:evaluationId"
          component={Benchmark}
        />
        <Route
          exact
          path="/examinations/:id/interval/:intervalId/evaluation/:evaluationId"
          component={Evaluation}
        />
        <Route
          exact
          path="/examinations/:id/interval/:intervalId/diagnosis/:evaluationId"
          render={() => <Diagnosis patient={patient} exam={examination} />}
        />
      </React.Fragment>
    );
  }

  renderActionButton() {
    const {
      startExam,
      examination: { examination },
      openConfirm
    } = this.props;

    if (examination.hasData) {
      return (
        <button
          type="button"
          className="btn-large"
          onClick={
            () =>
              // Open the confirm modal => call openConfirm with:
              openConfirm(() => {
                startExam(examination && examination.id); // 1. the function we'd like to call if the modal is confirmed
              }, i18n.t('confirmModal.confirmRecapture')) // 2. the message we'd like to pass as the header text
          }
        >
          <Icon style={{ marginRight: 3, verticalAlign: 'middle' }}>
            autorenew
          </Icon>
          <p className="btn-p-inline">
            {i18n.t('examinationActions.recaptureData')}
          </p>
        </button>
      );
    }

    return (
      <button
        className="btn-large"
        type="button"
        disabled={!this.props.examDetails}
        style={{ background: !this.props.examDetails ? "rgb(167 222 223)" : "#5CB8BB" }}
        onClick={() => startExam(examination && examination.id)}
      >
        <Icon style={{ marginRight: 3, verticalAlign: 'middle' }}>
          play_arrow
        </Icon>
        <p className="btn-p-inline">{i18n.t(`examinationActions.startExam`)}</p>
      </button>
    );
  }

  render() {
    const {
      isRunningExamModalOpen,
      isEditModalOpen,
      defaultModalValues,
      progress,
      closeEditModal,
      closeExamModal,
      stopExam,
      getProgress,
      pairDevice,
      resendData,
      openExamModal,
      openEditModal,
      getYears,
      isPaired,
      isConfirmOpen,
      confirmFunction,
      closeConfirm,
      confirmMessage,
      patient: { patient },
      examination: { examination },
      isPatientModalOpen,
      closePatientModal,
      defaultModalValuesPatient,
      openPatientModal
    } = this.props;
    // const patient = (patient && patient.patient) || {};
    // const examination = (examination && examination.examination) || {};
    const patientName = patient && `${patient.lastName} ${patient.firstName}`;
    // const examinations = (patient && patient.examinations) || [];
    // const isFetching = (examination && examination.isFetching) || false;

    if (!examination || !examination.id)
      return (
        <div className="patient">
          <h1 className="route-title">Examination does not exist.</h1>
        </div>
      );

    return (
      <div className="examination">

        <div style={{ display: this.props.showform ? "block" : "none", zIndex: 1000000, left: 0, position: "fixed", width: "100%", height: "100%", background: "#0006" }}>
            <div style={{ padding: 10, margin: "auto", marginTop: 50, width: "50vw", height: "80vh", overflowY: "scroll", background:"#fff" }}>
            {
            this.props.formData.map((field, index) => {
              console.log(this.props.formData);
              if (field.conditions.length == 0) {
                return this.renderLiveField(index, field)
              } else {
                var show = true;
                console.log(field.conditions)
                field.conditions.map((cond) => {
                  //console.log(this.state.fields[cond.field])
                  //console.log(this.state.fields[cond.field].value)
                  //console.log(cond.op)
                  //console.log(cond.value)
                  if ((
                      (this.props.formData[cond.field].value != cond.value && cond.op == "eq") ||
                      (this.props.formData[cond.field].value == cond.value && cond.op == "neq") ||
                      (this.props.formData[cond.field].value == "" && cond.op == "neq")
                    ) &&
                    !this.props.formData[cond.field].options[cond.value].value
                  ) {
                    show = false;
                  }
                })
                if (show) {
                  return this.renderLiveField(index, field)
                }
              }
            })
          }
          <button
                type="button"
                className="btn-small btn-inline"
                onClick={() => {
                  var submit = true;
                  this.props.formData.forEach(f => {
                    if(f.value == "" && f.required) {
                      submit = false;
                    }
                  });
                  if(submit) {
                    this.props.changeExamDet(true);
                    this.props.changeForm(false);
                  }
                } }
              >
                {i18n.t('datasheet.savesimple')}
              </button>

              <button
                type="button"
                style={{ marginLeft: 10 }}
                className="btn-small btn-inline"
                onClick={() => {;
                    this.props.changeForm(false);
                } }
              >
                {i18n.t('modalActions.cancel')}
              </button>
            </div>
        </div>

        <h1 className="route-title">{i18n.t(`navigation.examination`)}</h1>
        <Confirm
          isConfirmOpen={isConfirmOpen}
          confirmFunction={confirmFunction}
          closeConfirm={closeConfirm}
          confirmMessage={confirmMessage}
        />
        <EditPatient
          isOpen={isPatientModalOpen}
          closeModal={closePatientModal}
          defaults={defaultModalValuesPatient || null}
          patientId={patient.id}
        />
        <EditExamination
          isOpen={isEditModalOpen}
          closeModal={closeEditModal}
          defaults={defaultModalValues}
          examId={examination.id}
          patientId={patient.id}
          patient={patient}
        />
        {isRunningExamModalOpen && (
          <ExamStatus
            examinationId={examination.id}
            isPaired={isPaired}
            progress={progress}
            close={closeExamModal}
            stopExamination={stopExam}
            getProgress={getProgress}
          />
        )}
        <div className="info">
          <div className="patient-info">
            <div className="title-box">
              <h1 className="title title-inline">
                {i18n.t('examinationInfo.patient')}
              </h1>
              <button
                type="button"
                className="btn-small btn-inline"
                onClick={openPatientModal}
              >
                {i18n.t('examinationActions.editPatient')}
              </button>
            </div>
            <div className="box examination-info-list">
              <div className="examination-info-grid">
                <Link to={`/patients/${patient.id}`}>
                  <div className="elem elem-hoverable">
                    <h1>{i18n.t('examinationInfo.patientName')}</h1>
                    <p>{`${patientName}`}</p>
                  </div>
                </Link>
                <div className="elem">
                  <h1>{i18n.t('examinationInfo.age')}</h1>
                  <p>{getYears(patient.dateOfBirth)}</p>
                </div>
                <div className="elem">
                  <h1>{i18n.t('examinationInfo.weight')}</h1>
                  <p>
                    {examination.weight}
                    {' kg'}
                  </p>
                </div>
                <div className="elem">
                  <h1>{i18n.t('examinationInfo.height')}</h1>
                  <p>
                    {examination.height}
                    {' cm'}
                  </p>
                </div>
                <div className="elem">
                  <h1>{i18n.t(`patientInfo.gender`)}</h1>
                  <p>{i18n.t(`patientInfo.genders.${patient.gender}`)}</p>
                </div>
                <div className="elem">
                  <h1>{i18n.t('patientInfo.dominantSide')}</h1>
                  <p>
                    {i18n.t(
                      `patientInfo.dominantSides.${patient.dominantSide}`
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="examination-info">
            <div className="title-box">
              <h1 className="title title-inline">
                {i18n.t('examinationInfo.examinationDetails')}
              </h1>
              <button
                className="btn-small btn-inline"
                onClick={openEditModal}
                type="button"
              >
                <p>{i18n.t('examinationActions.editDetails')}</p>
              </button>
            </div>

            <div className="box examination-info-list">
              <div className="examination-info-grid">
                <div className="elem">
                  <h1>{i18n.t('examinationInfo.dateOfExamination')}</h1>
                  <p>{moment(examination.date).format('YYYY-MM-DD HH:mm')}</p>
                </div>
                <div className="elem">
                  <h1>{i18n.t('examinationInfo.examType')}</h1>
                  <p>
                    {i18n.t(
                      `examinationInfo.examTypes.${examination.examType}`
                    )}
                  </p>
                </div>
                <div className="elem">
                  <h1>{i18n.t(`examinationInfo.comment`)}</h1>
                  <p>{examination.comment}</p>
                </div>
                <div className="elem">
                  <h1>{i18n.t('examinationsList.anamnesis')}</h1>
                  <p>
                    {examination.leftHip ||
                      examination.rightHip ||
                      examination.leftKnee ||
                      examination.rightKnee ||
                      examination.leftFoot ||
                      examination.rightFoot ||
                      examination.pelvis
                      ? null
                      : 'n/a'}
                    {examination.leftHip
                      ? `${i18n.t('diagnosisInfo.left')} ${i18n.t(
                        'diagnosisInfo.hip'
                      )}: ${examination.leftHip} | `
                      : null}
                    {examination.rightHip
                      ? `${i18n.t('diagnosisInfo.right')} ${i18n.t(
                        'diagnosisInfo.hip'
                      )}: ${examination.rightHip} | `
                      : null}
                    {examination.leftKnee
                      ? `${i18n.t('diagnosisInfo.left')} ${i18n.t(
                        'diagnosisInfo.knee'
                      )}: ${examination.leftKnee} | `
                      : null}
                    {examination.rightKnee
                      ? `${i18n.t('diagnosisInfo.right')} ${i18n.t(
                        'diagnosisInfo.knee'
                      )}: ${examination.rightKnee} | `
                      : null}
                    {examination.leftFoot
                      ? `${i18n.t('diagnosisInfo.left')} ${i18n.t(
                        'diagnosisInfo.foot'
                      )}: ${examination.leftFoot} | `
                      : null}
                    {examination.rightFoot
                      ? `${i18n.t('diagnosisInfo.right')} ${i18n.t(
                        'diagnosisInfo.foot'
                      )}: ${examination.rightFoot} | `
                      : null}
                    {examination.pelvis
                      ? `${i18n.t('diagnosisInfo.pelvis')}: ${examination.pelvis
                      }`
                      : null}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="examination-actions">
            <div style={{ marginBottom: 10 }}>

            <div className="sub-actions">

            <button style={{ margin: 10 }} class="btn-small" type="button" onClick={() => 
                this.props.changeForm(true, 6)}
                  
                  >{i18n.t('examinationInfo.openform')}</button>

<button style={{ margin: 10 }} class="btn-small" type="button" onClick={() => this.props.changeForm(true, 7)}
                  
>{i18n.t('examinationInfo.openform2')}</button>


<button style={{ margin: 10 }} class="btn-small" type="button" onClick={() => this.props.changeForm(true, 11)}
                  
                  >{i18n.t('examinationInfo.openform6')}</button>

</div>
<div>

<button style={{ margin: 10 }} class="btn-small" type="button" onClick={() => this.props.changeForm(true, 8)}
                  
                  >{i18n.t('examinationInfo.openform3')}</button>

<button style={{ margin: 10 }} class="btn-small" type="button" onClick={() => this.props.changeForm(true, 10)}
                  
                  >{i18n.t('examinationInfo.openform5')}</button>



<button style={{ margin: 10 }} class="btn-small" type="button" onClick={() => this.props.changeForm(true, 9)}
                  
                  >{i18n.t('examinationInfo.openform4')}</button>

<button style={{ margin: 10 }} class="btn-small" type="button" onClick={() => this.props.changeForm(true, 13)}
                  
                  >{i18n.t('examinationInfo.openform8')}</button>


                  </div>

              <div style={{ width: '50%' }} sclassName="main-actions">
                
                </div>
              
            </div>
            <div className="sub-actions">
            {this.renderActionButton()}

              <button
                className="btn-small"
                type="button"
                onClick={() => pairDevice(examination.id, patientName)}
              >
                {i18n.t('examinationActions.pairDevice')}
              </button>
              <button
                type="button"
                className="btn-small"
                onClick={() => openExamModal()}
              >
                {i18n.t('examinationActions.viewProgress')}
              </button>
              <button
                type="button"
                className="btn-small"
                onClick={() => resendData(examination.id)}
              >
                {i18n.t('examinationActions.resendData')}
              </button>
            </div>
          </div>
        </div>
        <div className="details-wrapper">
          <div className="details">{this.renderData()}</div>
        </div>
        <div>
          

        <div className="hide-print" style={{ width: '100%', borderTop: "3px solid #5CB8BB", paddingTop: 10 }} >
                <p style={{ display: this.props.asanaDone ? "block": "none", marginBottom: 10, color: "#3a3" }} className="btn-p-inline">
                  {i18n.t('examinationActions.remoteMedicalSuccess')}
                </p>
                <button
                  disabled={this.props.asanaLoading || this.props.asanaDone ? true : false}
                  type="button"
                  className="btn-large"
                  style={{ background: this.props.asanaDone ? "rgb(167 222 223)" : "#5CB8BB" }}
                  onClick={
                    () => { 
                      this.props.setAsanaLoading();
                      fetch("https://menstage.kingstudio.hu/api/?dohelpid="+this.props.examId+"&username="+this.props.user.username, {
                        headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                        },
                        method: "GET"
                      })
                      this.props.setAsanaSuccess();
                    }}>
                  <p className="btn-p-inline">
                    {i18n.t('examinationActions.remoteMedical')}
                  </p>
                </button>
                <p style={{ marginTop: 10 }}>{i18n.t('examinationActions.remoteMedicalInfo')}</p>

                <button style={{ marginTop: 10 }} class="btn-large" type="button" onClick={() => this.props.changeForm(true, 12)}
                  
                  >{i18n.t('examinationInfo.openform7')}</button>

              </div>


        </div>
      </div>
    );
  }
}
