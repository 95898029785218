import env from '../../config';
import ApiHandlerService from '../../services/api-handler';

export default class PatientsApi {
  static add(variables, token) {
    const payload = variables && variables.payload;
    const url = `${env.API_BASE_URL}/patients`;
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store'
      },
      body: JSON.stringify({
        payload
      }),
      method: 'POST'
    };

    return ApiHandlerService.fetch(url, config);
  }

  static get(variables, token) {
    const id = variables && variables.id;
    const url = `${env.API_BASE_URL}/patients/${id}`;
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store'
      },
      method: 'GET'
    };

    return ApiHandlerService.fetch(url, config);
  }

  static list(variables, token) {
    const username = variables && variables.username;
    const limit = variables && variables.limit;

    // concat query parameters
    const params = {};
    if (username) params.user_name = username;
    if (limit) params.limit = limit;

    const query = Object.keys(params)
      .map(k => `${k}=${encodeURIComponent(params[k])}`)
      .join('&');

    const url = `${env.API_BASE_URL}/patients?${query}`;

    const config = {
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store'
      },
      method: 'GET'
    };

    return ApiHandlerService.fetch(url, config);
  }

  static update(variables, token) {
    const payload = variables && variables.payload;
    const id = variables && variables.id;
    if (!id) throw Error('Id not specified');
    if (payload.id) {
      delete payload.id;
    }

    const url = `${env.API_BASE_URL}/patients/${id}`;
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store'
      },
      body: JSON.stringify({
        payload
      }),
      method: 'PUT'
    };

    return ApiHandlerService.fetch(url, config);
  }
}
