import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types'; // eslint-disable-line

import PatientsList from '../../components/patient-list';
import ExaminationsList from '../../components/examination-list';
import '../../styles/recent.css';

import i18n from '../../i18n';

export default class Patients extends Component {
  static propTypes = {
    patients: PropTypes.shape({
      isFetching: PropTypes.bool.isRequired,
      patients: PropTypes.arrayOf(PropTypes.any).isRequired
    }),
    examinations: PropTypes.shape({
      isFetching: PropTypes.bool.isRequired,
      examinations: PropTypes.arrayOf(PropTypes.any).isRequired,
      displayCount: PropTypes.number
    })
  };

  render() {
    const { props } = this;
    return (
      <div className="recent">
        <h1 className="route-title">{i18n.t('navigation.recent')}</h1>

        <div className="patients">
          <h1>{i18n.t('navigation.patients')}</h1>
          <PatientsList patients={props.patients} />
          <Link to="/patients">
            <button type="button">{i18n.t('patientsList.morePatients')}</button>
          </Link>
        </div>

        <div className="examinations">
          <h1>{i18n.t('navigation.examinations')}</h1>
          <ExaminationsList
            examinations={props.examinations}
            displayCount={5}
          />
          <Link to="/examinations">
            <button type="button">
              {i18n.t('examinationsList.moreExaminations')}
            </button>
          </Link>
        </div>
      </div>
    );
  }
}
