import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  VictoryChart,
  VictoryLine,
  VictoryAxis,
  VictoryLabel,
  VictoryArea
} from 'victory';

class ChartBenchmark extends Component {
  static propTypes = {
    benchmarkDomain: PropTypes.shape({}),
    borders: PropTypes.arrayOf(PropTypes.any),
    averages: PropTypes.arrayOf(PropTypes.any)
  };

  static renderTicks(t, modulo) {
    if (t % modulo === 0) {
      return `${t}`;
    }
    return '';
  }

  getDividerNumber() {
    const { benchmarkDomain } = this.props;
    if (benchmarkDomain && benchmarkDomain.ticks) {
      let counter = 1;
      while (benchmarkDomain.ticks.length / counter > 14) {
        counter += 2;
      }
      return counter;
    }
    return 1;
  }

  render() {
    const { benchmarkDomain, borders, averages } = this.props;
    const tickDivider = this.getDividerNumber();

    return (
      <VictoryChart
        padding={{ left: 30, top: 15, bottom: 30, right: 20 }}
        height={220}
      >
        <VictoryAxis
          dependentAxis
          tickValues={benchmarkDomain.ticks}
          tickFormat={() => ''}
          style={{
            grid: { stroke: '#d0d0d0' }
          }}
        />
        <VictoryAxis
          style={{
            tickLabels: { fontSize: 5.5 },
            grid: { stroke: '#d0d0d0' }
          }}
          dependentAxis
          orientation="bottom"
          tickValues={[
            0,
            5,
            10,
            15,
            20,
            25,
            30,
            35,
            40,
            45,
            50,
            55,
            60,
            65,
            70,
            75,
            80,
            85,
            90,
            95,
            100
          ]}
          tickFormat={() => ''}
        />
        <VictoryArea
          data={borders}
          style={{
            data: {
              fill: '#C0C0C0',
              fillOpacity: 1,
              strokeWidth: 0
            }
          }}
        />
        <VictoryLine
          style={{ data: { stroke: '#f26f5a', strokeWidth: 2 } }}
          data={averages}
        />
        <VictoryAxis
          style={{ tickLabels: { fontSize: 10 } }}
          dependentAxis
          orientation="bottom"
          tickValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          tickFormat={t => `${t}%`}
          tickLabelComponent={<VictoryLabel y={200} />}
        />
        <VictoryAxis
          dependentAxis
          tickValues={benchmarkDomain.ticks}
          tickFormat={t => `${ChartBenchmark.renderTicks(t, tickDivider)}`}
          style={{
            tickLabels: { fontSize: 10 }
          }}
        />
      </VictoryChart>
    );
  }
}

export default ChartBenchmark;
