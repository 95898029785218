import React, { Component } from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line
import { Link } from 'react-router-dom';
import { TextField, MenuItem } from '@material-ui/core';
import OverviewChart from './overview-chart';
import OverviewTable from './overview-table';
import Loader from '../../../loader';

import i18n from '../../../../i18n';
import OverviewSeparator from './overview-separator';

class OverviewView extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.any),
    intervals: PropTypes.arrayOf(
      PropTypes.shape({
        data: PropTypes.string,
        end: PropTypes.number.isRequired,
        examinationId: PropTypes.number,
        ftEnd: PropTypes.number,
        ftStart: PropTypes.number,
        id: PropTypes.number,
        movingAverage: PropTypes.number,
        speed: PropTypes.number.isRequired,
        start: PropTypes.number.isRequired,
        name: PropTypes.string
      })
    ).isRequired,
    convertTimeToX: PropTypes.func.isRequired,
    changeDraggablePosition: PropTypes.func.isRequired,
    deleteInterval: PropTypes.func.isRequired,
    newInterval: PropTypes.func.isRequired,
    tickFormatter: PropTypes.func.isRequired,
    ticks: PropTypes.arrayOf(PropTypes.number),
    getInterval: PropTypes.func.isRequired,
    getIntervalName: PropTypes.func.isRequired,
    getDraggableBounds: PropTypes.func.isRequired,
    getDraggablePosition: PropTypes.func.isRequired,
    possibleSpeedOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
    handleSpeedChange: PropTypes.func.isRequired,
    actualSpeed: PropTypes.number.isRequired,
    vectors: PropTypes.shape({}),
    isIntervalsFetching: PropTypes.bool,
    convertXToTime: PropTypes.func
  };

  /**
   * Renders the separator markers
   */
  renderSeparators() {
    const {
      intervals,
      data,
      changeDraggablePosition,
      getDraggableBounds,
      getDraggablePosition,
      newInterval,
      possibleSpeedOptions,
      handleSpeedChange,
      actualSpeed,
      convertXToTime,
      convertTimeToX
    } = this.props;

    if (!data || !intervals || intervals.length === 0) {
      return this.renderAddNew();
    }

    // sort the intervals by the end time
    const sortedIntervals = [...intervals].sort((a, b) => a.end - b.end);

    sortedIntervals.unshift({ id: -1, start: 0, end: 0, speed: 0 });

    return (
      sortedIntervals &&
      sortedIntervals.map((interval, index) => (
        <OverviewSeparator
          key={interval.id}
          interval={interval}
          index={index}
          intervals={intervals}
          sortedIntervals={sortedIntervals}
          changeDraggablePosition={changeDraggablePosition}
          getDraggableBounds={getDraggableBounds}
          getDraggablePosition={getDraggablePosition}
          renderAddNew={this.renderAddNew}
          newInterval={newInterval}
          possibleSpeedOptions={possibleSpeedOptions}
          handleSpeedChange={handleSpeedChange}
          actualSpeed={actualSpeed}
          convertXToTime={convertXToTime}
          convertTimeToX={convertTimeToX}
        />
      ))
    );
  }

  /**
   * Renders the separator marker labels
   */
  renderLabels() {
    const {
      intervals,
      deleteInterval,
      convertTimeToX,
      getInterval,
      getIntervalName
    } = this.props;

    if (!intervals || intervals.length === 0) {
      return null;
    }

    // sort the intervals by the end time
    const sortedIntervals = [...intervals].sort((a, b) => a.end - b.end);

    return sortedIntervals.map((interval, index) => {
      const left = convertTimeToX(interval.start);
      const width = convertTimeToX(interval.end - interval.start);

      // hide zero speed interval labels
      if (!interval || interval.speed === 0) return null;

      return (
        <div className="label" style={{ left, width }} key={interval.id}>
          <p className="title">
            <Link
              to={`interval/${interval.id}`}
              onClick={() => {
                getInterval(interval.id);
              }}
            >
              {getIntervalName(interval)}
            </Link>
          </p>
          <p
            className="delete"
            role="presentation"
            type="button"
            onClick={() =>
              {
                if (window.confirm("Biztosan törölni szeretné?")) {
                  deleteInterval(interval.id, interval, index, sortedIntervals)
                }
              }
            }
          >
            {i18n.t(`overviewActions.deleteInterval`)}
          </p>
        </div>
      );
    });
  }

  /**
   * Renders the `add new` interval part
   */
  renderAddNew() {
    const { newInterval } = this.props;
    const { possibleSpeedOptions } = this.props;
    const { handleSpeedChange } = this.props;
    const { actualSpeed } = this.props;

    return (
      <div className="separator-add">
        <form
          onSubmit={e => {
            e.preventDefault();
            newInterval(e);
          }}
        >
          <div className="box">
            <TextField
              select
              onChange={e => {
                handleSpeedChange(e.target.value);
              }}
              fullWidth
              value={actualSpeed}
              InputLabelProps={{
                shrink: true,
                style: { fontSize: '13pt' }
              }}
              name="speed"
              label={i18n.t(`overviewInfo.speed`)}
            >
              {possibleSpeedOptions.map(value => (
                <MenuItem key={value} value={value}>
                  {`${value} km/h`}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <button className="add-interval-button" type="submit">
            {`+ ${i18n.t(`overviewActions.addInterval`)}`}
          </button>
        </form>
      </div>
    );
  }

  /**
   * Renders the vectors
   */
  renderVectors() {
    const { vectors } = this.props;

    return (
      <div>
        <div className="titleBox">
          <h1 className="title">
            {i18n.t(`overviewInfo.baseTransformationParameters`)}
          </h1>
        </div>
        <OverviewTable vectorData={vectors} />
      </div>
    );
  }

  /**
   * Renders the chart
   */
  renderChart() {
    const { data, tickFormatter, ticks } = this.props;
    let chart;

    // do not display the chart if no data
    if (data) {
      chart = (
        <OverviewChart
          data={data}
          ticks={ticks}
          tickFormatter={tickFormatter}
        />
      );
    }

    return chart;
  }

  renderLoadingOverlay() {
    const { isIntervalsFetching } = this.props;
    if (isIntervalsFetching) {
      return (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: '999',
            background: 'rgba(240,240,240,0.8)'
          }}
        >
          <Loader message={i18n.t(`loadingInfo.loadingIntervals`)} />
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <div className="overview">
        <div className="vectors">{this.renderVectors()}</div>
        <div className="chart">
          {this.renderLoadingOverlay()}
          <div className="intervals">
            {this.renderSeparators()}
            {this.renderLabels()}
          </div>
          {this.renderChart()}
        </div>
      </div>
    );
  }
}

export default OverviewView;
