import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ExamStatusView from './exam-status-view';
import i18n from '../../../i18n';

// @TODO: read from env params
// @TODO: exit after 10 minutes
// 10 minutes timeout
// const EXAM_WAIT_TIMEOUT = 10;

class ExamStatus extends Component {
  static propTypes = {
    examinationId: PropTypes.number.isRequired,
    progress: PropTypes.shape({}),
    close: PropTypes.func.isRequired,
    stopExamination: PropTypes.func.isRequired,
    isPaired: PropTypes.bool,
    getProgress: PropTypes.func
  };

  static getMessageClass(status) {
    switch (status) {
      case 'loading':
      case 'starting':
      case 'stopping':
      case 'stopped':
        return 'message-info';
      case 'started':
      case 'completed':
        return 'message-good';
      case 'disconnected':
        return 'message-warning';
      case 'nodata':
      case 'failed':
      case 'noExamInProgress':
      case 'failedToStop':
        return 'message-error';
      default:
        return '';
    }
  }

  constructor(props) {
    super(props);
    this.getElapsedTime = this.getElapsedTime.bind(this);
    this.state = {
      elapsedTime: this.getElapsedTime()
    };
  }

  componentDidMount() {
    const { getProgress, examinationId } = this.props;

    // check progress every 3 secs
    this.getProgressLooper = setInterval(() => {
      getProgress(examinationId, 'examination');
    }, 3000);

    // timer - not required if the progress is checked
    // at the same frequency, but leaving this in the code
    // allows the progress check frequency to be modified
    this.elapsedTimeLooper = setInterval(() => {
      this.setState({
        elapsedTime: this.getElapsedTime()
      });
    }, 1000);
  }

  componentWillUnmount() {
    // stop checking the progress
    clearInterval(this.getProgressLooper);
    // stop timer
    clearInterval(this.elapsedTimeLooper);
  }

  getMessage(status) {
    const { progress } = this.props;
    const message = progress && progress.message;
    if (message === 'stopExamination' || message === 'getExaminationData') {
      return i18n.t(`backendResponse.${message}`);
    }
    return message
      ? `${i18n.t(`examStatusInfo.${status}`)} (${i18n.t(
          `backendResponse.${message}`
        )})`
      : i18n.t(`examStatusInfo.${status}`);
  }

  /**
   * Calculates the elapsed time.
   *
   * @returns The elapsed time
   * @memberof ExamStatus
   */
  getElapsedTime() {
    const { progress } = this.props;
    if (!progress || !progress.createdAt) return '00:00:00';

    const startTime = Math.floor(new Date(progress.createdAt).getTime());
    const now = Math.floor(new Date().getTime());
    const timeDiff = now - startTime;

    const msToHMS = ms => {
      // convert to seconds:
      let seconds = parseInt(ms / 1000, 10);
      // extract hours:
      const hours = parseInt(seconds / 3600, 10); // 3,600 seconds in 1 hour
      seconds %= 3600; // seconds remaining after extracting hours
      // extract minutes:
      const minutes = parseInt(seconds / 60, 10); // 60 seconds in 1 minute
      // keep only seconds not extracted to minutes:
      seconds %= 60;
      return `${hours
        .toString()
        .padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    return msToHMS(timeDiff);
  }

  render() {
    const {
      examinationId,
      isPaired,
      close,
      stopExamination,
      progress
    } = this.props;
    const { elapsedTime } = this.state;
    const status = (progress && progress.status) || 'loading';

    if (
      progress &&
      (progress.status === 'failed' ||
        progress.status === 'completed' ||
        progress.status === 'noExamInProgress' ||
        progress.status === 'nodata')
    ) {
      // stop checking progress
      clearInterval(this.getProgressLooper);

      // stop timer
      clearInterval(this.elapsedTimeLooper);
    }

    return (
      <React.Fragment>
        <ExamStatusView
          examinationId={examinationId}
          status={status}
          message={this.getMessage(status)}
          messageClass={ExamStatus.getMessageClass(status)}
          elapsedTime={elapsedTime}
          isPaired={isPaired}
          isMobileOnline={progress && progress.isOnline}
          stopExamination={stopExamination}
          close={close}
        />
      </React.Fragment>
    );
  }
}

export default ExamStatus;
