import { REQUEST } from '../request';

const CLEAN_BENCHMARK = 'CLEAN_BENCHMARK';
const GET_BENCHMARK_SUCCESS = 'GET_BENCHMARK_SUCCESS';
const GET_BENCHMARK_FAILED = 'GET_BENCHMARK_FAILED';

export const Types = {
  CLEAN_BENCHMARK,
  GET_BENCHMARK_SUCCESS,
  GET_BENCHMARK_FAILED,
  REQUEST
};

export const initialState = {
  isFetching: false,
  benchmark: {},
  errors: null
};

const benchmark = (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        isFetching:
          (action.payload && /BENCHMARK/.test(action.payload.action)) ||
          state.isFetching,
        errors:
          action.payload && /BENCHMARK/.test(action.payload.action)
            ? null
            : state.errors
      };

    case GET_BENCHMARK_SUCCESS:
      return {
        ...state,
        isFetching: false,
        benchmark: (action && action.payload && action.payload.data) || {},
        errors: null
      };

    case GET_BENCHMARK_FAILED:
      return {
        ...state,
        isFetching: false,
        errors: action.payload
      };

    case 'FLUSH':
    case CLEAN_BENCHMARK:
      return initialState;
    default:
      return state;
  }
};

export const cleanBenchmark = () => ({
  type: CLEAN_BENCHMARK
});

export const Actions = { cleanBenchmark };

export default benchmark;
