import React, { Component } from 'react';
import PropTypes from 'prop-types';

import '../../../../styles/diagnosis-table.css';

class DiagnosisTable extends Component {
  static propTypes = {
    parameters: PropTypes.shape({})
  };

  /**
   *
   * Returns a piece of the table, like `left leg V` in the proper format
   *
   * @param {*} rangeAsParam The range which will be checked
   * @param {*} value The value of the exact parameter
   */
  static insertPieceOfTable(range, value) {
    const piece = { ref: '', value: value && value.toFixed(2), diff: '' };
    // Check if range is defined and place [+] or [-] to diff
    // Otherwise place n/a to range
    if (!range || !range.min || !range.max) {
      piece.ref = 'n/a';
    } else {
      piece.ref = `${range.min.toFixed(2)} - ${range.max.toFixed(2)}`;
      if (value > range.max) {
        piece.diff = '[+]';
      }
      if (value < range.min) {
        piece.diff = '[-]';
      }
    }

    return piece;
  }

  /**
   *
   * Returns the significance between the left and right side
   *
   * @param {*} leftValue The value of the left side
   * @param {*} rightValue The value of the right side
   */
     static insertLeftRightSignificance(leftValue, rightValue) {
      // Check if the quotient of the left and right value is outside the [0.8..1.2] inverval
      // and place * to diff
      // Otherwise place "" to diff
      let diff = "";
      if (leftValue && rightValue) {
        if(Math.abs(leftValue / rightValue) < 0.8 || Math.abs(leftValue / rightValue) > 1.2) {
          diff = '*';
        }
      }
  
      return diff;
    }

  /**
   *
   * Transforms the parameters from API to a format that the DiagnosisTable component can render
   *
   */
  parametersToRows() {
    // If parameters not found, return empty list
    const { parameters } = this.props;
    if (!parameters) {
      return [];
    }
    // The list of rows needs to be rendered
    // @TODO: Replace the undefined values in method calls with proper range data from backend
    // Range should look like: {max: number, min: number}
    const rows = [
      {
        label: 'lépéshossz átlag',
        diff: DiagnosisTable.insertLeftRightSignificance(parameters.left_step_time,parameters.right_step_time),
        dim: '[mp]',
        left: DiagnosisTable.insertPieceOfTable(
          parameters.left_step_time_range,
          parameters.left_step_time
        ),
        right: DiagnosisTable.insertPieceOfTable(
          parameters.right_step_time_range,
          parameters.right_step_time
        )
      },
      {
        label: 'lépéshossz',
        diff: DiagnosisTable.insertLeftRightSignificance(parameters.left_step_cm,parameters.right_step_cm),
        dim: '[cm]',
        left: DiagnosisTable.insertPieceOfTable(
          parameters.left_step_cm_range,
          parameters.left_step_cm
        ),
        right: DiagnosisTable.insertPieceOfTable(
          parameters.right_step_cm_range,
          parameters.right_step_cm
        )
      },
      {
        label: 'relatív lépéshossz',
        diff: DiagnosisTable.insertLeftRightSignificance(parameters.left_relative_perc,parameters.right_relative_perc),
        dim: '[%]',
        left: DiagnosisTable.insertPieceOfTable(
          parameters.left_relative_perc_range,
          parameters.left_relative_perc
        ),
        right: DiagnosisTable.insertPieceOfTable(
          parameters.right_relative_perc_range,
          parameters.right_relative_perc
        )
      },
      {
        label: 'lépéshossz relatív szórás',
        diff: DiagnosisTable.insertLeftRightSignificance(parameters.left_step_relative_std,parameters.right_step_relative_std),
        dim: '-',
        left: DiagnosisTable.insertPieceOfTable(
          parameters.left_step_relative_std_range,
          parameters.left_step_relative_std
        ),
        right: DiagnosisTable.insertPieceOfTable(
          parameters.right_step_relative_std_range,
          parameters.right_step_relative_std
        )
      },
      {
        breaker: true,
        label: 'Erők járás során'
      },
      {
        label: 'V max',
        diff: DiagnosisTable.insertLeftRightSignificance(parameters.left_v_max,parameters.right_v_max),
        dim: '[N/kg]',
        left: DiagnosisTable.insertPieceOfTable(
          parameters.left_v_max_range,
          parameters.left_v_max
        ),
        right: DiagnosisTable.insertPieceOfTable(
          parameters.right_v_max_range,
          parameters.right_v_max
        )
      },
      {
        label: 'V min',
        diff: DiagnosisTable.insertLeftRightSignificance(parameters.left_v_min,parameters.right_v_min),
        dim: '[N/kg]',
        left: DiagnosisTable.insertPieceOfTable(
          parameters.left_v_min_range,
          parameters.left_v_min
        ),
        right: DiagnosisTable.insertPieceOfTable(
          parameters.right_v_min_range,
          parameters.right_v_min
        )
      },
      {
        label: 'S (propulsio) max',
        diff: DiagnosisTable.insertLeftRightSignificance(parameters.left_s_max,parameters.right_s_max),
        dim: '[N/kg]',
        left: DiagnosisTable.insertPieceOfTable(
          parameters.left_s_max_range,
          parameters.left_s_max
        ),
        right: DiagnosisTable.insertPieceOfTable(
          parameters.right_s_max_range,
          parameters.right_s_max
        )
      },
      {
        label: 'S (talajfogás) min',
        diff: DiagnosisTable.insertLeftRightSignificance(parameters.left_s_min,parameters.right_s_min),
        dim: '[N/kg]',
        left: DiagnosisTable.insertPieceOfTable(
          parameters.left_s_min_range,
          parameters.left_s_min
        ),
        right: DiagnosisTable.insertPieceOfTable(
          parameters.right_s_min_range,
          parameters.right_s_min
        )
      },
      {
        label: 'H lateral max',
        diff: DiagnosisTable.insertLeftRightSignificance(parameters.left_h_max,parameters.right_v_max),
        dim: '[N/kg]',
        left: DiagnosisTable.insertPieceOfTable(
          parameters.left_h_max_range,
          parameters.left_h_max
        ),
        right: DiagnosisTable.insertPieceOfTable(
          parameters.right_h_max_range,
          parameters.right_v_max
        )
      },
      {
        label: 'H medial max',
        diff: DiagnosisTable.insertLeftRightSignificance(parameters.left_h_min,parameters.right_h_min),
        dim: '[N/kg]',
        left: DiagnosisTable.insertPieceOfTable(
          parameters.left_h_min_range,
          parameters.left_h_min
        ),
        right: DiagnosisTable.insertPieceOfTable(
          parameters.right_h_min_range,
          parameters.right_h_min
        )
      },
      {
        label: 'S / V arány',
        diff: DiagnosisTable.insertLeftRightSignificance(parameters.left_s_div_v,parameters.right_s_div_v),
        dim: '[%]',
        left: DiagnosisTable.insertPieceOfTable(
          parameters.left_s_div_v_range,
          parameters.left_s_div_v
        ),
        right: DiagnosisTable.insertPieceOfTable(
          parameters.right_s_div_v_range,
          parameters.right_s_div_v
        )
      },
      {
        label: 'H / S arány',
        diff: DiagnosisTable.insertLeftRightSignificance(parameters.left_h_div_s,parameters.right_h_div_s),
        dim: '[%]',
        left: DiagnosisTable.insertPieceOfTable(
          parameters.left_h_div_s_range,
          parameters.left_h_div_s
        ),
        right: DiagnosisTable.insertPieceOfTable(
          parameters.right_h_div_s_range,
          parameters.right_h_div_s
        )
      },
      {
        breaker: true,
        label: ' '
      },
      {
        label: 'Mozgás energia (V)',
        diff: DiagnosisTable.insertLeftRightSignificance(parameters.left_work_v,parameters.right_work_v),
        dim: '[J]',
        left: DiagnosisTable.insertPieceOfTable(
          parameters.left_work_v_range,
          parameters.left_work_v
        ),
        right: DiagnosisTable.insertPieceOfTable(
          parameters.right_work_v_range,
          parameters.right_work_v
        )
      },
      {
        label: 'Mozgás energia (S)',
        diff: DiagnosisTable.insertLeftRightSignificance(parameters.left_work_s,parameters.right_work_s),
        dim: '[J]',
        left: DiagnosisTable.insertPieceOfTable(
          parameters.left_work_s_range,
          parameters.left_work_s
        ),
        right: DiagnosisTable.insertPieceOfTable(
          parameters.right_work_s_range,
          parameters.right_work_s
        )
      },
      {
        label: 'Mozgás energia (H)',
        diff: DiagnosisTable.insertLeftRightSignificance(parameters.left_work_h,parameters.right_work_h),
        dim: '[J]',
        left: DiagnosisTable.insertPieceOfTable(
          parameters.left_work_h_range,
          parameters.left_work_h
        ),
        right: DiagnosisTable.insertPieceOfTable(
          parameters.right_work_h_range,
          parameters.right_work_h
        )
      },
      {
        label: 'Mozgás energia (total)',
        diff: DiagnosisTable.insertLeftRightSignificance(parameters.left_work_total,parameters.right_work_total),
        dim: '[J]',
        left: DiagnosisTable.insertPieceOfTable(
          parameters.left_work_total_range,
          parameters.left_work_total
        ),
        right: DiagnosisTable.insertPieceOfTable(
          parameters.right_work_total_range,
          parameters.right_work_total
        )
      },
      {
        label: 'Mozgási energia megoszlása a két láb között',
        diff: DiagnosisTable.insertLeftRightSignificance(parameters.kinetic_l,parameters.kinetic_r),
        dim: '[%]',
        left: DiagnosisTable.insertPieceOfTable(
          parameters.kinetic_l_range,
          parameters.kinetic_l
        ),
        right: DiagnosisTable.insertPieceOfTable(
          parameters.kinetic_r_range,
          parameters.kinetic_r
        )
      }
    ];

    return rows;
  }

  /**
   * Rendering the rows with mapping through the list comes from the method `parametersToRows()`
   */
  tableRows() {
    const rows = this.parametersToRows();
    return rows.map(row => {
      if (row.breaker) {
        return (
          <tr key={row.label}>
            <td colSpan="9" className="breaker">
              {row.label}
            </td>
          </tr>
        );
      }
      return (
        <tr key={row.label}>
          <td className="label">{row.label}</td>
          <td style={{color:"red", fontSize: 15}}><b>{row.diff}</b></td>
          <td>{row.dim}</td>
          <td>{row.left.ref}</td>
          <td>{row.left.value}</td>
          <td>{row.left.diff}</td>
          <td>{row.right.ref}</td>
          <td>{row.right.value}</td>
          <td>{row.right.diff}</td>
        </tr>
      );
    });
  }

  render() {
    return (
      <table className="diagnosis-table">
        <tbody>
          <tr className="header-row">
            <td>Lépésciklus</td>
            <td />
            <td />
            <td colSpan="3">Bal oldal</td>
            <td colSpan="3">Jobb oldal</td>
          </tr>
          <tr className="header-row">
            <td />
            <td>diff</td>
            <td>dim</td>
            <td>Referencia tartomány</td>
            <td />
            <td />
            <td>Referencia tartomány</td>
            <td />
            <td />
          </tr>
          {this.tableRows()}
        </tbody>
      </table>
    );
  }
}

export default DiagnosisTable;
