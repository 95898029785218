import { combineReducers } from 'redux';
import app from './app';
import auth from './auth';
import evaluations from './evaluations';
import examinations from './examinations';
import intervals from './intervals';
import patients from './patients';
import benchmark from './benchmark';
import print from './print';

export default combineReducers({
  app,
  auth,
  examinations,
  intervals,
  evaluations,
  patients,
  benchmark,
  print
});
