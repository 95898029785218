import React, { Component } from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line
import RecentView from './recent-view';

class Recent extends Component {
  static propTypes = {
    getPatients: PropTypes.func.isRequired,
    getAllExaminations: PropTypes.func.isRequired
  };

  async componentDidMount() {
    const { getPatients, getAllExaminations } = this.props;
    getPatients();
    getAllExaminations();
  }

  render() {
    const { props } = this;
    return (
      <RecentView patients={props.patients} examinations={props.examinations} />
    );
  }
}

export default Recent;
