/* globals fetch */
export const ResponseTypes = {
  json: 'json',
  blob: 'blob'
};

export default class ApiHandler {
  static getData(text, status) {
    let data;
    if (!text) {
      return {};
    }

    try {
      data = JSON.parse(text.replace(/\bNaN\b/g, "null"));
    } catch {
      // HTML error page returned
      data = {
        status: 'fail',
        message: `(${status}) Server error`
      };
    }

    return data;
  }

  static async fetch(url, config, responseType = ResponseTypes.json) {
    const res = await fetch(url, config);

    if (res && res.ok) {
      switch (responseType) {
        case ResponseTypes.blob:
          return res.blob().then(blob => ({
            ok: !!res.ok,
            status: res.status,
            headers: res.headers ? res.headers.map : {},
            data: blob
          }));
        default:
          return res.text().then(text => ({
            ok: !!res.ok,
            status: res.status,
            headers: res.headers ? res.headers.map : {},
            data: ApiHandler.getData(text, res.status)
          }));
      }
    }

    // request failed
    return (
      (res &&
        res.text().then(text => ({
          ok: !!res.ok,
          status: res.status,
          headers: res.headers ? res.headers.map : {},
          data: ApiHandler.getData(text, res.status)
        }))) ||
      {}
    );
  }
}
