/* global */
// import { toast } from 'react-toastify';

import env from '../../config';
import ApiHandlerService from '../../services/api-handler';
import Utils from '../../services/utils';

export default class EvaluationsApi {
  static add(variables, token) {
    const payload = variables && variables.payload;
    const url = `${env.API_BASE_URL}/evaluations`;
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store'
      },
      body: JSON.stringify({
        payload
      }),
      method: 'POST'
    };

    return ApiHandlerService.fetch(url, config);
  }

  static get(variables, token) {
    const id = variables && variables.id;
    const url = `${env.API_BASE_URL}/evaluations/${id}`;
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store'
      },
      method: 'GET'
    };

    return ApiHandlerService.fetch(url, config);
  }

  /**
   * Requests all the data required for printing a report.
   *
   * @static
   * @param {object} variables Action variables
   * @param {string} token Auth token for authentication on backend
   * @returns Async fetch request
   * @memberof EvaluationsApi
   */
  static getPrintData(variables, token) {
    const id = variables && variables.id;

    // handle backend printing with token in querystring
    const tokenAnywhere = token || (variables && variables.token);

    // unique url if printing from backend => required in Docker environment
    let url = `${env.API_BASE_URL}/evaluations/print/${id}`;
    const isPrinting = Utils.isPDFPrinting();
    if (isPrinting) {
      url = `${env.API_BASE_URL_INTERNAL}/evaluations/print/${id}`;
    }

    const config = {
      headers: {
        authorization: `Bearer ${tokenAnywhere}`,
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store'
      },
      method: 'GET'
    };

    return ApiHandlerService.fetch(url, config);
  }

  static list(variables, token) {
    const intervalId = variables && variables.intervalId;
    const url = `${env.API_BASE_URL}/evaluations?interval_id=${intervalId}`;
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store'
      },
      method: 'GET'
    };

    return ApiHandlerService.fetch(url, config);
  }

  static update(variables, token) {
    const payload = variables && variables.payload;
    const id = payload && payload.id;
    if (!id) throw Error('Id not specified in payload');
    if (payload.id) {
      delete payload.id;
    }

    // Do not send results back to backend
    if (payload.results) {
      delete payload.results;
    }

    // Do not send results back to backend
    if (payload.avgCurves) {
      delete payload.avgCurves;
    }

    const url = `${env.API_BASE_URL}/evaluations/${id}`;
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store'
      },
      body: JSON.stringify({
        payload
      }),
      method: 'PUT'
    };

    return ApiHandlerService.fetch(url, config);
  }

  static start(variables, token) {
    const payload = variables && variables.payload;
    const url = `${env.API_BASE_URL}/evaluations/start`;
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store'
      },
      body: JSON.stringify({
        payload
      }),
      method: 'POST'
    };

    return ApiHandlerService.fetch(url, config);
  }
}
