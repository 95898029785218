import React, { Component } from 'react';
import PropTypes from 'prop-types';

import i18n from '../../i18n';

import '../../styles/examinations.css';
import ExaminationList from '../../components/examination-list';

export default class ExaminationsView extends Component {
  static propTypes = {
    examinations: PropTypes.shape({
      isFetching: PropTypes.bool.isRequired,
      examinations: PropTypes.arrayOf(PropTypes.any).isRequired
    }),
    searchedExaminations: PropTypes.arrayOf(PropTypes.any).isRequired,
    search: PropTypes.shape({
      show: PropTypes.string
    }),
    searchChanged: PropTypes.func.isRequired,
    closeSearch: PropTypes.func.isRequired
  };

  renderSearchExaminations() {
    const { searchedExaminations } = this.props;
    return (
      <ExaminationList
        examinations={{ examinations: searchedExaminations, isFetching: false }}
      />
    );
  }

  render() {
    const { search, searchChanged, closeSearch, examinations } = this.props;
    return (
      <div className="examinations">
        <div className="search">
          <h1>{i18n.t('searchInfo.searchForExaminations')}</h1>
          <div className="search-fields">
            <div className="search-field">
              <h1>{i18n.t('searchInfo.byName')}</h1>
              <div className="search-field-input box">
                <input
                  name="name"
                  type="text"
                  placeholder={i18n.t('searchPlaceHolder.name')}
                  onChange={e => searchChanged(e)}
                />
              </div>
            </div>
            <div className="search-field">
              <h1>{i18n.t('searchInfo.byExamComment')}</h1>
              <div className="search-field-input box">
                <input
                  name="comment"
                  type="text"
                  placeholder={i18n.t('searchPlaceHolder.comment')}
                  onChange={e => searchChanged(e)}
                />
              </div>
            </div>
            <div className="search-field">
              <h1>{i18n.t('searchInfo.byExamDate')}</h1>
              <div className="search-field-input box">
                <input
                  name="date"
                  type="text"
                  placeholder="2018-02"
                  onChange={e => searchChanged(e)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={`search-results ${search.show}`}>
          <h1>{i18n.t('searchInfo.results')}</h1>
          {this.renderSearchExaminations()}
          <button
            type="button"
            className="btn-red close-search"
            onClick={() => closeSearch()}
          >
            {i18n.t('searchActions.close')}
          </button>
        </div>

        <h1 className="route-title">{i18n.t('navigation.examinations')}</h1>

        <ExaminationList examinations={examinations} />
      </div>
    );
  }
}
