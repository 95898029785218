import { REQUEST } from '../request';

const CLEAN_INTERVAL = 'CLEAN_INTERVAL';
const ADD_INTERVAL_SUCCESS = 'ADD_INTERVAL_SUCCESS';
const DELETE_INTERVAL_SUCCESS = 'DELETE_INTERVAL_SUCCESS';
const GET_INTERVAL_SUCCESS = 'GET_INTERVAL_SUCCESS';
const GET_INTERVAL_CYCLES_SUCCESS = 'GET_INTERVAL_CYCLES_SUCCESS';
const LIST_INTERVALS_SUCCESS = 'LIST_INTERVALS_SUCCESS';
const UPDATE_INTERVAL_SUCCESS = 'UPDATE_INTERVAL_SUCCESS';
const ADD_INTERVAL_FAILED = 'ADD_INTERVAL_FAILED';
const DELETE_INTERVAL_FAILED = 'DELETE_INTERVAL_FAILED';
const GET_INTERVAL_FAILED = 'GET_INTERVAL_FAILED';
const GET_INTERVAL_CYCLES_FAILED = 'GET_INTERVAL_CYCLES_FAILED';
const LIST_INTERVALS_FAILED = 'LIST_INTERVALS_FAILED';
const UPDATE_INTERVAL_FAILED = 'UPDATE_INTERVAL_FAILED';

export const Types = {
  CLEAN_INTERVAL,
  ADD_INTERVAL_SUCCESS,
  DELETE_INTERVAL_SUCCESS,
  GET_INTERVAL_SUCCESS,
  GET_INTERVAL_CYCLES_SUCCESS,
  LIST_INTERVALS_SUCCESS,
  UPDATE_INTERVAL_SUCCESS,
  ADD_INTERVAL_FAILED,
  DELETE_INTERVAL_FAILED,
  GET_INTERVAL_FAILED,
  GET_INTERVAL_CYCLES_FAILED,
  LIST_INTERVALS_FAILED,
  UPDATE_INTERVAL_FAILED,
  REQUEST
};

export const initialState = {
  isFetching: false,
  intervals: [],
  errors: null
};

const intervals = (state = initialState, action) => {
  if (!action) return state;

  let id;
  let found;
  let interval;
  let intervalsUpdated;

  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        isFetching:
          (action.payload && /INTERVAL/.test(action.payload.action)) ||
          state.isFetching,
        errors:
          action.payload && /INTERVAL/.test(action.payload.action)
            ? null
            : state.errors
      };

    case LIST_INTERVALS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        intervals:
          (action.payload &&
            action.payload.data &&
            action.payload.data.intervals) ||
          [],
        errors: null
      };

    case ADD_INTERVAL_SUCCESS:
    case UPDATE_INTERVAL_SUCCESS:
    case GET_INTERVAL_SUCCESS:
      id =
        action &&
        action.payload &&
        action.payload.data &&
        action.payload.data.id;
      if (!id) return state;

      ({ interval } = action.payload.data);

      if (!interval) return state;

      found = false;
      intervalsUpdated = {
        ...state,
        isFetching: false,
        intervals: (state.intervals || []).map(itm => {
          if (!itm || (itm.id && itm.id.toString()) !== id.toString()) {
            // this isn't the item we care about - keep it as-is
            return itm;
          }

          // otherwise, this is the item we are looking for => return the retrieved value
          found = true;

          // keep the data (otherwise on update, it would be wiped out)
          return {
            ...interval,
            data: interval.data || itm.data
          };
        }),
        errors: null
      };

      if (!found) {
        intervalsUpdated.intervals.push(interval);
      }

      return intervalsUpdated;

    case DELETE_INTERVAL_SUCCESS:
      id =
        action &&
        action.payload &&
        action.payload.data &&
        action.payload.data.id;
      if (!id) return state;

      intervalsUpdated = {
        ...state,
        isFetching: false,
        intervals: (state.intervals || []).filter(
          itm => !itm || (itm.id && itm.id.toString()) !== id.toString()
        ),
        errors: null
      };

      return intervalsUpdated;

    case GET_INTERVAL_CYCLES_SUCCESS:
      id =
        action &&
        action.payload &&
        action.payload.data &&
        action.payload.data.id;
      if (!id) return state;

      ({ interval } = action.payload.data);

      if (!interval || !interval.cycles) return state;

      intervalsUpdated = {
        ...state,
        isFetching: false,
        intervals: (state.intervals || []).map(itm => {
          if (!itm || (itm.id && itm.id.toString()) !== id.toString()) {
            // this isn't the item we care about - keep it as-is
            return itm;
          }

          return {
            ...itm,
            cycles: interval.cycles
          };
        }),
        errors: null
      };

      return intervalsUpdated;

    case ADD_INTERVAL_FAILED:
    case UPDATE_INTERVAL_FAILED:
    case GET_INTERVAL_FAILED:
    case LIST_INTERVALS_FAILED:
    case DELETE_INTERVAL_FAILED:
    case GET_INTERVAL_CYCLES_FAILED:
      return {
        ...state,
        isFetching: false,
        errors: action.payload
      };

    case 'FLUSH':
    case CLEAN_INTERVAL:
      return initialState;
    default:
      return state;
  }
};

export const cleanIntervals = () => ({
  type: CLEAN_INTERVAL
});

export const Actions = { cleanIntervals };

export default intervals;
