import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18n from '../../i18n';

class StatementView extends Component {
  static propTypes = {
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string
      })
    )
  };

  renderProperties() {
    const { fields } = this.props;
    const properties = [];
    let i = 0;
    while (i < fields.length) {
      properties.push(
        <React.Fragment>
          <tr>
            <td className="table-key-wrapper">
              {i === 1 ? (
                <p className="table-value">
                  {`${i18n.t(`patientInfo.${fields[i + 1].name}`)}, idő`}
                </p>
              ) : (
                <p className="table-value">
                  {i18n.t(`patientInfo.${fields[i].name}`)}
                </p>
              )}
            </td>
            <td>
              {i === 1 ? (
                <p className="table-value">
                  {`${fields[i + 1].value}, ${fields[i].value}`}
                </p>
              ) : (
                <p className="table-value">{fields[i].value}</p>
              )}
            </td>
          </tr>
        </React.Fragment>
      );
      i += i === 1 ? 2 : 1;
    }
    return properties;
    /* return fields.map(field => (
      <React.Fragment key={field.name}>
        <tr>
          <td className="table-key-wrapper">
            <p className="table-key">{i18n.t(`patientInfo.${field.name}`)}</p>
          </td>
          <td>
            <p className="table-value">{field.value}</p>
          </td>
        </tr>
      </React.Fragment>
    )); */
  }

  render() {
    return (
      <div className="statement-pdf">
        <h1 className="statement-title">{i18n.t('statementInfo.title')}</h1>
        <h2 className="statement-subtitle">
          {i18n.t('statementInfo.subtitle')}
        </h2>
        <table className="statement-table">
          <tbody>{this.renderProperties()}</tbody>
        </table>
        <p className="statement-p">{i18n.t('statementInfo.p1')}</p>
        <p className="statement-p">{i18n.t('statementInfo.p2')}</p>
        <p className="statement-p">{i18n.t('statementInfo.p3')}</p>
        <p className="statement-p">{i18n.t('statementInfo.p4')}</p>
        <p className="statement-p">{i18n.t('statementInfo.p5')}</p>
        <p className="statement-p">{i18n.t('statementInfo.p6')}</p>
        <div className="checkbox-div">
          <div className="checkbox" />
          <p className="statement-p checkbox-p">{i18n.t('statementInfo.p7')}</p>
        </div>
        <p className="statement-small-p">
          {i18n.t('statementInfo.small-p1')}
          <br />
          {i18n.t('statementInfo.small-p2')}
          <br />
          {i18n.t('statementInfo.small-p3')}
          <br />
          {i18n.t('statementInfo.small-p4')}
          <br />
          {i18n.t('statementInfo.small-p5')}
          <br />
          {i18n.t('statementInfo.small-p6')}
          <br />
          {i18n.t('statementInfo.small-p7')}
          <br />
          {i18n.t('statementInfo.small-p7')}
        </p>
        <div className="footer-grid">
          <div>
            <p className="statement-date">
              {`Budapest,
            ${new Date().toLocaleDateString('hu')}`}
            </p>
          </div>
          <div className="signature-wrapper">
            <p className="statement-signature">Páciens aláírása</p>
          </div>
        </div>
      </div>
    );
  }
}

export default StatementView;
