import { connect } from 'react-redux';
import Report from './report';
import { getPrintData } from '../../reducers/request';
import Utils from '../../services/utils';

const mapStateToProps = (state, props) => {
  const { print } = state;
  const { location } = props;

  // extract the interval id from the url
  const regExp = /\/report\/(\d*)/i;
  const matches = regExp.exec(location.pathname);
  const id = matches && matches.length > 1 && matches[1];

  // extract the evaluation from the list
  const evaluation = print && print.evaluation;

  // interval of the selected evaluation
  const interval = print && print.interval;

  // examination of the selected interval
  const examination = print && print.examination;

  // patient of the selected examination
  const patient = print && print.patient;

  // benchmark of the selected evaluation
  const benchmark = print && print.benchmark;

  // extract the token from qs
  const params = Utils.getQueryStringParams(location.search);
  const { token } = params;

  return {
    evaluation,
    interval,
    examination,
    patient,
    benchmark,
    evaluationId: id,
    token,
    isPrintFetching: print.isFetching,
    errors: print.errors
  };
};

export default connect(
  mapStateToProps,
  { getPrintData }
)(Report);
