import React, { Component } from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line
import moment from 'moment';
import BenchmarkView from './benchmark-view';
import Loader from '../../../loader';
import NotFound from '../../../not-found';
import i18n from '../../../../i18n';

class Benchmark extends Component {
  static propTypes = {
    interval: PropTypes.shape({
      name: PropTypes.string
    }),
    evaluation: PropTypes.shape({}),
    patient: PropTypes.shape({}),
    examination: PropTypes.shape({}),
    benchmark: PropTypes.shape({}),
    getBenchmark: PropTypes.func,
    patients: PropTypes.arrayOf(PropTypes.any),
    isIntervalFetching: PropTypes.bool,
    isEvaluationFetching: PropTypes.bool,
    isExaminationFetching: PropTypes.bool,
    isPatientFetching: PropTypes.bool,
    intervalId: PropTypes.string,
    examinationId: PropTypes.string,
    patientId: PropTypes.number,
    evaluationId: PropTypes.string,
    getInterval: PropTypes.func,
    getEvaluation: PropTypes.func,
    getExamination: PropTypes.func,
    getPatient: PropTypes.func,
    examinationErrors: PropTypes.shape({
      message: PropTypes.string
    }),
    intervalErrors: PropTypes.shape({
      message: PropTypes.string
    }),
    evaluationErrors: PropTypes.shape({
      message: PropTypes.string
    }),
    patientErrors: PropTypes.shape({
      message: PropTypes.string
    }),
    benchmarkErrors: PropTypes.shape({
      message: PropTypes.string
    }),
    benchmarkId: PropTypes.number,
    updateEvaluation: PropTypes.func,
    isBenchmarkLoading: PropTypes.bool
  };

  static possibleBenchmarkTypes = [
    {
      name: i18n.t('benchmarkInfo.allExaminations'),
      value: 0
    },
    {
      name: i18n.t('benchmarkInfo.allMaleExaminations'),
      value: 10
    },
    {
      name: i18n.t('benchmarkInfo.allFemaleExaminations'),
      value: 20
    },
    {
      name: i18n.t('benchmarkInfo.matchingGenderAgeCohort'),
      value: 100
    },
    {
      name: i18n.t('benchmarkInfo.matchingGenderAgeBMICohort'),
      value: 1000
    }
  ];

  /**
   *
   * Returns the domain of the benchmark chart
   *
   */
  static getBenchmarkDomain(benchmarks) {
    let max = 0;
    let min = 0;
    benchmarks.forEach(benchmark => {
      benchmark.data.forEach(piece => {
        if (piece.y >= max) {
          max = piece.y;
        }
        if (piece.y <= min) {
          min = piece.y;
        }
      });
    });
    benchmarks.forEach(benchmark => {
      benchmark.borders.forEach(piece => {
        if (piece.y >= max) {
          max = piece.y;
        }
        if (piece.y0 <= min) {
          min = piece.y0;
        }
      });
    });

    max = Math.ceil(max) + 4;
    min = Math.floor(min) - 4;

    const ticks = [];
    for (let x = min; x <= max; x += 1) {
      if (x % 2 === 0) {
        ticks.push(x);
      }
    }

    return { max, min, ticks };
  }

  constructor(props) {
    super(props);
    this.state = {
      benchmarkType: Benchmark.possibleBenchmarkTypes[0]
    };
    this.handleBenchmarkChange = this.handleBenchmarkChange.bind(this);
  }

  /*
   * If the evaluation is found, fetch benchmark data
   */
  componentDidMount() {
    const { getBenchmark, evaluationId, evaluation } = this.props;
    if (evaluation) {
      getBenchmark(evaluation.benchmarkId, evaluationId);
      this.setBenchmarkState(evaluation.benchmarkId);
    }
  }

  /*
   * If new benchmarkId comes as a prop, fetch the benchmark
   */
  componentWillReceiveProps(nextProps) {
    const { getBenchmark, evaluationId, benchmarkId } = this.props;
    if (
      nextProps.benchmarkId.toString() &&
      nextProps.benchmarkId !== benchmarkId
    ) {
      getBenchmark(nextProps.benchmarkId, evaluationId);
      this.setBenchmarkState(nextProps.benchmarkId);
    }
  }

  /*
   * Decodes the benchmarkId to match dictionary and sets the state according to the result
   */
  setBenchmarkState(benchmarkId) {
    let stateBench = 0;
    if (benchmarkId < 100) {
      stateBench = benchmarkId;
    }
    if (benchmarkId >= 100 && benchmarkId < 1000) {
      stateBench = 100;
    }
    if (benchmarkId >= 1000 && benchmarkId) {
      stateBench = 1000;
    }

    const newBench = Benchmark.possibleBenchmarkTypes.find(
      f => f.value.toString() === stateBench.toString()
    );

    this.setState({
      benchmarkType: newBench
    });
  }

  /*
   * Returns a constant number based on backend dictionary,
   * If patient not found returns 0
   */
  getAgeNum() {
    const { patients, examination } = this.props;
    const patient = patients.find(p => p.id === examination.patientId);

    if (!patient || !patient.dateOfBirth) return 0;

    const years = moment().diff(moment(patient.dateOfBirth), 'years');

    if (years >= 10 && years < 20) return 100;
    if (years >= 20 && years < 40) return 200;
    if (years >= 40 && years < 50) return 300;
    if (years >= 50 && years < 99) return 400;

    return 0;
  }

  /*
   * Returns a constant number based on backend dictionary,
   * If patient not found returns 0
   */
  getGender() {
    const { patients, examination } = this.props;

    const patient = patients.find(p => p.id === examination.patientId);

    if (!patient || !patient.gender) return 0;

    switch (patient.gender) {
      case 'male':
        return 10;
      case 'female':
        return 20;
      default:
        return 0;
    }
  }

  /*
   * Returns the time of the evaluation
   */
  getEvaluationTime() {
    const { evaluation } = this.props;
    if (!evaluation || !evaluation.ftStart || !evaluation.ftEnd) return '';

    return `${moment()
      .minutes(0)
      .seconds(evaluation.ftStart / 100)
      .format('m:ss')}
      - 
      ${moment()
        .minutes(0)
        .seconds(evaluation.ftEnd / 100)
        .format('m:ss')}`;
  }

  /*
   * Returns a constant number based on backend dictionary,
   * If examination not found returns 0
   */
  getBMI() {
    const { examination } = this.props;
    if (!examination || !examination.weight || !examination.height) return 0;

    const BMI = this.calculateBMI();

    if (BMI >= 10 && BMI < 20) return 1000;
    if (BMI >= 20 && BMI < 23) return 2000;
    if (BMI >= 23 && BMI < 25) return 3000;
    if (BMI >= 25 && BMI < 27) return 4000;
    if (BMI >= 27 && BMI < 30) return 5000;
    if (BMI >= 30 && BMI < 40) return 6000;
    return 0;
  }

  /**
   * Returns the name of the interval if specified. Falls back to the speed.
   */
  getIntervalName() {
    const { interval } = this.props;

    if (!interval) return '';

    return interval.name || `${interval.speed} km/h`;
  }

  /**
   * Returns the BMI of the patient based on examination data
   */
  calculateBMI() {
    const { examination } = this.props;
    if (!examination || !examination.weight || !examination.height) return 0;

    // BMI calculation formula: [weight (kg) / height (cm) / height (cm)] x 10,000
    // found here: https://www.cdc.gov/nccdphp/dnpao/growthcharts/training/bmiage/page5_1.html
    return (
      (examination.weight / examination.height / examination.height) * 10000
    );
  }

  /**
   *
   * Returns the proper number for backend API call
   *
   */
  benchmarkTypeForAPICall(type) {
    switch (type) {
      // All examinations
      case 0:
        return 0;

      // All male examinations
      case 10:
        return 10;

      // All female examinations
      case 20:
        return 20;

      // Matching gender, age and cohort'
      case 100:
        return this.getAgeNum() + this.getGender();

      // Matching gender, age and cohort'
      case 1000:
        return this.getAgeNum() + this.getGender() + this.getBMI();

      default:
        return 0;
    }
  }

  /**
   *
   * Fetches new benchmark borders from API, set actual benchmark type to component state
   *
   * @param Event e The click event
   */
  handleBenchmarkChange(e) {
    const { evaluation, updateEvaluation } = this.props;

    const newBench = Benchmark.possibleBenchmarkTypes.find(
      f => f.value.toString() === e.target.value.toString()
    );

    const benchmarkId = this.benchmarkTypeForAPICall(newBench.value);

    // Updates the evaluation's benchmarkId
    updateEvaluation({
      ...evaluation,
      benchmarkId,
      leftHip: evaluation.leftHip || '',
      rightHip: evaluation.rightHip || '',
      leftKnee: evaluation.leftKnee || '',
      rightKnee: evaluation.rightKnee || '',
      leftFoot: evaluation.leftFoot || '',
      rightFoot: evaluation.rightFoot || '',
      pelvis: evaluation.pelvis || ''
    });
  }

  /**
   * Transforms API benchmark data to chart data
   */
  benchmarkToChart() {
    const { evaluation, benchmark } = this.props;

    if (!evaluation || !evaluation.avgCurves)
      return { chartAvgs: [], domain: null };

    const avgs = JSON.parse(evaluation.avgCurves.replace(/\bNaN\b/g, "null"));

    if (!avgs) return { chartAvgs: [], domain: null };

    const chartAvgs = [];
    const { sample } = benchmark;

    const leftV = {
      name: `${i18n.t('chartInfo.left').toLowerCase()} V`,
      data: [],
      borders: []
    };
    const rightV = {
      name: `${i18n.t('chartInfo.right').toLowerCase()} V`,
      data: [],
      borders: []
    };
    const leftS = {
      name: `${i18n.t('chartInfo.left').toLowerCase()} S`,
      data: [],
      borders: []
    };
    const rightS = {
      name: `${i18n.t('chartInfo.right').toLowerCase()} S`,
      data: [],
      borders: []
    };
    const leftH = {
      name: `${i18n.t('chartInfo.left').toLowerCase()} H`,
      data: [],
      borders: []
    };
    const rightH = {
      name: `${i18n.t('chartInfo.right').toLowerCase()} H`,
      data: [],
      borders: []
    };

    avgs.forEach(d => {
      leftV.data.push({ x: d.ss, y: d.lavg_v });
      rightV.data.push({ x: d.ss, y: d.ravg_v });
      leftS.data.push({ x: d.ss, y: d.lavg_s });
      rightS.data.push({ x: d.ss, y: d.ravg_s });
      leftH.data.push({ x: d.ss, y: d.lavg_h });
      rightH.data.push({ x: d.ss, y: d.ravg_h });
    });

    // Borders coming from benchmark API
    if (benchmark && benchmark.max_curve && benchmark.min_curve) {
      const max = benchmark.max_curve;
      const min = benchmark.min_curve;

      for (let i = 0; i < benchmark.max_curve.length; i += 1) {
        leftV.borders.push({
          x: max[i].ss,
          y0: min[i].lavg_v,
          y: max[i].lavg_v
        });
        leftS.borders.push({
          x: max[i].ss,
          y0: min[i].lavg_s,
          y: max[i].lavg_s
        });
        leftH.borders.push({
          x: max[i].ss,
          y0: min[i].lavg_h,
          y: max[i].lavg_h
        });
        rightV.borders.push({
          x: max[i].ss,
          y0: min[i].ravg_v,
          y: max[i].ravg_v
        });
        rightS.borders.push({
          x: max[i].ss,
          y0: min[i].ravg_s,
          y: max[i].ravg_s
        });
        rightH.borders.push({
          x: max[i].ss,
          y0: min[i].ravg_h,
          y: max[i].ravg_h
        });
      }
    }

    chartAvgs.push(leftV, rightV, leftS, rightS, leftH, rightH);

    const domain = Benchmark.getBenchmarkDomain(chartAvgs);

    return { chartAvgs, domain, sample };
  }

  render() {
    const { benchmarkType } = this.state;
    const { isBenchmarkLoading } = this.props;
    const data = this.benchmarkToChart();

    const {
      interval,
      examination,
      evaluation,
      patient,
      isIntervalFetching,
      isEvaluationFetching,
      isExaminationFetching,
      isPatientFetching,
      intervalId,
      evaluationId,
      examinationId,
      patientId,
      getInterval,
      getEvaluation,
      getExamination,
      getPatient,
      examinationErrors,
      patientErrors,
      evaluationErrors,
      intervalErrors,
      benchmarkErrors
    } = this.props;

    if (
      evaluationErrors ||
      intervalErrors ||
      examinationErrors ||
      patientErrors ||
      benchmarkErrors
    ) {
      const errorMessage =
        (evaluationErrors && evaluationErrors.message) ||
        (intervalErrors && intervalErrors.message) ||
        (examinationErrors && examinationErrors.message) ||
        (patientErrors && patientErrors.message) ||
        (benchmarkErrors && benchmarkErrors.message);
      return (
        <NotFound
          isWrapped
          icon
          message={i18n.t(`backendResponse.${errorMessage}`)}
          backTo={`/examinations/${examinationId}/overview`}
        />
      );
    }

    if (!evaluation) {
      if (!isEvaluationFetching) {
        getEvaluation(evaluationId);
      }
    }

    if (!interval) {
      if (!isIntervalFetching) {
        getInterval(intervalId);
      }
    }

    if (!examination) {
      if (!isExaminationFetching) {
        getExamination(examinationId);
      }
    }

    if (!patient) {
      if (!isPatientFetching) {
        getPatient(patientId);
      }
    }

    if (!evaluation || !examination || !interval || !patient) {
      return (
        <Loader
          isWrapped
          message={i18n.t('loadingInfo.loadingBenchmarkData').toLowerCase()}
        />
      );
    }

    return (
      <BenchmarkView
        evaluationTime={this.getEvaluationTime()}
        movingAverage={evaluation && evaluation.movingAverage}
        isBenchmarkLoading={isBenchmarkLoading}
        intervalName={this.getIntervalName()}
        benchmark={data.chartAvgs}
        benchmarkDomain={data.domain}
        benchmarkSample={data.sample}
        changeBenchmarkType={this.handleBenchmarkChange}
        benchmarkType={benchmarkType}
        possibleBenchmarkTypes={Benchmark.possibleBenchmarkTypes}
      />
    );
  }
}

export default Benchmark;
