import env from '../../config';
import ApiHandlerService from '../../services/api-handler';

export default class BenchmarkApi {
  static get(variables, token) {
    const number = variables && variables.benchmarkType;
    const evaluationId = variables && variables.evaluationId;
    const url = `${
      env.API_BASE_URL
    }/benchmark/${number}?evaluation_id=${evaluationId}`;
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store'
      },
      method: 'GET'
    };

    return ApiHandlerService.fetch(url, config);
  }
}
