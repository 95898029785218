import { REHYDRATE } from '../request';

const GET_USER = 'GET_USER';
const SIGN_IN = 'SIGN_IN';
const SIGN_UP = 'SIGN_UP';
const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
const SIGN_IN_FAILED = 'SIGN_IN_FAILED';
const SIGN_UP_FAILED = 'SIGN_UP_FAILED';
const TOKEN_IS_VALID = 'TOKEN_IS_VALID';
const VALIDATE_TOKEN = 'VALIDATE_TOKEN';
const SIGN_OUT = 'SIGN_OUT';
const SIGNED_OUT = 'SIGNED_OUT';
const SAVE_AUTH_HEADERS = 'SAVE_AUTH_HEADERS';
const SWAP_JWT_TOKEN = 'SWAP_JWT_TOKEN';

/** Action types */
export const Types = {
  GET_USER,
  SIGN_IN,
  SIGN_UP,
  SIGN_IN_SUCCESS,
  SIGN_UP_SUCCESS,
  SIGN_IN_FAILED,
  SIGN_UP_FAILED,
  SIGN_OUT,
  SIGNED_OUT,
  TOKEN_IS_VALID,
  VALIDATE_TOKEN,
  SAVE_AUTH_HEADERS
};

export const initialState = {
  root: 'login',
  isFetching: false,
  signInMessages: [],
  signUpMessages: [],
  signedUp: false,
  errors: [],
  updateEmail: false,
  user: null
};

const auth = (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        isFetching: true,
        signInMessages: [],
        signUpMessages: [],
        signedUp: false
      };
    case SIGNED_OUT:
      return {
        ...state,
        signedUp: false,
        user: null,
        headers: null,
        root: 'login',
        signInMessages: [],
        signUpMessages: []
      };

    case SIGN_IN_SUCCESS: {
      return {
        ...state,
        user: action.payload.data
      };
    }
    case SWAP_JWT_TOKEN:
      return {
        ...state,
        user: {
          ...state.user,
          token: action.payload.token
        }
      };
    case SIGN_IN_FAILED:
      return {
        ...state,
        isFetching: false,
        errors: action.payload
      };
    case REHYDRATE:
      return {
        ...((action.payload && action.payload.auth) || initialState),
        isFetching: false
      };
    case 'FLUSH':
      return initialState;
    default:
      return state;
  }
};

export const getUser = isSignIn => ({
  type: Types.GET_USER,
  isSignIn
});

export const gotUser = (payload, isSignIn) => ({
  type: Types.GOT_USER,
  payload,
  isSignIn
});

export const signIn = credentials => ({
  type: Types.SIGN_IN,
  payload: credentials
});

export const signOut = () => ({
  type: Types.SIGN_OUT
});

export const signedOut = () => ({
  type: Types.SIGNED_OUT
});

export const signInSuccess = data => ({
  type: Types.SIGN_IN_SUCCESS,
  payload: data
});

export const swapJwtToken = token => ({
  type: SWAP_JWT_TOKEN,
  payload: { token }
});

export const Actions = {
  getUser,
  gotUser,
  signIn,
  signInSuccess,
  signOut,
  signedOut,
  swapJwtToken
};

export default auth;
