import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import i18n from '../../../i18n';

class ConfirmView extends Component {
  static propTypes = {
    isConfirmOpen: PropTypes.bool,
    confirmFunction: PropTypes.func,
    closeConfirm: PropTypes.func,
    confirmMessage: PropTypes.string
  };

  render() {
    const {
      closeConfirm,
      confirmFunction,
      confirmMessage,
      isConfirmOpen
    } = this.props;

    return (
      <Modal open={isConfirmOpen}>
        <div className="modal-view confirm-modal box">
          <h1 className="confirm-message">{confirmMessage}</h1>
          <div className="actions">
            <button
              type="button"
              onClick={() => {
                // If confirmed: close confirm modal and call the passed function
                closeConfirm();
                confirmFunction();
              }}
            >
              {i18n.t('confirmModal.confirmed')}
            </button>
            <button
              className="btn-red"
              type="button"
              onClick={() => {
                // If not confirmed: close confirm modal
                closeConfirm();
              }}
            >
              {i18n.t('confirmModal.notConfirmed')}
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ConfirmView;
