import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ExaminationSubmenu from './examination-submenu';

const mapStateToProps = ({
  intervals: { intervals },
  evaluations: { evaluations }
}) => ({
  intervals,
  evaluations
});

export default withRouter(connect(mapStateToProps)(ExaminationSubmenu));
