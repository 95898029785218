import { BrowserRouter as Router } from 'react-router-dom';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class MainView extends Component {
  static propTypes = {
    elements: PropTypes.element.isRequired
  };

  render() {
    const { elements } = this.props;
    return <Router basename="/">{elements}</Router>;
  }
}

export default MainView;
