import React, { Component } from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line
import {
  VictoryChart,
  VictoryLine,
  VictoryAxis,
  VictoryScatter
} from 'victory';
import { TextField, MenuItem } from '@material-ui/core';
import Draggable from 'react-draggable';
import '../../../../styles/interval.css';
import EvaluationList from '../../../evaluation-list';
import Loader from '../../../loader';

import i18n from '../../../../i18n';

class IntervalView extends Component {
  static propTypes = {
    data: PropTypes.shape({
      data: PropTypes.any
    }),
    // Use this proptype validation if proptypes arrive properly
    cycles: PropTypes.arrayOf(
      PropTypes.shape({
        time: PropTypes.number,
        s: PropTypes.number,
        v: PropTypes.number
      })
    ),

    // Delete this PropType validation if proptypes arrive properly
    // cycles: PropTypes.arrayOf(PropTypes.number),

    tickFormatter: PropTypes.func.isRequired,
    ticks: PropTypes.arrayOf(PropTypes.any).isRequired,
    changeDraggablePosition: PropTypes.func.isRequired,
    intervalName: PropTypes.string.isRequired,
    changeMovingAverage: PropTypes.func.isRequired,
    movingAverageValue: PropTypes.number.isRequired,
    possibleMovingAverageValues: PropTypes.arrayOf(PropTypes.any).isRequired,
    draggableStartBound: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    draggableStartPosition: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    draggableEndBound: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    draggableEndPosition: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    startEvaluation: PropTypes.func.isRequired,
    intervalId: PropTypes.number,
    isFetchingIntervals: PropTypes.bool,
    isFetchingEvaluations: PropTypes.bool,
    isEvaluationRunning: PropTypes.bool,
    setBestFit: PropTypes.func,
    speed: PropTypes.number
  };

  static getMenuItems(values) {
    return values.map(value => (
      <MenuItem key={value} value={value}>
        {value}
      </MenuItem>
    ));
  }

  /**
   * If evaluations are fetching, render the loader component
   */
  static renderLoadingEvaluation(
    isFetchingEvaluations,
    isEvaluationRunning,
    isFetchingIntervals
  ) {
    if (isFetchingIntervals) {
      return (
        <Loader isWrapped message={i18n.t('loadingInfo.loadingInterval')} />
      );
    }
    if (isFetchingEvaluations) {
      return (
        <div>
          <Loader
            isWrapped
            message={i18n.t('loadingInfo.loadingEvaluations')}
          />
        </div>
      );
    }
    if (isEvaluationRunning) {
      return (
        <div>
          <Loader
            isWrapped
            message={i18n.t('loadingInfo.evaluationInProgress')}
          />
        </div>
      );
    }
    return null;
  }

  static renderCycles(cycles, speed) {
    if (!cycles || cycles === []) return null;
    return (
      <VictoryScatter
        size={4}
        style={{
          data: {
            stroke: '#f26f5a',
            fillOpacity: 0,
            strokeWidth: 2
          }
        }}
        data={cycles}
        x="time"
        y={speed < 8 ? 's' : 'v'}
      />
    );
  }

  /**
   * If the listOfBools conatains a true element returns the hiding css class
   *
   * @param {[bool]} listOfBools the list of booleans to check
   */
  static hideListClass(listOfBools) {
    const hide = listOfBools.includes(true);
    if (hide) {
      return 'hidden-element';
    }
    return '';
  }

  renderChart() {
    const { data, tickFormatter, ticks, cycles, speed } = this.props;

    if (!data || !data.data) return null;

    return (
      <svg viewBox="0 0 1000 320">
        <VictoryChart standalone={false} width={1000} height={320}>
          <VictoryAxis
            orientation="left"
            domain={[-10, 10]}
            tickValues={[-15, -10, -5, 0, 5, 10, 15]}
            style={{
              tickLabels: { fontSize: 12, padding: 6 }
            }}
          />
          <VictoryAxis
            dependentAxis
            orientation="bottom"
            offsetY={50}
            label="Time"
            tickValues={ticks}
            tickFormat={t => tickFormatter(t)}
            style={{
              grid: { stroke: '#D0D0D0' },
              tickLabels: { fontSize: 12, padding: 6 }
            }}
          />
          <VictoryLine
            data={data && data.data}
            x="time"
            y={speed < 8 ? 's' : 'v'}
            style={{
              data: { stroke: '#28bcbc', strokeWidth: 1 }
            }}
          />
          {IntervalView.renderCycles(cycles, speed)}
        </VictoryChart>
      </svg>
    );
  }

  render() {
    const {
      changeDraggablePosition,
      intervalName,
      intervalId,
      changeMovingAverage,
      movingAverageValue,
      possibleMovingAverageValues,
      draggableStartBound,
      draggableStartPosition,
      draggableEndBound,
      draggableEndPosition,
      startEvaluation,
      isFetchingEvaluations,
      isEvaluationRunning,
      isFetchingIntervals,
      setBestFit
    } = this.props;

    return (
      <div className="interval">
        <div
          className={`head ${IntervalView.hideListClass([
            isFetchingIntervals
          ])}`}
        >
          <h1>{intervalName}</h1>
          <p>{i18n.t('intervalInfo.movingAverage')}</p>
          <TextField
            select
            onChange={e => {
              e.preventDefault();
              changeMovingAverage(e);
            }}
            value={movingAverageValue}
            InputLabelProps={{
              shrink: true,
              style: { fontSize: '13pt' }
            }}
            style={{ display: 'inline-block' }}
            name="movingAverage"
          >
            {IntervalView.getMenuItems(possibleMovingAverageValues)}
          </TextField>
          <button
            type="button"
            style={{ marginLeft: 20 }}
            onClick={() => setBestFit()}
          >
            {i18n.t('intervalActions.bestFit')}
          </button>
        </div>
        <div
          className={`chart ${IntervalView.hideListClass([
            isFetchingIntervals
          ])}`}
        >
          <div className="start-end">
            <Draggable
              handle=".handle"
              bounds={draggableStartBound}
              axis="x"
              position={draggableStartPosition}
              onStop={(e, data) => changeDraggablePosition(e, data, true)}
            >
              <div className="handle separator">
                <div className="handle separator-dot" />
                <div className="handle separator-line" />
              </div>
            </Draggable>
            <Draggable
              handle=".handle"
              bounds={draggableEndBound}
              axis="x"
              position={draggableEndPosition}
              onStop={(e, data) => changeDraggablePosition(e, data, false)}
            >
              <div className="separator">
                <div className="handle separator-dot" />
                <div className="handle separator-line" />
              </div>
            </Draggable>
          </div>
          {this.renderChart()}
        </div>
        <button
          className={IntervalView.hideListClass([
            isEvaluationRunning,
            isFetchingIntervals
          ])}
          type="button"
          onClick={startEvaluation}
        >
          {i18n.t('intervalActions.startEvaluation')}
        </button>
        {IntervalView.renderLoadingEvaluation(
          isFetchingEvaluations,
          isEvaluationRunning,
          isFetchingIntervals
        )}
        <div
          className={IntervalView.hideListClass([
            isEvaluationRunning,
            isFetchingEvaluations,
            isFetchingIntervals
          ])}
        >
          <EvaluationList intervalId={intervalId} />
        </div>
      </div>
    );
  }
}

export default IntervalView;
