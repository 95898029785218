import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18n from '../../i18n';

class ChartLabel extends Component {
  static propTypes = {
    labelInfo: PropTypes.shape({}),
    labelType: PropTypes.string
  };

  render() {
    const { labelInfo, labelType } = this.props;

    const suffix = ` | ${i18n.t('chartInfo.time')}: ${
      labelInfo.evaluationTime
    } | ${i18n.t('chartInfo.MA')}:${labelInfo.movingAverage}`;

    switch (labelType) {
      case 'benchmark':
        return (
          <h3 className="chart-title">
            {`${labelInfo.chartName} - ${i18n
              .t('chartInfo.benchmark')
              .toLowerCase()} | ${labelInfo.benchmarkType} ${suffix} | ${i18n.t(
              'chartInfo.samples'
            )}:${labelInfo.benchmarkSample}`}
          </h3>
        );
      case 'histogram':
        return (
          <h3 className="chart-title">
            {`${labelInfo.chartName} ${i18n.t(
              'chartInfo.histogram'
            )} ${suffix}`}
          </h3>
        );
      case 'dimensions':
        return (
          <h3 className="chart-title">
            {`${labelInfo.chartName} ${i18n.t(
              'chartInfo.dimensions'
            )} ${suffix}`}
          </h3>
        );
      case 'averages':
        return (
          <h3 className="chart-title">
            {`${i18n.t('chartInfo.comparisonAVGstart')} ${
              labelInfo.chartName
            } ${i18n.t('chartInfo.comparisonAVGend')} ${suffix}`}
          </h3>
        );
      default:
        return null;
    }
  }
}

export default ChartLabel;
