/* eslint react/jsx-wrap-multilines: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line

import i18n from '../../../i18n';

import { getModalFields, ModalTypes } from '../modal-fields';
import EditExaminationView from './edit-examination-view';

class EditExamination extends Component {
  static propTypes = {
    defaults: PropTypes.shape({
      weight: PropTypes.number,
      height: PropTypes.number,
      date: PropTypes.string,
      dominantSide: PropTypes.string,
      examType: PropTypes.string,
      comment: PropTypes.string,
      leftHip: PropTypes.string,
      rightHip: PropTypes.string,
      leftKnee: PropTypes.string,
      rightKnee: PropTypes.string,
      leftFoot: PropTypes.string,
      rightFoot: PropTypes.string,
      pelvis: PropTypes.string
    }).isRequired,
    patientId: PropTypes.number,
    patient: PropTypes.shape({}),
    examId: PropTypes.number.isRequired,
    isOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    updateExamination: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.fields = getModalFields(ModalTypes.Examination, props.defaults);
    this.buttons = this.setupButtons();
    this.state = {
      anamnesis: {
        leftHip: props.defaults.leftHip,
        rightHip: props.defaults.rightHip,
        leftKnee: props.defaults.leftKnee,
        rightKnee: props.defaults.rightKnee,
        leftFoot: props.defaults.leftFoot,
        rightFoot: props.defaults.rightFoot,
        pelvis: props.defaults.pelvis
      }
    };
    this.onAnamnesisChange = this.onAnamnesisChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.fields = getModalFields(ModalTypes.Examination, nextProps.defaults);
  }

  onAnamnesisChange(name, value) {
    this.setState(prevState => {
      const anamnesis = Object.assign({}, prevState.anamnesis);
      anamnesis[name] = value;
      return { anamnesis };
    });
  }

  setupButtons() {
    return [
      {
        name: 'editExamination',
        type: 'submit',
        text: i18n.t('modalActions.saveExamination'),
        doValidate: true, // optional (true by default)
        submit: that => {
          this.editExamination(that);
        }
      }
    ];
  }

  clearAnamnesis() {
    const { defaults } = this.props;
    this.setState({
      anamnesis: {
        leftHip: defaults.leftHip,
        rightHip: defaults.rightHip,
        leftKnee: defaults.leftKnee,
        rightKnee: defaults.rightKnee,
        leftFoot: defaults.leftFoot,
        rightFoot: defaults.rightFoot,
        pelvis: defaults.pelvis
      }
    });
  }

  editExamination(that) {
    const { examId, patientId, updateExamination } = this.props;
    const { state } = that;
    const fields = { ...state.fields };
    const { anamnesis } = this.state;

    const newExamination = {
      weight: parseFloat(fields.weight.value),
      height: parseFloat(fields.height.value),
      date: new Date(fields.date.value).toISOString(),
      examType: fields.examType.value.toLowerCase(),
      comment: fields.comment.value,
      leftHip: anamnesis.leftHip,
      rightHip: anamnesis.rightHip,
      leftKnee: anamnesis.leftKnee,
      rightKnee: anamnesis.rightKnee,
      leftFoot: anamnesis.leftFoot,
      rightFoot: anamnesis.rightFoot,
      pelvis: anamnesis.pelvis,
      patientId
    };

    updateExamination(examId, newExamination);
    this.clearAnamnesis();
  }

  render() {
    const { isOpen, closeModal } = this.props;
    const { anamnesis } = this.state;
    return (
      <EditExaminationView
        modalType="examination"
        fields={this.fields}
        buttons={this.buttons}
        isOpen={isOpen}
        closeModal={() => {
          closeModal();
          this.clearAnamnesis();
        }}
        anamnesis={anamnesis}
        onAnamnesisChange={this.onAnamnesisChange}
      />
    );
  }
}

export default EditExamination;
