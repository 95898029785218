import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types'; // eslint-disable-line

import i18n from '../../i18n';

import '../../styles/evaluation-list.css';

export default class EvaluationListView extends Component {
  static propTypes = {
    evaluations: PropTypes.arrayOf(
      PropTypes.shape({
        intervalId: PropTypes.number.isRequired,
        ftEnd: PropTypes.number.isRequired,
        ftStart: PropTypes.number.isRequired,
        id: PropTypes.number.isRequired,
        movingAverage: PropTypes.number.isRequired,
        results: PropTypes.string,
        speed: PropTypes.number.isRequired
      })
    ),
    pathStart: PropTypes.string,
    cleanBenchmark: PropTypes.func,
    getEvaluationTime: PropTypes.func,
    getStepCount: PropTypes.func
  };

  static renderBestFitSticker(isBestFit) {
    return (
      isBestFit && (
        <div className="status">
          <div className="best-fit-sticker">
            <h1>Best fit</h1>
          </div>
        </div>
      )
    );
  }

  renderExaminations() {
    const {
      evaluations,
      pathStart,
      cleanBenchmark,
      getEvaluationTime,
      getStepCount
    } = this.props;

    const sortedEvaluations =
      (evaluations &&
        evaluations.sort((a, b) =>
          moment(b.createdAt).diff(moment(a.createdAt))
        )) ||
      [];

    return sortedEvaluations.map(evaluation => (
      <div className="evaluation-wrapper" key={evaluation.id}>
        <Link
          key={evaluation.id}
          to={`${pathStart}/${evaluation.id}`}
          style={{ textDecoration: 'none' }}
          onClick={() => cleanBenchmark()}
        >
          <div className="list-item box">
            <div className="column-date">
              {evaluation.createdAt &&
                `${new Date(evaluation.createdAt).toLocaleDateString('hu-HU')} 
                  ${new Date(evaluation.createdAt).toLocaleTimeString(
                    'hu-HU'
                  )}`}
            </div>
            <div className="column-range">{getEvaluationTime(evaluation)}</div>
            <div className="column-steps">{getStepCount(evaluation)}</div>
            <div className="column-moving-average">
              {evaluation.movingAverage}
            </div>
          </div>
        </Link>

        {EvaluationListView.renderBestFitSticker(evaluation.bestfit)}
      </div>
    ));
  }

  render() {
    return (
      <div className="evaluation-list evaluation-grid">
        <div className="list-item list-header">
          <div className="column-date">
            {i18n.t('evaluationsList.evalDate')}
          </div>
          <div className="column-range">{i18n.t('evaluationsList.range')}</div>
          <div className="column-steps">
            {i18n.t('evaluationsList.numberOfSteps')}
          </div>
          <div className="column-moving-average">
            {i18n.t('intervalInfo.movingAverage')}
          </div>
        </div>
        {this.renderExaminations()}
      </div>
    );
  }
}
