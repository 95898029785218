import React, { Component } from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line
import PatientsView from './patients-view';

class Patients extends Component {
  static propTypes = {
    patients: PropTypes.shape({
      isFetching: PropTypes.bool.isRequired,
      patients: PropTypes.arrayOf(PropTypes.any).isRequired
    }),
    getPatients: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      searchedPatients: [],
      search: {
        show: 'search-results-closed',
        name: '',
        comment: '',
        date: ''
      }
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.searchChanged = this.searchChanged.bind(this);
    this.closeSearch = this.closeSearch.bind(this);
  }

  async componentDidMount() {
    const { getPatients } = this.props;
    getPatients('*', 500);
  }

  searchChanged(e) {
    const { state } = this;
    const { patients } = this.props;

    if (!patients || !patients.patients) {
      return;
    }

    switch (e.target.name) {
      case 'name':
        state.search.name = e.target.value;
        break;
      case 'comment':
        state.search.comment = e.target.value;
        break;
      case 'date':
        state.search.date = e.target.value;
        break;
      default:
        break;
    }

    const { name, date, comment } = state.search;

    if (name || comment || date) {
      // Search if one of the field is not empty

      let searchedPatients = patients.patients;

      // filter by name first
      if (name) {
        const re = new RegExp(
          name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
          'i'
        );
        searchedPatients = searchedPatients.filter(
          p =>
            p.firstName &&
            p.lastName &&
            re.test(
              `${p.firstName
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')} ${p.lastName
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')}`
            ) &&
            p
        );
        // searchedPatients = [...new Set([...searchedPatients, ...filtered])];
      }

      // further filter by comment
      if (comment) {
        const re = new RegExp(
          comment.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
          'i'
        );
        searchedPatients = searchedPatients.filter(
          p =>
            re.test(
              p.lastExamination &&
                p.lastExamination.comment &&
                p.lastExamination.comment
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
            ) && p
        );
      }

      // further filter by date
      if (date) {
        const re = new RegExp(date, 'i');
        searchedPatients = searchedPatients.filter(
          p => re.test(p.lastExamination && p.lastExamination.date) && p
        );
      }

      // @TODO: better search (from db)
      this.setState(prevState => ({
        ...prevState,
        search: {
          ...prevState.search,
          show: 'search-results-open'
        },
        searchedPatients
      }));
    } else {
      // Close search if everything is deleted from search fields

      this.setState(prevState => ({
        ...prevState,
        search: {
          ...prevState.search,
          show: 'search-results-closed'
        }
      }));
    }
  }

  openModal() {
    this.setState({
      isModalOpen: true
    });
  }

  closeModal() {
    this.setState({
      isModalOpen: false
    });
  }

  closeSearch() {
    this.setState(prevState => ({
      search: {
        ...prevState.search,
        show: 'search-results-closed',
        name: '',
        comment: '',
        date: ''
      }
    }));
  }

  render() {
    const { patients } = this.props;
    const { isModalOpen, search, searchedPatients } = this.state;
    return (
      <PatientsView
        patients={patients}
        isModalOpen={isModalOpen}
        openModal={this.openModal}
        closeModal={this.closeModal}
        searchChanged={this.searchChanged}
        search={search}
        searchedPatients={searchedPatients}
        closeSearch={this.closeSearch}
      />
    );
  }
}

export default Patients;
