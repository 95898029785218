/* globals navigator */
export default class Utils {
  /**
   * Extracts the user agent from the last HTTP request.
   *
   * @static
   * @returns The user agent description.
   * @memberof Utils
   */
  static getUserAgent() {
    return navigator && navigator.userAgent;
  }

  /**
   * Returns if the page was requested from the backend by the PDF converter.
   *
   * @memberof Utils
   */
  static isPDFPrinting() {
    let isPrinting = false;
    const userAgent = Utils.getUserAgent();
    if (userAgent && /HeadlessChrome/i.test(userAgent)) {
      isPrinting = true;
    }
    return isPrinting;
  }

  /**
   * Extracts the querystring parameters and values from a url or querystrings section.
   *
   * @static
   * @param {string} url or qs section
   * @returns The mapped keys and values.
   * @memberof Utils
   */
  static getQueryStringParams(url) {
    return url
      ? (/^[?#]/.test(url) ? url.slice(1) : url)
          .split('&')
          .reduce((params, param) => {
            const [key, value] = param.split('=');
            const decoded = { ...params };
            if (key) {
              decoded[key] = value
                ? decodeURIComponent(value.replace(/\+/g, ' '))
                : '';
            }
            return decoded;
          }, {})
      : {};
  }
}
