import { connect } from 'react-redux';
import Overview from './overview';
import {
  getExamination,
  addInterval,
  deleteInterval,
  getInterval,
  listIntervals,
  updateInterval
} from '../../../../reducers/request';
import cleanInterval from '../../../../reducers/intervals';

const mapStateToProps = (state, props) => {
  const { examinations, patients, intervals } = state;
  const { location } = props;

  // extract the examination id from the url
  const regExp = /examinations\/(\d*)/i;
  const matches = regExp.exec(location.pathname);
  const id = matches && matches.length > 1 && matches[1];

  // extract the examination from the list
  const examination =
    examinations &&
    examinations.examinations &&
    examinations.examinations.find(e => e.id && e.id.toString() === id);

  const isExaminationFetching = examinations && examinations.isFetching;

  return {
    patients,
    examination,
    examinationId: id,
    isExaminationFetching,
    intervals: intervals.intervals,
    isIntervalsFetching: intervals.isFetching
  };
};

export default connect(
  mapStateToProps,
  {
    getExamination,
    addInterval,
    deleteInterval,
    getInterval,
    listIntervals,
    updateInterval,
    cleanInterval
  }
)(Overview);
