import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import EvaluationList from './evaluation-list';
import { listEvaluations } from '../../reducers/request';
import { cleanBenchmark } from '../../reducers/benchmark';

const mapStateToProps = (state, props) => {
  const { evaluations } = state;
  const { location } = props;

  // extract the interval id from the url
  const regExp = /\/examinations\/(\d*)(\/interval\/(\d*)(\/(.*)\/(\d*))*)*/i;
  const matches = regExp.exec(location.pathname);
  const examinationId = matches && matches.length > 1 && matches[1];
  const intervalId = matches && matches.length > 3 && matches[3];

  return {
    examinationId: examinationId && parseInt(examinationId, 10),
    intervalId: intervalId && parseInt(intervalId, 10),
    evaluations: evaluations && evaluations.evaluations,
    isFetching: evaluations && evaluations.isFetching
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    { listEvaluations, cleanBenchmark }
  )(EvaluationList)
);
