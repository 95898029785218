import { connect } from 'react-redux';
import ExaminationList from './examination-list';
import { cleanIntervals } from '../../reducers/intervals';

const mapStateToProps = () => ({});

export default connect(
  mapStateToProps,
  { cleanIntervals }
)(ExaminationList);
