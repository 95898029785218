import { history } from '../../App';

function navigate(routeName) {
  history.push({
    pathname: routeName
    // search: '?the=query',
  });
}

function back() {
  history.goBack();
}

export default {
  back,
  navigate
};
