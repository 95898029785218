import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Menu from './menu'; // eslint-disable-line import/no-named-as-default
import { menuToogle } from '../../reducers/app';

const mapStateToProps = ({ app: { isMenuOpen } }) => ({
  isMenuOpen
});

export default withRouter(
  connect(
    mapStateToProps,
    { menuToogle }
  )(Menu)
);
