/* globals */

import React, { Component } from 'react';
import './form.css';
import i18n from '../../i18n';

export default class FormView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showform: false,
      name: "",
      id: 0,
      list: [],
      fields: []
    }
  }

  componentDidMount() {
    fetch('https://menstage.kingstudio.hu/api/?list=')
      .then(response => response.json())
      .then(data => this.setState({ list: data }))
      .catch(e => console.log(e))
  }

  renderConditions(i, conditions) {
    return conditions.map((condition, index) => {
      return (<div>
        <p>Feltétel: (Ha mező == kiválasztott értékkel)
          <button className="option_delete" style={{ marginLeft: 20, }} onClick={() => {
            var lst = this.state.fields;
            lst[i].conditions.splice(index, 1);
            this.setState({ fields: lst });
          }}>{ i18n.t('datasheet.deleteif') }</button>
        </p>
        <select style={{ width: "33%", display: "inline-block" }}
          value={condition.field} onChange={
            (e) => {
              var lst = this.state.fields;
              lst[i].conditions[index].field = e.target.value
              this.setState({ fields: lst });
            }
          }>
          <option value="">{ i18n.t('datasheet.choose') }</option>
          {
            this.state.fields.map((field, index) => {
              return (<option value={index}>{field.name}</option>)
            })
          }
        </select>
        <select style={{ width: "33%", display: "inline-block" }}
          value={condition.op} onChange={
            (e) => {
              var lst = this.state.fields;
              lst[i].conditions[index].op = e.target.value
              this.setState({ fields: lst });
            }
          }>
          <option value="eq">==</option>
          <option value="neq">!=</option>
        </select>
        <select style={{ width: "33%", display: "inline-block" }} value={condition.value} onChange={
          (e) => {
            var lst = this.state.fields;
            lst[i].conditions[index].value = e.target.value
            this.setState({ fields: lst });
          }
        }>
          <option value="">{ i18n.t('datasheet.choose') }</option>
          {
            condition.field == "" ? <span /> :
              this.state.fields[condition.field].options.map((field, index) => {
                return (<option value={index}>{field.name}</option>)
              })
          }
        </select>
      </div>)
    })
  }

  renderOptions(i, options) {
    return options.map((option, index) => {
      return (
      <div draggable="true" 
      onDragStart={(e) => {
        e.dataTransfer.setData("id", index);
      }}
      onDragOver={(e) => {
        e.preventDefault();
      }}
      onDrop={(e) => {
        e.preventDefault();
        var data = e.dataTransfer.getData("id");

        console.log(index);
        console.log(data);

        var flds = this.state.fields;
        var tmp = flds[index];
        flds[index] = flds[data];
        flds[data] = tmp;
        this.setState({fields: flds});
      }} key={index}>
        <p>Opció neve:
          <button className="option_delete" style={{ marginLeft: 20 }} onClick={() => {
            var lst = this.state.fields;
            lst[i].options.splice(index, 1);
            this.setState({ fields: lst });
          }}>opció törlése</button>
        </p>
        <input name="name" type="text" value={option.name} onChange={
          (e) => {
            var lst = this.state.fields;
            lst[i].options[index].name = e.target.value
            this.setState({ fields: lst });
          }
        } />
      </div>)
    })
  }

  renderField(index, field) {
    return (
      <div 
      draggable="true" 
      onDragStart={(e) => {
        e.dataTransfer.setData("id", index);
      }}
      onDragOver={(e) => {
        e.preventDefault();
      }}
      onDrop={(e) => {
        e.preventDefault();
        var data = e.dataTransfer.getData("id");

        console.log(index);
        console.log(data);

        var flds = this.state.fields;
        var tmp = flds[index];
        flds[index] = flds[data];
        flds[data] = tmp;
        this.setState({fields: flds});
      }} key={index} className="form-element">
        <p>Mező neve:</p>
        <input name="name" type="text" value={field.name} onChange={
          (e) => {
            var lst = this.state.fields;
            lst[index].name = e.target.value
            this.setState({ fields: lst });
          }
        } />
        <p>Mező megjelenítési feltétele:</p>
        <div>
          {this.renderConditions(index, field.conditions)}
          <br />
          <button onClick={() => {
            var lst = this.state.fields;
            lst[index].conditions.push({ "field": "", "value": "", "op": "eq" })
            this.setState({ fields: lst });
          }}>Új feltétel</button>
        </div>
        <p>Mező kötelező:</p>
        <select value={field.required} onChange={
          (e) => {
            var lst = this.state.fields;
            lst[index]['required'] = e.target.value
            this.setState({ fields: lst });
          }
        }>
          <option value="false">NEM</option>
          <option value="true">IGEN</option>
        </select>
        <p>Mező típusa:</p>
        <select value={field.type} onChange={
          (e) => {
            var lst = this.state.fields;
            lst[index].type = e.target.value
            this.setState({ fields: lst });
          }
        }>
          <option value="">{ i18n.t('datasheet.choose') }</option>
          <option value="TEXT">Megjegyzés</option>
          <option value="TEXTINPUT">Szöveges beviteli mező</option>
          <option value="MULTITEXTINPUT">Több soros szöveges beviteli mező</option>
          <option value="SELECT">Lenyíló beviteli mező</option>
          <option value="CHECK">Több opciós beviteli mező</option>
        </select>
        {
          field.type == "SELECT" || field.type == "CHECK" ?
            (
              <div>
                {this.renderOptions(index, field.options)}
                <br />
                <button onClick={() => {
                  var lst = this.state.fields;
                  lst[index].options.push({ "name": "", "value": false, "conditions": [] })
                  this.setState({ fields: lst });
                }}>Új opció</button>
              </div>
            )
            : <span></span>
        }
        <button style={{ marginTop: 30}} onClick={() => {
              var lst = this.state.fields;

              lst.splice(index, 0, JSON.parse(JSON.stringify(field)));
              this.setState({ fields: lst });
            }}>Mező másolása</button>
      </div>
    )
  }

  renderLiveField(index, field) {
    if (field.type == "TEXT") {
      return (
        <div className="form-element">
          <p>{field.name}</p>
        </div>
      )
    } else if (field.type == "MULTITEXTINPUT") {
      return (
        <div className="form-element">
          <p>{field.name}</p>
          <textarea style={{ width: "100%", height: 120, border: "1px solid #5CB8BB" }} type="text" name=""></textarea>
        </div>
      )
    } else if (field.type == "TEXTINPUT") {
      return (
        <div className="form-element">
          <p>{field.name}</p>
          <input type="text" name=""></input>
        </div>
      )
    } else if (field.type == "SELECT") {
      return (
        <div className="form-element">
          <p>{field.name}</p>
          <select value={field.value} onChange={
            (e) => {
              var lst = this.state.fields;
              lst[index].value = e.target.value
              this.setState({ fields: lst });
            }
          }>
            <option value="">Válasszon</option>
            {
              field.options.map((option, index) => {
                return (<option value={index}>{option.name}</option>)
              })
            }
          </select>
        </div>
      )
    } else if (field.type == "CHECK") {
      return (
        <div className="form-element">
          <p>{field.name}</p>
          {
            field.options.map((option, ind) => {
              return (<p>
                <label class="container">
                {option.name}
                <input style={{ width: "auto" }} type="checkbox" checked={option.value} value={index} onClick={(e) => {
                var lst = this.state.fields;
                lst[index].options[ind].value = !lst[index].options[ind].value
                this.setState({ fields: lst });
              }} /> 
              <span class="checkmark"></span>
              </label></p>)
            })
          }
        </div>
      )
    } else {
      return (
        <div className="form-element">
          <p>Mező neve:</p>
          <input name="name" type="text" value={field.name} onChange={
            (e) => {
              var lst = this.state.fields;
              lst[index].name = e.target.value
              this.setState({ fields: lst });
            }
          } />
          <p>Mező megjelenítési feltétele:</p>
          <div>
            {this.renderConditions(index, field.conditions)}
            <br />
            <button onClick={() => {
              var lst = this.state.fields;
              lst[index].conditions.push({ "field": "", "value": "", "op": "eq" })
              this.setState({ fields: lst });
            }}>Új feltétel</button>
          </div>
          <p>Mező típusa:</p>
          <select value={field.type} onChange={
            (e) => {
              var lst = this.state.fields;
              lst[index].type = e.target.value
              this.setState({ fields: lst });
            }
          }>
            <option value="">Válasszon</option>
            <option value="TEXT">Megjegyzés</option>
            <option value="TEXTINPUT">Szöveges beviteli mező</option>
            <option value="SELECT">Lenyíló beviteli mező</option>
            <option value="CHECK">Több opciós beviteli mező</option>
          </select>
          {
            field.type == "SELECT" || field.type == "CHECK" ?
              (
                <div>
                  {this.renderOptions(index, field.options)}
                  <br />
                  <button onClick={() => {
                    var lst = this.state.fields;
                    lst[index].options.push({ "name": "" })
                    this.setState({ fields: lst });
                  }}>Új opció</button>
                </div>
              )
              : <span></span>
          }
        </div>
      )
    }
  }

  render() {
    const {
      fields
    } = this.state;

    if (this.state.showform) {
      return (
        <div className="examination preview">
          <h1 className="route-title">Adatlap neve: {this.state.name}</h1>
          <div className="form-element">
            <button onClick={() => {
              this.setState({ showform: false });
            }}>Adatlap szerkesztése</button>
          </div>
          {
            fields.map((field, index) => {
              if (field.conditions.length == 0) {
                return this.renderLiveField(index, field)
              } else {
                var show = true;
                console.log(field.conditions)
                field.conditions.map((cond) => {
                  console.log(this.state.fields[cond.field])
                  console.log(this.state.fields[cond.field].value)
                  console.log(cond.op)
                  console.log(cond.value)
                  if (
                    (
                      (this.state.fields[cond.field].value != cond.value && cond.op == "eq") ||
                      (this.state.fields[cond.field].value == cond.value && cond.op == "neq") ||
                      (this.state.fields[cond.field].value == "" && cond.op == "neq")
                    ) &&
                    !this.state.fields[cond.field].options[cond.value].value
                  ) {
                    show = false;
                  }
                })
                if (show) {
                  return this.renderLiveField(index, field)
                }
              }
            })
          }
        </div>
      )
    } else {
      return (
        <div className="examination">
          <h1 className="route-title">{ i18n.t('datasheet.title') }</h1>
          <div className="form-element">
            <p>{ i18n.t('datasheet.name') }</p>
            <input name="name" type="text" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
          </div>
          {
            fields.map((field, index) => {
              return this.renderField(index, field)
            })
          }
          <div className="form-element">
            <button onClick={() => {
              var lst = this.state.fields;
              lst.push({
                "name": "",
                "type": "TEXT",
                "conditions": [],
                "options": [],
                "value": ""
              });
              this.setState({ fields: lst });
            }}>{ i18n.t('datasheet.newfield') }</button></div>

<div style={{ "background": "none"}} className="form-element">
            <button className="preview" onClick={() => {
              this.setState({ showform: true });
            }}>{ i18n.t('datasheet.preview') }</button>
            {
              this.state.fields.length == 0 ? <span /> :
            (<button style={{ marginLeft: 20 }} onClick={() => {
              this.setState({ name: "", id: 0, fields: [] });
            }}>{ i18n.t('datasheet.back') }</button>)
          }
            <button style={{ background: "#f26f5a", marginLeft: 20 }} onClick={() => {
              var data = new FormData();
              data.append( "id", this.state.id );
              data.append( "name", this.state.name );
              data.append( "jsn", JSON.stringify( this.state.fields ) );
              
              fetch('https://menstage.kingstudio.hu/api/',
              {
                  method: "POST",
                  body: data
              })
              .then((d) => {
                this.setState({name: "", id: 0, fields: []});
                fetch('https://menstage.kingstudio.hu/api/?list=')
                    .then(response => response.json())
                    .then(data => this.setState({ list: data }))
                    .catch(e => console.log(e))
              })
            }}>{ i18n.t('datasheet.save') }</button>
          </div>

            {
              this.state.id == 0 ?
          (<div>
          <div class="table">
            <div class="table-header">
              <div class="header__item">{ i18n.t('datasheet.datasheet') }</div>
              <div class="header__item">{ i18n.t('datasheet.edit') }</div>
            </div>
            <div class="table-content">
          {
            this.state.list.map((f) => {
              return (
                <div class="table-row">
                  <div class="table-data">{ f.name }</div>
                  <div class="table-data"><button style={{ marginLeft: 10 }} onClick={() => {
                    this.setState({ name: f.name, fields: JSON.parse(f.jsn), id: f.id });
                  }}>{ i18n.t('datasheet.edit') }</button></div>
                </div>
              )
            })
          }
            </div>
          </div>
          </div>) : (<div />)
    }
        </div>
      );
    }
  }
}

// mező értékek ( lenyíló és több opciósnál )
// feltételek ( opcióknál is )
// Előnézet 
