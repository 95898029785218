import { connect } from 'react-redux';
import Patients from './patients';
import { getPatients } from '../../reducers/request';

const mapStateToProps = ({ patients }) => ({
  patients
});

export default connect(
  mapStateToProps,
  { getPatients }
)(Patients);
