export const ADD_EXAMINATION = 'ADD_EXAMINATION';
export const UPDATE_EXAMINATION = 'UPDATE_EXAMINATION';
export const GET_ALL_EXAMINATIONS = 'GET_ALL_EXAMINATIONS';
export const GET_EXAMINATIONS = 'GET_EXAMINATIONS';
export const GET_EXAMINATION = 'GET_EXAMINATION';
export const GET_LAST_EXAMINATION = 'GET_LAST_EXAMINATION';
export const START_EXAMINATION = 'START_EXAMINATION';
export const STOP_EXAMINATION = 'STOP_EXAMINATION';
export const PAIR_DEVICE = 'PAIR_DEVICE';
export const RESEND_EXAMINATION_DATA = 'RESEND_EXAMINATION_DATA';
export const ADD_INTERVAL = 'ADD_INTERVAL';
export const DELETE_INTERVAL = 'DELETE_INTERVAL';
export const GET_INTERVAL = 'GET_INTERVAL';
export const GET_INTERVAL_CYCLES = 'GET_INTERVAL_CYCLES';
export const LIST_INTERVALS = 'LIST_INTERVALS';
export const UPDATE_INTERVAL = 'UPDATE_INTERVAL';
export const ADD_EVALUATION = 'ADD_EVALUATION';
export const GET_EVALUATION = 'GET_EVALUATION';
export const LIST_EVALUATIONS = 'LIST_EVALUATIONS';
export const UPDATE_EVALUATION = 'UPDATE_EVALUATION';
export const START_EVALUATION = 'START_EVALUATION';
export const ADD_PATIENT = 'ADD_PATIENT';
export const GET_PATIENTS = 'GET_PATIENTS';
export const GET_PATIENT = 'GET_PATIENT';
export const UPDATE_PATIENT = 'UPDATE_PATIENT';
export const PRINT = 'PRINT';
export const REQUEST = 'REQUEST';
export const SEND_REQUEST_SUCCESS = 'SEND_REQUEST_SUCCESS';
export const GET_BENCHMARK = 'GET_BENCHMARK';
export const GET_PRINT_DATA = 'GET_PRINT_DATA';
export const GET_PROGRESS = 'GET_PROGRESS';
export const REHYDRATE = 'persist/REHYDRATE';
export const GET_USER = 'GET_USER';

export const Types = {
  REQUEST,
  SEND_REQUEST_SUCCESS,
  REHYDRATE
};

export const ActionTypes = {
  GET_USER,
  GET_PRINT_DATA,
  ADD_EXAMINATION,
  UPDATE_EXAMINATION,
  GET_ALL_EXAMINATIONS,
  GET_EXAMINATIONS,
  GET_EXAMINATION,
  GET_LAST_EXAMINATION,
  START_EXAMINATION,
  STOP_EXAMINATION,
  PAIR_DEVICE,
  RESEND_EXAMINATION_DATA,
  ADD_INTERVAL,
  DELETE_INTERVAL,
  GET_INTERVAL,
  GET_INTERVAL_CYCLES,
  LIST_INTERVALS,
  UPDATE_INTERVAL,
  ADD_EVALUATION,
  GET_EVALUATION,
  LIST_EVALUATIONS,
  UPDATE_EVALUATION,
  START_EVALUATION,
  GET_BENCHMARK,
  ADD_PATIENT,
  GET_PATIENT,
  GET_PATIENTS,
  UPDATE_PATIENT,
  PRINT,
  GET_PROGRESS
};

export const addPatient = payload => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.ADD_PATIENT,
    requestId: 'addPatient',
    variables: {
      payload
    }
  }
});

export const getPatient = id => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.GET_PATIENT,
    requestId: 'getPatient',
    variables: {
      id
    }
  }
});

export const getPatients = (username = '*', limit = 20) => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.GET_PATIENTS,
    requestId: 'getPatients',
    variables: {
      username,
      limit
    }
  }
});

export const updatePatient = (id, payload) => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.UPDATE_PATIENT,
    requestId: 'updatePatient',
    variables: {
      id,
      payload
    }
  }
});

export const addExamination = payload => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.ADD_EXAMINATION,
    requestId: 'addExamination',
    variables: {
      payload
    }
  }
});

export const updateExamination = (id, payload) => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.UPDATE_EXAMINATION,
    requestId: 'updateExamination',
    variables: {
      id,
      payload
    }
  }
});

export const getExamination = id => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.GET_EXAMINATION,
    requestId: 'getExamination',
    variables: {
      id
    }
  }
});

export const getExaminations = patientId => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.GET_EXAMINATIONS,
    requestId: 'getExaminations',
    variables: {
      id: patientId
    }
  }
});

export const getAllExaminations = (username = '*', limit = 20) => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.GET_ALL_EXAMINATIONS,
    requestId: 'getAllExaminations',
    variables: {
      limit,
      username
    }
  }
});

export const getLastExamination = patientId => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.GET_LAST_EXAMINATION,
    requestId: 'getLastExamination',
    variables: {
      id: patientId
    }
  }
});

export const startExamination = id => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.START_EXAMINATION,
    requestId: 'startExamination',
    variables: {
      id
    }
  }
});

export const stopExamination = id => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.STOP_EXAMINATION,
    requestId: 'stopExamination',
    variables: {
      id
    }
  }
});

export const pairDevice = (id, name) => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.PAIR_DEVICE,
    requestId: 'pairDevice',
    variables: {
      id,
      name
    }
  }
});

export const resendData = id => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.RESEND_EXAMINATION_DATA,
    requestId: 'resendData',
    variables: {
      id
    }
  }
});

export const getBenchmark = (benchmarkType, evaluationId) => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.GET_BENCHMARK,
    requestId: 'getBenchmark',
    variables: {
      benchmarkType,
      evaluationId
    }
  }
});

export const addInterval = payload => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.ADD_INTERVAL,
    requestId: 'addInterval',
    variables: {
      payload
    }
  }
});

export const deleteInterval = id => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.DELETE_INTERVAL,
    requestId: 'deleteInterval',
    variables: {
      id
    }
  }
});

export const getInterval = id => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.GET_INTERVAL,
    requestId: 'getInterval',
    variables: {
      id
    }
  }
});

export const getIntervalCycles = id => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.GET_INTERVAL_CYCLES,
    requestId: 'getIntervalCycles',
    variables: {
      id
    }
  }
});

export const listIntervals = examinationId => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.LIST_INTERVALS,
    requestId: 'listIntervals',
    variables: {
      examinationId
    }
  }
});

export const updateInterval = (payload, fields) => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.UPDATE_INTERVAL,
    requestId: 'updateInterval',
    variables: {
      payload,
      fields
    }
  }
});

export const addEvaluation = payload => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.ADD_EVALUATION,
    requestId: 'addEvaluation',
    variables: {
      payload
    }
  }
});

export const getEvaluation = id => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.GET_EVALUATION,
    requestId: 'getEvaluation',
    variables: {
      id
    }
  }
});

export const listEvaluations = intervalId => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.LIST_EVALUATIONS,
    requestId: 'listEvaluations',
    variables: {
      intervalId
    }
  }
});

export const updateEvaluation = (payload, openPrintView) => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.UPDATE_EVALUATION,
    requestId: 'updateEvaluation',
    variables: {
      payload,
      openPrintView
    }
  }
});

export const startEvaluation = payload => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.START_EVALUATION,
    requestId: 'startEvaluation',
    variables: {
      payload
    }
  }
});

export const getPrintData = (id, token) => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.GET_PRINT_DATA,
    requestId: 'getPrintData',
    variables: {
      id,
      token
    }
  }
});

export const print = (type, payload) => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.PRINT,
    requestId: 'print',
    variables: {
      type,
      payload
    }
  }
});

export const getProgress = (id, type) => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.GET_PROGRESS,
    requestId: 'getProgress',
    variables: {
      id,
      type
    }
  }
});

export const getUser = () => ({
  type: 'REQUEST',
  payload: {
    requestId: 'getUser',
    action: ActionTypes.GET_USER
  }
});

export const Actions = {
  pairDevice,
  resendData,
  getPrintData,
  getBenchmark,
  addExamination,
  updateExamination,
  getAllExaminations,
  getExamination,
  getExaminations,
  getLastExamination,
  startExamination,
  stopExamination,
  addInterval,
  deleteInterval,
  getInterval,
  getIntervalCycles,
  listIntervals,
  updateInterval,
  addEvaluation,
  getEvaluation,
  listEvaluations,
  updateEvaluation,
  startEvaluation,
  addPatient,
  getPatient,
  getPatients,
  updatePatient,
  getUser,
  print,
  getProgress
};
