import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  VictoryChart,
  VictoryBar,
  VictoryLine,
  VictoryAxis,
  VictoryLabel
} from 'victory';

class ChartHistogram extends Component {
  static propTypes = {
    histogramDomain: PropTypes.shape({}),
    histogram: PropTypes.shape({}),
    fontSize: PropTypes.number,
    height: PropTypes.number
  };

  // Render ticks only if they fit on the chart
  static renderTicks(t, modulo, side) {
    if (t % modulo === 0) {
      if (side === 'left') {
        return `${t * 2}`;
      }
      return `${t * 2 + 1}`;
    }
    return '';
  }

  // Count the modulo number based on how many ticks are we able to show
  getDividerNumber() {
    // The number of ticks we are able to show
    const MAX_NUM_TO_SHOW = 30;

    const { histogram } = this.props;
    if (histogram && histogram.time) {
      let counter = 1;
      while (histogram.time.length / counter > MAX_NUM_TO_SHOW) {
        counter += 1;
      }
      return counter;
    }
    return 1;
  }

  render() {
    const { histogramDomain, histogram, fontSize, height } = this.props;
    const modulo = this.getDividerNumber();

    return (
      <VictoryChart
        padding={{ left: 40, top: 20, bottom: 30, right: 20 }}
        height={height || 200}
        domain={{ y: [histogramDomain.min, histogramDomain.max] }}
      >
        <VictoryBar
          alignment="start"
          data={histogram.data}
          style={{
            data: { fill: '#28bcbc' }
          }}
        />

        <VictoryLine
          style={{ data: { stroke: '#f26f5a' } }}
          data={[
            { x: 0, y: histogram.hi },
            { x: histogram.data.length - 0.5, y: histogram.hi }
          ]}
        />
        <VictoryLine
          style={{ data: { stroke: '#f26f5a' } }}
          data={[
            { x: 0, y: histogram.low },
            { x: histogram.data.length - 0.5, y: histogram.low }
          ]}
        />
        <VictoryAxis
          style={{ tickLabels: { fontSize } }}
          dependentAxis
          orientation="bottom"
          tickValues={histogram.time}
          tickFormat={t => {
            if (histogram.name.includes('left')) {
              return ChartHistogram.renderTicks(t, modulo, 'left');
            }
            return ChartHistogram.renderTicks(t, modulo, 'right');
          }}
          tickLabelComponent={<VictoryLabel dx={3} dy={-5} />}
        />
        <VictoryAxis dependentAxis style={{ tickLabels: { fontSize } }} />
      </VictoryChart>
    );
  }
}

export default ChartHistogram;
