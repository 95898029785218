import i18n from 'i18n-js';

import hu from './locales/hu.json';
import en from './locales/en.json';

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

var lng = getCookie("language");
if (lng == "") {
    lng = "hu";
}

i18n.defaultLocale = 'hu';
i18n.locale = lng;
i18n.fallbacks = true;
i18n.translations = { "en": en, "hu": hu };
i18n.missingBehaviour = 'guess';

export default i18n;
