import React, { Component } from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line

import '../../../../styles/evaluation.css';
import ChartHistogram from '../../../charts/histogram';
import ChartDimension from '../../../charts/dimension';
import ChartAverage from '../../../charts/average';
import ChartLabel from '../../../charts/labels';

class EvaluationView extends Component {
  static propTypes = {
    histograms: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        data: PropTypes.arrayOf(PropTypes.any).isRequired,
        hi: PropTypes.number.isRequired,
        low: PropTypes.number.isRequired,
        time: PropTypes.arrayOf(PropTypes.any).isRequired
      })
    ),
    dimensions: PropTypes.arrayOf(PropTypes.any).isRequired,
    intervalName: PropTypes.string.isRequired,
    averages: PropTypes.arrayOf(PropTypes.any).isRequired,
    averagesDomain: PropTypes.shape({
      max: PropTypes.number,
      min: PropTypes.number,
      ticks: PropTypes.arrayOf(PropTypes.number)
    }),
    histogramsDomain: PropTypes.shape({
      max: PropTypes.number,
      min: PropTypes.number
    }),
    dimensionsDomain: PropTypes.shape({
      max: PropTypes.number,
      min: PropTypes.number,
      ticks: PropTypes.arrayOf(PropTypes.number)
    }),
    movingAverage: PropTypes.number,
    evaluationTime: PropTypes.string
  };

  renderBars() {
    const {
      histograms,
      histogramsDomain,
      movingAverage,
      evaluationTime
    } = this.props;

    if (!histograms) return null;

    return histograms.map(chart => (
      <div className="chart box" key={chart.name}>
        <ChartLabel
          labelInfo={{
            evaluationTime,
            movingAverage,
            chartName: chart.name
          }}
          labelType="histogram"
        />
        <div>
          <ChartHistogram
            histogram={chart}
            histogramDomain={histogramsDomain}
            fontSize={9}
          />
        </div>
      </div>
    ));
  }

  renderDimensions() {
    const {
      dimensions,
      dimensionsDomain,
      movingAverage,
      evaluationTime
    } = this.props;

    if (!dimensions) return null;

    return dimensions.map(chart => (
      <div className="chart box" key={chart.name}>
        <ChartLabel
          labelInfo={{
            evaluationTime,
            movingAverage,
            chartName: chart.name
          }}
          labelType="dimensions"
        />
        <div>
          <ChartDimension dimension={chart} dimensionDomain={dimensionsDomain} />
        </div>
      </div>
    ));
  }

  renderAverages() {
    const {
      averages,
      averagesDomain,
      movingAverage,
      evaluationTime
    } = this.props;

    if (!averages) return null;

    return averages.map(chart => (
      <div className="chart box" key={chart.name}>
        <ChartLabel
          labelInfo={{
            evaluationTime,
            movingAverage,
            chartName: chart.name
          }}
          labelType="averages"
        />
        <div>
          <ChartAverage
            average={chart}
            averageDomain={averagesDomain}
            fontSize={5.5}
          />
        </div>
      </div>
    ));
  }

  render() {
    const { intervalName } = this.props;
    return (
      <div className="evaluation">
        <div className="head">
          <h1>{intervalName}</h1>
        </div>
        <div className="grid grid-two">{this.renderBars()}</div>
        <div className="grid grid-three">{this.renderDimensions()}</div>
        <div className="grid grid-one">{this.renderAverages()}</div>
      </div>
    );
  }
}

export default EvaluationView;
