import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StatementView from './statement-view';

class Statement extends Component {
  static propTypes = {
    fields: PropTypes.shape({})
  };

  render() {
    const { fields } = this.props;

    const fieldsWeShouldDisplay = [
      {
        name: 'fullName',
        value: `${fields.firstName.value} ${fields.lastName.value}`
      },
      { name: fields.dateOfBirth.name, value: fields.dateOfBirth.value },
      { name: fields.placeOfBirth.name, value: fields.placeOfBirth.value },
      { name: fields.mothersName.name, value: fields.mothersName.value },
      { name: fields.phone.name, value: fields.phone.value },
      { name: fields.email.name, value: fields.email.value },
      { name: fields.address.name, value: fields.address.value }
    ];

    return <StatementView fields={fieldsWeShouldDisplay} />;
  }
}

export default Statement;
